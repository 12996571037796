import React, { useEffect, useState } from "react";
import BackButton from "../components/BackButton";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButton from "../components/PaypalButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartOrders } from "../features/userSlice";
import { getUserData } from "../components/utils";
import {
  captureOrderPayment,
  captureOrderPaymentUsingSavedCards,
  capturePaymentUsingcredits,
  CardPaymentIntent,
  CreatePaymentIntent,
  GetCustomerCard,
  GetCustomerPaypalCard,
  PaymentOrders,
  GetRemainigAmount
} from "../components/auth";
import Spinner from "../components/spinner/Spinner";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { PAYPAL_CLIENT_ID, STRIPE_PUBLIC_KEY } from "../components/Api";
import CheckoutForm from "../components/payment/stripe/CheckoutForm";
import { useStripe } from "@stripe/react-stripe-js";
import PaypalAdvanceCheckout from "../components/PaypalAdvanceCheckout";

let cartDetails = [],
  totalAmount,
  cartid,
  orderIds,
  orderDiscount,
  shippingAmount,
  subTotal,
  orderSuccessfull = {},
  remainingAmount,
  creditStatus;
const promise = loadStripe(STRIPE_PUBLIC_KEY);

const Checkout = (props) => {
  let navigate = useNavigate();
  var getCustomerId = getUserData(); //getting customer id from local storage
  const stripe = useStripe();
  const [clientSecret, setClientSecret] = useState("");
  const [checkoutPageLoader, setCheckoutPageLoader] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false)


  var customerId =
    !!getCustomerId && getCustomerId
      ? getCustomerId?.customerInfo?.customer_data?.id
      : "";

  var customerEmail =
    !!getCustomerId && getCustomerId
      ? getCustomerId?.customerInfo?.customer_data?.email
      : "";

  var getCustomerData = getUserData(); //getting customer id from local storage

  var customerName =
    !!getCustomerData && getCustomerData
      ? getCustomerData.customerInfo.customer_data.firstName +
      " " +
      getCustomerData.customerInfo.customer_data.lastName
      : "";

  // Redux state getting here
  cartDetails = useSelector(cartOrders).payload.user.cartOrders.cart_order;
  cartid = useSelector(cartOrders).payload.user.cartOrders.id;
  totalAmount = useSelector(cartOrders).payload.user.cartOrders.total;
  orderIds = useSelector(cartOrders).payload.user.cartOrders.items;
  orderDiscount = useSelector(cartOrders).payload.user.cartOrders.discount;
  shippingAmount =
    useSelector(cartOrders).payload.user.cartOrders.shippingAmount;
  subTotal = useSelector(cartOrders).payload.user.cartOrders.subTotal;
  remainingAmount = useSelector(cartOrders).payload.user.cartOrders.remainingAmount;
  creditStatus = useSelector(cartOrders).payload.user.cartOrders.creditStatus;
  console.log(creditStatus);
  console.log("creditStatus");
  if(creditStatus === "disabled"){
    remainingAmount = 0
  }

  // Redirecting the users if cart id doesn't match
  let { checkout } = useParams(); //getting url parameter

  console.log("orderIds----------------", orderIds);

  useEffect(() => {
    if (checkout != cartid) {
      toast.error("Your payment failed, please try again ");
      navigate("/");
    }
    // If client secret is empty
    if (clientSecret == "") {
      ceatePaymentIntent();
    }
  }, []);

  const [chooseCard, setChooseCard] = useState(""); //default card
  const [paymentLoader, setPaymentLoader] = useState(false); //default card

  // ************************ PayPal Payment ************************************************//
  const initialOptions = {
    "client-id": PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };

  var paypalItems = [];
  orderIds.map((item) => {
    var orderObj = {
      name: item?.item_name,
      unit_amount: {
        currency_code: "USD",
        value: Number(item?.subTotalPrice).toFixed(2),
      },
      quantity: 1,
    };
    paypalItems.push(orderObj);
  });

  // ************************ Paypal Advance Checkout OrderIds Object preparation ********************* //
  console.log("********************");
  console.log(Number(orderDiscount));
  console.log(remainingAmount);
console.log( Number(orderDiscount)+remainingAmount);
  console.log("********************");

  var paypalAdvanceCheckoutObj = {
    purchase_units: [
      {
        amount: {
          value: Number(totalAmount-remainingAmount).toFixed(2),
          currency_code: "USD",
          breakdown: {
            discount: {
              currency_code: "USD",
              value: (Number(orderDiscount)+remainingAmount).toFixed(2),
            },
            shipping: {
              currency_code: "USD",
              value: Number(shippingAmount).toFixed(2),
            },
            // shipping_discount: {
            //   currency_code: "USD",
            //   value: Number(shippingAmount).toFixed(2),
            // },
            item_total: {
              currency_code: "USD",
              value: Number(subTotal).toFixed(2),
            },
          },
        },
        items: paypalItems,
      },
    ],
  };
  console.log("Paypal Advance Checkout Obj");
  console.log(paypalAdvanceCheckoutObj);
  // ************************ Paypal Advance Checkout OrderIds Object preparation Complete ********************* //
  const createOrderHandler = (data, actions) => {
    // Set up the transaction
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: Number(totalAmount-remainingAmount).toFixed(2),
            currency_code: "USD",
            breakdown: {
              discount: {
                currency_code: "USD",
                value: (Number(orderDiscount)+remainingAmount).toFixed(2),
              },
              shipping: {
                currency_code: "USD",
                value: Number(shippingAmount).toFixed(2),
              },
              item_total: {
                currency_code: "USD",
                value: Number(subTotal).toFixed(2),
              },
            },
          },
          items: paypalItems,
        },
      ],
    });
  };

  const onErrorHandler = (err) => {
    // Set up the transaction
    console.log(err, "bbbbbbbbbbbb");
    toast.error("Something went wrong");
    navigate("/");
  };

  const onCancelHandler = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", data);
    setPaymentLoader(false);
    toast.error("The payment was cancelled!");
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onApproveHandler = (data, actions) => {
    // This function captures the funds from the transaction.
    setPaymentLoader(true);
    return actions.order
      .capture()
      .then(function (details) {
        // This function shows a transaction success message to your buyer.
        // alert("Transaction completed by " + details.payer.name.given_name);
        let type="";
          if(remainingAmount==0){
          type=["paypal"]
          }else{
            type=["credits","paypal"]
        }
        orderSuccessfull = {
          txn_id: details.id,
          customer_id: customerId,
          customer_email: customerEmail,
          payer_id: details.payer.payer_id,
          payer_email: details.payer.email_address,
          currency_code: "USD",
          totalAmount: (totalAmount-remainingAmount).toFixed(2),
          status: details.status,
          payment_type:type ,
          create_time: details.create_time,
          update_time: details.update_time,
          order_ids: orderIds,
          oldPrice: (Number(subTotal) + Number(shippingAmount)).toFixed(2),
        };
        setPaymentLoader(true);
        var PaymentInfo = {
          type: type,
          RemainingAmount: remainingAmount,
          txn_detail: orderSuccessfull,
          disct: orderDiscount,
        };
        PaymentOrders(PaymentInfo)
          .then((res) => {
            setCheckoutPageLoader(true);
            setPaymentLoader(false);
            
            if (res.status == 200) {
              // user Object

              setTimeout(() => {
                setPaymentLoader(false);
                toast.success(res.message);
                navigate("/");
              }, 5000);
            } else {
              setPaymentLoader(false);
              toast.error(res.message);
            }
          })
          .catch((err) => {
            setPaymentLoader(false);
          });
      })
      .catch((err) => {
        toast.error(err.err);
        setPaymentLoader(false);
      });
  };

  // ************************ Strip Payment ******************************//
  const [PaymentIntentLoader, setPaymentIntentLoader] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleChange = (e) => {
    setProcessing(true);
    setDisabled(true);
    if (e.target.value == chooseCard) {
      setChooseCard("");
      setProcessing(false);
      setDisabled(false);
    } else {
      setChooseCard(e.target.value);
      try {
        var data = {
          amount: totalAmount * 100,
          email: customerEmail,
          pmId: e.target.value,
        };
        setPaymentIntentLoader(true);
        CardPaymentIntent(data)
          .then((res) => {
            if (res.status == 200) {
              setClientSecret(res.data);
              setPaymentIntentLoader(false);
              setProcessing(false);
              setDisabled(false);
              console.log(res.data);
            } else {
              setProcessing(false);
              setDisabled(false);
              setPaymentIntentLoader(false);
              console.log(res.message);
            }
          })
          .catch((err) => {
            setProcessing(false);
            setDisabled(false);
            console.log(err, "apisssiiiiii");
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  //   Creat payment intent
  const ceatePaymentIntent = () => {

    try {
      var data = {
        amount: (totalAmount) * 100,
        email: customerEmail,
        orderId: orderIds
      };
      CreatePaymentIntent(data)
        .then((res) => {
          if (res.status == 200) {
            setClientSecret(res.data);
            console.log(res.data);
          } else {
            console.log(res.message);
          }
        })
        .catch((err) => {
          console.log(err, "apiiasdiiiii");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [isOpencard, setOpencard] = useState(false);
  const handleToken = async (token) => {
    // console.log(token);
    orderSuccessfull = {
      customer_id: customerId,
      customer_email: customerEmail,
      currency_code: "USD",
      totalAmount: Number(totalAmount).toFixed(2),
      payment_type: "stripe",
      order_ids: orderIds,
      token: token,
      oldPrice: (Number(subTotal) + Number(shippingAmount)).toFixed(2),
    };

    setPaymentLoader(true);
    var PaymentInfo = {
      type: "stripe",
      txn_detail: orderSuccessfull,
    };

    PaymentOrders(PaymentInfo)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setTimeout(() => {
            setPaymentLoader(false);
            toast.success(res.message);
            navigate("/");
          }, 5000);
        } else {
          setPaymentLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  // Get customer cards
  const [cardLoader, setCardLoader] = useState(false);
  const [SavedCardLoader, setSavedCardLoader] = useState(false);
  const [cardData, setCardData] = useState([]);

  const getCards = (customerId) => {
    if (customerId != null && customerId != "") {
      var customerInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      setCardLoader(true);
      GetCustomerCard(customerInfo)
        .then((res) => {
          if (res.status == 200) {
            setCardLoader(false);
            setCardData(res.data);
            // cards Object
          } else {
            setCardLoader(false);
            console.log(res.message);
          }
        })
        .catch((err) => {
          setCardLoader(false);
          toast.error(err.message);
          console.log(err);
        });
    }
  };

  // Get customer cards
  const [paypalCardLoader, setPaypalCardLoader] = useState(false);
  const [paypalCardData, setPaypalCardData] = useState([]);

  const getPaypalCards = (customerId) => {
    if (customerId !== null && customerId !== "") {
      var customerInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      setPaypalCardLoader(true);
      GetCustomerPaypalCard(customerInfo)
        .then((res) => {
          if (res.status == 200) {
            setPaypalCardLoader(false);
            setPaypalCardData(res.data);
            // cards Object
          } else {
            setPaypalCardLoader(false);
            console.log(res.message);
          }
        })
        .catch((err) => {
          setPaypalCardLoader(false);
          toast.error(err.message);
          console.log(err);
        });
    }
  };

  const handleCardPay = async () => {
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: chooseCard,
    });
    setPaymentLoader(true);
    if (payload.error) {
      toast.error(`Payment failed - ${payload.error.message}`);
      setPaymentLoader(false);
    } else {
      handleToken(payload);
      // setTimeout(() => {
      //   setSavedCardLoader(false);
      //   toast.success("Thank you for your payment, your orders is placed");
      //   navigate("/");
      // }, 5000);
    }
  };

  useEffect(() => {
    getCards(customerId);
    getPaypalCards(customerId);
  }, []);

  const [paymentSource, setPaymentSource] = useState("existing");
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");

  const handleChangePaymentSource = (e) => {
    setPaymentSource(e.target.value);
  };

  const payUsingExistingCard = (e) => {
    e.preventDefault();
    const payload = {
      creditCardInformation: selectedPaymentSource,
      orderInformation: orderIds.map(
        (orderInfo) => (
          console.log(orderIds),
          console.log("orderInfo.item_name"),
          {
            orderNumber: orderInfo.item_name,
            totalPrice: orderInfo.amount,
            id: orderInfo.id,
          }
        )
      ),
      orderInfo: paypalAdvanceCheckoutObj,
      RemainingAmount:remainingAmount,
      disct:orderDiscount,
      email: customerEmail,
    };
    setProcessing(true);
    captureOrderPaymentUsingSavedCards(payload)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setProcessing(false);
            toast.success(response.message);
            navigate("/");
          }, 5000);
        } else {
          setTimeout(() => {
            setProcessing(false);
            toast.error(response.message);
            navigate("/");
          }, 5000);
        }
      })
      .catch((errors) => {
        setTimeout(() => {
          setProcessing(false);
          toast.success(errors);
          navigate("/");
        }, 5000);
        console.log(errors);
      });
  };

  const payUsingCredits = (totalAmount,orderIds,customerId) => {
    setCheckoutPageLoader(true);
    const payload={
      order_amount:totalAmount,
      CustomerId:customerId,
      orderIds:orderIds,
      Credit_on_order:totalAmount,
    }
    // const payload = {
    //   // creditCardInformation: selectedPaymentSource,
    //   orderInformation: orderIds.map(
    //     (orderInfo) => (
    //       console.log(orderIds),
    //       console.log("orderInfo.item_name"),
    //       {
    //         orderNumber: orderInfo.item_name,
    //         totalPrice: orderInfo.amount,
    //         id: orderInfo.id,
    //       }
    //     )
    //   ),
    //   orderInfo: paypalAdvanceCheckoutObj,
    //   email: customerEmail,
    // };
    setBtnDisabled(true)
    capturePaymentUsingcredits(payload)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setCheckoutPageLoader(false);
            setProcessing(false);
            toast.success(response.message);
            setBtnDisabled(false)
            navigate("/");
          }, 5000);
        } else {
          setTimeout(() => {
            setCheckoutPageLoader(false);
            setProcessing(false);
            toast.error(response.message);
            setBtnDisabled(false)
            navigate("/");
          }, 5000);
        }
      })
      .catch((errors) => {
        setTimeout(() => {
          setCheckoutPageLoader(false);
          setProcessing(false);
          toast.success(errors);
          setBtnDisabled(false)
          navigate("/");
        }, 5000);
        console.log(errors);
      });
  };

  const selectPaymentSource = (e) => {
    setSelectedPaymentSource(e.target.value);
  };

  return (
    <>
      {/* Stripe modal */}
      {isOpencard && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setOpencard(!isOpencard);
                }}
              >
                ×
              </div>

              <h2 style={{ textAlign: "left" }}>Pay</h2>
              <hr />
              <div className="justify-content-center row">
                <div className="col-md-12">
                  {/* <Elements stripe={promise}> */}
                  <CheckoutForm onClick={handleToken} secret={clientSecret} />
                  {/* </Elements> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`right_sidebar tab-content ${
          checkoutPageLoader ? "bg_blur" : ""
          }`}
      >
        {checkoutPageLoader ? (
          <div className="checkout-spinner">
            <div className="spinner_div">
              <Spinner />
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Stripe modal */}
        <section className="bt_batch_overview py-0">
          <div className="container-fluid px-0">
            <div className="row g-0">
              <div className="col-12 d-flex">
                <div className="right_sidebar">
                  <div className="top_section">
                    <BackButton />
                    <h1>Checkout </h1>
                  </div>
                  <div className="bottom_layout">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <div className="analytics_overview checkout">
                        {/* <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="date-colm">
                            <h2>Select your saved card</h2>
                          </div>
                        </div>
                      </div> */}
                        {/* <div className="row gx-3">
                        {cardLoader ? (
                          <div className="spinner_div">
                            <Spinner/>
                          </div>
                        ) : (
                          <>
                            {!!cardData &&
                            cardData.length > 0 &&
                            cardData != null ? (
                              cardData.map((item, i) => {
                                return (
                                  <div className="col-md-3">
                                    <div className="box_colm colm_1">
                                      <div className="weekly_sale">
                                        <h4>{item?.brand}</h4>
                                        <p>*** *** ****({item?.last4} )</p>
                                        <div className="img_bx">
                                          <input
                                            type="radio"
                                            name="ccard"
                                            className="form-check-input"
                                            value={item?.pmId}
                                            onClick={handleChange}
                                            checked={chooseCard == item?.pmId}
                                          />
                                        </div>
                                      </div>
                                      <div className="price_tag">
                                        <p>
                                          Expire{" "}
                                          {`${item?.expMonth}/${item?.expYear}`}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p>No cards found</p>
                            )}
                          </>
                        )}
                      </div> */}
                      </div>
                    </div>
                    {/* {paymentLoader ? (
                    <div className="spinner_div">
                      <Spinner/>
                    </div>
                  ) : (
                    ""
                  )} */}
                    {/* or card  */}
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <div className="analytics_overview checkout">
                        <div className="row">
                          <div className="col-lg-4 col-md-6">
                            <br />
                          </div>
                        </div>
                        <div className="row gx-3">
                          <div className="col-md-6">
                            {/* credit balance notification start */}
                            <div className="balance-box d-flex justify-content-between mb-4">



                              {/* <div className="d-flex justify-content-between align-items-center"> */}
                              {(Number(totalAmount) <= Number(remainingAmount) && creditStatus !== "disabled" ) ?   <>
                                <div>
                                  <h4 className="balance-title mb-2">Available Credits</h4>
                                    <h2 className="balance-amt">{ `$${Number(remainingAmount).toFixed(2)}` }</h2>
                                </div>
                                <div>
                                  <br></br>
                                  <h2 className="balance-amt"> - </h2>
                                </div>
                                <div>
                                  <h4 className="balance-title mb-2">Order Amount</h4>
                                    <h2 className="balance-amt">{ `$${Number(totalAmount).toFixed(2)}` }</h2>
                                </div>
                                <div>
                                  <br></br>
                                  <h2 className="balance-amt"> = </h2>
                                </div>
                                <div>
                                  <h4 className="balance-title mb-2">Total payable Amount</h4>
                                  <h2 className="balance-amt">0</h2>
                                </div>
                              </>
                                : ""}
                              {(Number(totalAmount) > Number(remainingAmount)) ?
                                <>
                                  <div>
                                    <h4 className="balance-title mb-2">Order Amount</h4>
                                    <h2 className="balance-amt">{ `$${Number(totalAmount).toFixed(2)}` }</h2>
                                  </div>
                                  <div>
                                    <br></br>
                                    <h2 className="balance-amt"> - </h2>
                                  </div>
                                  <div>
                                    <h4 className="balance-title mb-2">Available Credits</h4>
                                    <h2 className="balance-amt">{ `$${ Number(remainingAmount).toFixed(2)}` }</h2>
                                  </div>
                                  <div>
                                    <br></br>
                                    <h2 className="balance-amt"> = </h2>
                                  </div>
                                  <div>
                                    <h4 className="balance-title mb-2">Total payable Amount</h4>
                                    <h2 className="balance-amt">{ `$${Number(totalAmount-remainingAmount).toFixed(2)}` }</h2>
                                  </div>
                                </>
                                : ""}
                              {(Number(totalAmount) <= Number(remainingAmount) && creditStatus === "disabled") ? <>
                                <div>
                                  <h4 className="balance-title mb-2">Order Amount</h4>
                                    <h2 className="balance-amt">{ `$${Number(totalAmount).toFixed(2)}` }</h2>
                                </div>
                                <div>
                                  <br></br>
                                  <h2 className="balance-amt"> - </h2>
                                </div>
                                <div>
                                  <h4 className="balance-title mb-2">Available Credits</h4>
                                    <h2 className="balance-amt">{ `$${remainingAmount.toFixed(2)}` }</h2>
                                </div>
                                <div>
                                  <br></br>
                                  <h2 className="balance-amt"> = </h2>
                                </div>
                                <div>
                                  <h4 className="balance-title mb-2">Total payable Amount</h4>
                                    <h2 className="balance-amt">{ `$${Number(totalAmount-remainingAmount).toFixed(2)}` }</h2>
                                </div>
                              </>
                                : ""}
                              {/* </div> */}
                            </div>
                            {/* credit balance notification end */}
                            {/* <div className="button_session custom_cx_button">
                            {processing ? (
                              <div className="spinner_div">
                                <Spinner/>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="combine_btn_colm strip_payment_btn">
                              {!!chooseCard && chooseCard != null ? (
                                <button
                                  disabled={
                                    processing || paymentLoader || disabled
                                  }
                                  className={`combine_button ${
                                    disabled || paymentLoader
                                      ? "disabled_btn"
                                      : "combine_button"
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // setOpencard(!isOpencard);
                                    handleCardPay();
                                  }}
                                  name="combine"
                                >
                                  Pay ${totalAmount}
                                </button>
                              ) : (
                                <button
                                  className="combine_button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setOpencard(!isOpencard);
                                  }}
                                  name="combine"
                                >
                                  Pay ${totalAmount}
                                </button>
                              )}
                            </div>
                          </div>
                          <span className="or_span">OR</span> */}

                            {/*select payment method
                          add new card or choose from selected */}

                            <div className="row">
                              <div className="col-md-6">
                               <div className={ `box_colm colm_1 ${(Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled" ? "disabled" : ""}` } >
                                  <div className="weekly_sale">
                                    <div className="img_bx">
                                      <input
                                        type="radio"
                                        name="ccard_existing"
                                        className="form-check-input"
                                        onClick={handleChangePaymentSource}
                                        value="existing"
                                        checked={paymentSource === "existing"}
                                       disabled = {(Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled"}
                                      />
                                    </div>
                                  </div>
                                  <div className="price_tag">
                                    <p>Payment from Existing Saved Card</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                               <div className={`box_colm colm_1 ${(Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled" ? "disabled" : ""}` }>
                                  <div className="weekly_sale">
                                    <div className="img_bx">
                                      <input
                                        type="radio"
                                        name="ccard_new"
                                        className="form-check-input"
                                        value="new"
                                        onClick={handleChangePaymentSource}
                                        checked={paymentSource === "new"}
                                       disabled = {(Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled"}
                                      />
                                    </div>
                                  </div>
                                  <div className="price_tag">
                                    <p>Payment from new Card</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {paymentSource === "existing" && (
                              <>
                                <div className="row gx-3">
                                  {cardLoader ? (
                                    <div className="spinner_div">
                                      <Spinner />
                                    </div>
                                  ) : (
                                    <>
                                      {!!paypalCardData &&
                                        paypalCardData.length > 0 &&
                                        paypalCardData != null ? (
                                        paypalCardData.map((item, i) => {
                                          return (
                                            <div
                                              className="col-md-6"
                                              style={{ marginTop: "20px" }}
                                            >
                                              <div className={`box_colm colm_1 ${(Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled" ? "disabled" : ""}` }>
                                                <div className="weekly_sale">
                                                  <h4>{item?.card_name}</h4>
                                                  <p>
                                                    *** *** ****(
                                                    {item?.card_number} )
                                                  </p>
                                                  <div className="img_bx">
                                                    <input
                                                      type="radio"
                                                      name="ccard"
                                                      className="form-check-input"
                                                      value={item?._id}
                                                      onClick={
                                                        selectPaymentSource
                                                      }
                                                      checked={
                                                        selectedPaymentSource ==
                                                        item?._id
                                                      }
                                                      disabled = {(Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled"}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="price_tag">
                                                  <p>
                                                    Expire{" "}
                                                    {`${item?.card_expiry_month}/${item?.card_expiry_year}`}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p>No cards found</p>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="button_session custom_cx_button">
                                    {processing ? (
                                      <div className="spinner_div">
                                        <Spinner />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="combine_btn_colm strip_payment_btn">
                                      {!!selectedPaymentSource &&
                                        selectedPaymentSource !== null && (
                                          <button
                                            disabled={
                                              processing ||
                                              paymentLoader ||
                                              disabled
                                            }
                                            className={`combine_button ${
                                              disabled || paymentLoader
                                                ? "disabled_btn"
                                                : "combine_button"
                                              }`}
                                            onClick={(e) => {
                                              payUsingExistingCard(e);
                                            }}
                                            name="combine"
                                          >
                                            Pay ${(Number(totalAmount) - Number(remainingAmount)).toFixed(2)}
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {paymentSource === "new" && (
                              <PaypalAdvanceCheckout
                                cartDetails={cartDetails}
                                cartId={cartid}
                                totalAmount={totalAmount}
                                paypalAdvanceCheckoutObj={
                                  paypalAdvanceCheckoutObj
                                }
                                orderIds={orderIds}
                                customerEmail={customerEmail}
                                remainingAmount={remainingAmount}
                              />
                            )}
                            <PayPalScriptProvider options={initialOptions}>
                              <span className="or_span">OR</span>

                              <PaypalButton
                                createOrder={createOrderHandler}
                                onApprove={onApproveHandler}
                                onError={onErrorHandler}
                                onCancel={onCancelHandler}
                                disabled = { (Number(totalAmount) < Number(remainingAmount)) && creditStatus !== "disabled"  }
                              />
                            </PayPalScriptProvider>

                            {/* { totalAmount < remainingAmount &&   */}
                            <div className="row">
                              <div className="button_session custom_cx_button">
                                <div className="combine_btn_colm strip_payment_btn">
                                  <button
                                    disabled={
                                    isBtnDisabled ||(Number(totalAmount) > Number(remainingAmount)) || creditStatus === "disabled" || processing || paymentLoader || disabled
                                    }
                                   className={`combine_button ${
                                    (Number(totalAmount) > Number(remainingAmount)) || creditStatus === "disabled" || disabled || paymentLoader 
                                        ? "disabled_btn "
                                        : "combine_button"
                                      } ${isBtnDisabled ? 'opacity-50' : ""}`}
                                    onClick={(e) => {
                                     payUsingCredits(totalAmount,orderIds,customerId);
                                    }}
                                    name="combine"
                                  >
                                    Pay Using Credits ${totalAmount}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Checkout;
