export const All_Products = {
    Heading : "Compare Free vs Platinum:",
    title:"All products",
    details:"Receive website and sync access to all of Boutique Tree’s wonderful styles including denim, shoes, tops, dresses, coverups, accessories, and everything in between!",
    rowData:[
        {fee:"Product Access & Pricing", 
        no_monthly_fee:"Access to all Launch items on Launch Days with full size runs",
        $299_per_Month:"Access to all Launch items on Launch Days with full size runs.  10% discount on all orders.",
        star:true,
        },
        {fee:"Shipment Time", 
            no_monthly_fee:"Orders ship within 2-4 business days",
            $299_per_Month:"Orders ship within 1-2 business days",
            star:true,
        },
        {fee:"Sample Access", 
            no_monthly_fee:"Samples may be purchased on Launch Day, without prior access to Samples.",
            $299_per_Month:"Premium accessnto purchasing samples prior to Launch Days.",
            star:true,
        },
        {fee:"Tracking auto syncs", 
            no_monthly_fee:"Tracking auto syncs to your Shopify when orders ship",
            $299_per_Month:"Tracking auto syncs to your Shopify when orders ship",
            star:false,
        },
        {fee:"Brand Access", 
            no_monthly_fee:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
            $299_per_Month:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
            star:false,
        },
        {fee:"Access to promotional material", 
            no_monthly_fee:"Photography & Video promotional material available to use.",
            $299_per_Month:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
            star:false,
        },
        {
            fee:"Shipping Costs", 
            no_monthly_fee:"Flat Rate Shipping Costs",
            $299_per_Month:"Flat Rate Shipping Costs",
            star:false,
            link:true,
            href:"https://boutiquetree.com/policies/shipping-policy"
        },

    ]
    }
    
    export const High_Quantity = {
        Heading : "Compare Free vs Platinum:",
        title:"Recent Launches + High Quantity",
        details:"Receive website and sync access to Boutique Tree’s wonderful styles that are the newest and higher quantities items including denim, shoes, tops, dresses, coverups, accessories, and everything in between!  Lower quantities styles are not included.  You would be missing out on many of our sale items when selecting this sync.",
        rowData:[
            {fee:"Product Access & Pricing", 
            no_monthly_fee:"Access to all Launch items on Launch Days with full size runs.  Products with lower quantities will not be included on this sync.",
            $299_per_Month:"Access to all Launch items on Launch Days with full size runs.  Products with lower quantities will not be included on this sync. 10% discount on all order.",
            star:true,
            },
            {fee:"Shipment Time", 
                no_monthly_fee:"Orders ship within 2-4 business days",
                $299_per_Month:"Orders ship within 1-2 business days",
                star:true,
            },
            {fee:"Sample Access", 
                no_monthly_fee:"Samples may be purchased on Launch Day, without prior access to Samples.",
                $299_per_Month:"Premium accessnto purchasing samples prior to Launch Days.",
                star:true,
            },
            {fee:"Tracking auto syncs", 
                no_monthly_fee:"Tracking auto syncs to your Shopify when orders ship",
                $299_per_Month:"Tracking auto syncs to your Shopify when orders ship",
                star:false,
            },
            {fee:"Brand Access", 
                no_monthly_fee:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
                $299_per_Month:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
                star:false,
            },
            {fee:"Access to promotional material", 
                no_monthly_fee:"Photography & Video promotional material available to use.",
                $299_per_Month:"Photography & Video promotional material available to use.",
                star:false,
            },
            {fee:"Shipping Costs", 
                no_monthly_fee:"Flat Rate Shipping Costs",
                $299_per_Month:"Flat Rate Shipping Costs",
                star:false,
                 link:true,
            href:"https://boutiquetree.com/policies/shipping-policy"
            },
    
        ]
        }
        

        export const Denim_ = {
            Heading : "Compare Free vs Platinum:",
            title:"Denim + Shoes",
            details:"Receive website and sync access ONLY to denim and shoes styles.. Other styles (tops, dresses, coverups, accessories, etc.) will NOT be available to you on the website or sync with this product selection.",
            rowData:[
                {fee:"Product Access & Pricing", 
                no_monthly_fee:"Access to all Denim and Shoes Launch items on Launch Days with full size runs.  Other clothing and accessories items are not included.",
                $149_per_Month:"Access to all Denim and Shoes Launch items on Launch Days with full size runs.  Other clothing and accessories items are not included. 10% discount on all orders.",
                star:true,
                },
                {fee:"Shipment Time", 
                    no_monthly_fee:"Orders ship within 2-4 business days",
                    $149_per_Month:"Orders ship within 1-2 business days",
                    star:true,
                },
                {fee:"Sample Access", 
                    no_monthly_fee:"Access to all Launch items on Launch Days with full size runs.",
                    $149_per_Month:"Premium accessnto purchasing samples prior to Launch Days.",
                    star:true,
                },
                {fee:"Tracking auto syncs", 
                    no_monthly_fee:"Tracking auto syncs to your Shopify when orders ship",
                    $149_per_Month:"Tracking auto syncs to your Shopify when orders ship",
                    star:false,
                },
                {fee:"Brand Access", 
                    no_monthly_fee:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
                    $149_per_Month:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
                    star:false,
                },
                {fee:"Access to promotional material", 
                    no_monthly_fee:"Photography & Video promotional material available to use.",
                    $149_per_Month:"Photography & Video promotional material available to use.",
                    star:false,
                },
                {fee:"Shipping Costs", 
                    no_monthly_fee:"Flat Rate Shipping Costs",
                    $149_per_Month:"Flat Rate Shipping Costs",
                    star:false,
                     link:true,
            href:"https://boutiquetree.com/policies/shipping-policy"
                },
        
            ]
            }

            export const Accessories_ = {
                Heading : "Compare Free vs Platinum:",
                title:"Shoes + Accessories",
                details:"Receive website and sync access ONLY to shoes & accessories styles. This option is designed to work well for boutiques that are part of a clothing MLM and cannot offer clothing styles. Other styles (tops, dresses, coverups, shoes, etc.) will NOT be available to you on the website or sync with this product selection.",
                rowData:[
                    {fee:"Product Access & Pricing", 
                    no_monthly_fee:"Access to all Shoes and Accessories Launch items on Launch Days with full size runs.  Other clothing and denim items are not included.",
                    $49_per_Month:"Access to all Shoes and Accessories Launch items on Launch Days with full size runs.  Other clothing and denim items are not included. 10% discount on all orders.",
                    star:true,
                    },
                    {fee:"Shipment Time", 
                        no_monthly_fee:"Orders ship within 2-4 business days",
                        $49_per_Month:"Orders ship within 1-2 business days",
                        star:true,
                    },
                    {fee:"Sample Access", 
                        no_monthly_fee:"Samples may be purchased on Launch Day, without prior access to Samples.",
                        $49_per_Month:"Premium accessnto purchasing samples prior to Launch Days.",
                        star:true,
                    },
                    {fee:"Tracking auto syncs", 
                        no_monthly_fee:"Tracking auto syncs to your Shopify when orders ship",
                        $49_per_Month:"Tracking auto syncs to your Shopify when orders ship",
                        star:false,
                    },
                    {fee:"Brand Access", 
                        no_monthly_fee:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
                        $49_per_Month:"Your favorite brands, including Judy Blue, Corkys, Blowfish, and more!",
                        star:false,
                    },
                    {fee:"Access to promotional material", 
                        no_monthly_fee:"Photography & Video promotional material available to use.",
                        $49_per_Month:"Photography & Video promotional material available to use.",
                        star:false,
                    },
                    {fee:"Shipping Costs", 
                        no_monthly_fee:"Flat Rate Shipping Costs",
                        $49_per_Month:"Flat Rate Shipping Costs",
                        star:false,
                         link:true,
            href:"https://boutiquetree.com/policies/shipping-policy"
                    },
            
                ]
                }