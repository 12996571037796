/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import Spinner from "../spinner/Spinner"
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from '../Api';
import AddCardForm from '../payment/stripe/AddCardForm';
import { Grid, Stack, Button,Box, Dialog, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { darken } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormLogoInfo } from './SignUpInfoForm';
import AddPayPalCardForm from '../payment/paypal/AddPayPalCardForm';
import { getTempUserData, getUserData, removeItem, setItem } from '../utils';
import {
  SaveCustomerCard,
  DeleteCardById,
  GetCustomerCard,
  DeletePaypalCardById,
  GetCustomerPaypalCard,
  ActivatePaypalCard,
  SetCustomerPaypalAutopay} from "../auth.js"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Service from '../../Pages/Service.js';
import  IconButton from '@mui/material/IconButton';
import  CloseIcon from '@mui/icons-material/Close';



export default function ProductBillingForm({onTabPrev,onTabNext}) {
    var tempUserData = getTempUserData();
    let navigate = useNavigate();

    const [cardForm, setCardForm] = useState({
      firstName: "",
      cardName: "",
      month: "",
      year: "",
      cvv: "",
      bilingZip: "",
    });

    var customerEmail =
    !!tempUserData && tempUserData ? tempUserData?.customerInfo?.customer_data?.email || tempUserData?.customerInfo?.email : "";


  var customerId =
    !!tempUserData && tempUserData
      ? tempUserData?.customerInfo?.customer_data?.id || tempUserData?.customerInfo?.shopify_id
      : "";


  
    const cardChange = (e) => {
      setCardForm({
        ...cardForm,
        [e.target.name]: e.target.value,
      });
    };


    const [isOpencard, setOpencard] = useState(false);
    const [cardLoader, setCardLoader] = useState(false);
    const [autopay, setAutopay] = useState(false);
    const [paypalAutopay, setPaypalAutopay] = useState(false);
    const [autopayLoader, setAutopayLoader] = useState(false);
    const [cardData, setCardData] = useState([]);
    const [paypalCardData, setPaypalCardData] = useState([]);

    const promise = loadStripe(STRIPE_PUBLIC_KEY);
    const [Loader, setLoader] = useState(false);

    const isMobile = useMediaQuery('(max-width:500px)');

    useEffect(() => {
      let LoggedIn = getUserData();
      if (LoggedIn && LoggedIn != "") {
        removeItem("tempUserData")
        return navigate("/");
      }
    }, [])

    useEffect(() => {
      getPaypalCards(tempUserData?.customerInfo?.customer_data?.id || tempUserData?.customerInfo?.shopify_id);
    },[])

    const handleToken = async (token) => {
        SaveCustomerCard(token,true)
          .then((res) => {
            if (res.status == 200) {
              // Get customer cards
              getCards(tempUserData.customerInfo.customer_data.id || tempUserData?.customerInfo?.shopify_id);
              setOpencard(false);
              toast.success(res.message);
            } else {
              setOpencard(false);
              toast.error(res.message);
              console.log("error");
            }
          })
          .catch((err) => {
            setOpencard(false);
            console.log(err);
          });
      };

  const [isOpenPaypalCard, setIsOpenPaypalCard] = useState(false);
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [deleteCardLoader, setDeleteCardLoader] = useState(false);
  const [DeleteId, setDeleteId] = useState("");

  const [isPaypalOpenDelete, setPaypalOpenDelete] = useState(false);
  const [deletePaypalCardLoader, setDeletePaypalCardLoader] = useState(false);
  const [DeletePaypalId, setDeletePaypalId] = useState("");

      // Get customer cards
  const getPaypalCards = (customerId) => {
    if (customerId != null && customerId != "") {
      var customerInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      setCardLoader(true);
      GetCustomerPaypalCard(customerInfo,true)
        .then((res) => {
          if (res.status == 200) {
            setCardLoader(false);
            setPaypalCardData(res.data);

            let setDefaultCard = res.data.find((item) => item?.primary);
            setPaypalChooseCard(setDefaultCard?._id);
            // cards Object
          } else {
            setCardLoader(false);
            console.log(res.message);
          }
        })
        .catch((err) => {
          setCardLoader(false);
          toast.error(err.message);
          console.log(err);
        });
    }
  };

  const [chooseCard, setChooseCard] = useState(""); //default card
  const [paypalChooseCard, setPaypalChooseCard] = useState(""); //default card
  const [primaryCard, setPrimary] = useState(false); //default card
  const [primaryPaypalCard, setPrimaryPaypal] = useState(false); //default 
  
  const primaryIsActive = () => setPrimary((primaryCard) => !primaryCard);
  const primaryPaypalIsActive = () => setPrimaryPaypal((primaryPaypalCard) => !primaryPaypalCard);

  // Get customer cards
  const getCards = (customerId) => {
    if (customerId != null && customerId != "") {
      var customerInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      setCardLoader(true);
      GetCustomerCard(customerInfo,true)
        .then((res) => {
          if (res.status == 200) {
            setCardLoader(false);
            setCardData(res.data);

            let setDefaultCard = res.data.find((item) => item?.primary);
            setChooseCard(setDefaultCard?.pmId);
            // cards Object
          } else {
            setCardLoader(false);
            console.log(res.message);
          }
        })
        .catch((err) => {
          setCardLoader(false);
          toast.error(err.message);
          console.log(err);
        });
    }
  };


  const deletePaypalCardAlert = (cardId) => {
    setPaypalOpenDelete(!isPaypalOpenDelete);
    setDeletePaypalId(cardId);
  }

  const handlePayPalCardChange = (e) => {
    setPaypalChooseCard(e.target.value);
    setPrimaryPaypal(true);

    try {
      primaryPaypalIsActive();
      var data = {
        email: customerEmail,
        _id: e.target.value,
        primary: !primaryPaypalCard,
        customerId: customerId,
      };
      ActivatePaypalCard(data,true)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
      
  const handleCloseModel = () => {
    setIsOpenPaypalCard(!isOpenPaypalCard);
    getPaypalCards(tempUserData?.customerInfo?.customer_data?.id || tempUserData?.customerInfo?.shopify_id);
  }

        //  Delete card
  const handleDelete = (cardId) => {
    var cardObj = {
      card_id: cardId,
      customer_id: customerId,
    };

    setDeleteCardLoader(true);
    DeleteCardById(cardObj,true)
      .then((res) => {
        if (res.status === 200) {
          // user Object
          setTimeout(() => {
            setDeleteCardLoader(false);
            getCards(tempUserData?.customerInfo?.customer_data?.id || tempUserData?.customerInfo?.shopify_id);
            setOpenDelete(false);
            toast.success(res.message);
          }, 3000);
        } else {
          setDeleteCardLoader(false);
          setOpenDelete(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setDeleteCardLoader(false);
        setOpenDelete(false);
        toast.error("Something went wrong");
      });
  };

  //  Delete card
  const handleDeletePaypalCard = (cardId) => {
    var cardObj = {
      card_id: cardId,
      customer_id: customerId,
    };

    setDeletePaypalCardLoader(true);
    DeletePaypalCardById(cardObj,true)
      .then((res) => {
        if (res.status === 200) {
          // user Object
          setTimeout(() => {
            setDeletePaypalCardLoader(false);
            getPaypalCards(tempUserData.customerInfo.customer_data.id || tempUserData?.customerInfo?.shopify_id);
            setPaypalOpenDelete(false);
            toast.success(res.message);
          }, 3000);
        } else {
          setDeletePaypalCardLoader(false);
          setPaypalOpenDelete(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setDeleteCardLoader(false);
        setPaypalOpenDelete(false);
        toast.error("Something went wrong");
      });
  };

  const toggleIsActive = () => setAutopay((autopay) => !autopay);
  const togglePaypalIsActive = () => setPaypalAutopay((paypalAutopay) => !paypalAutopay);

  const handlePaypalAutopay = (customerId) => {
    try {
      if (!!paypalCardData && paypalCardData != null && paypalCardData.length > 0) {
        togglePaypalIsActive();
        setAutopayLoader(true);
        if (customerId != null && customerId != "") {
          var customerInfo = {
            customer_id: customerId && customerId.replace(/^\D+/g, ""),
            paypalAutopay: !paypalAutopay,
          };
          SetCustomerPaypalAutopay(customerInfo,true)
            .then((res) => {
              if (res.status == 200) {
                setPaypalAutopay(res.data);
                setAutopayLoader(false);
                toast.success(res.message);
              } else {
                setPaypalAutopay(res.data);
                setAutopayLoader(false);
                toast.error(res.message);
              }
            })
            .catch((err) => {
              setAutopayLoader(false);
              toast.error(err.message);
              console.log(err);
            });
        }
      } else {
        toast.error("Please add card first.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSubscription = () => {
    navigate("/service");
  }

  const [openDialog, setOpenDialog] = useState(false);

  const onOpenDialog= () => {
    setOpenDialog(true)
  }

  const onCloseDialog = () =>{
    setOpenDialog(false)
  }

  const isFreePlan = true;
  const planName = "PLATINUM"


  return (
    <>
  {/* Stripe modal */}
  {isOpenDelete && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setOpenDelete(!isOpenDelete);
                }}
              >
                ×
              </div>

              <p style={{textAlign: "center"}}>
                {" "}
                Are you sure you want to delete this card?
              </p>
              {deleteCardLoader && (
                <div className="spinner_div">
                  <Spinner/>Card Informat
                </div>
              )}
              <div className="justify-content-center row">
                <div className="btn_wrap">
                  <button
                    className="btn no-btn-dt no_delete_btn"
                    onClick={(e) => {
                      setOpenDelete(!isOpenDelete);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn yes-btn-dt yes_delete_btn"
                    onClick={(e) => {
                      handleDelete(DeleteId);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} {/* Stripe modal */}
      {isPaypalOpenDelete && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setPaypalOpenDelete(!isPaypalOpenDelete);
                }}
              >
                ×
              </div>

              <p style={{textAlign: "center"}}>
                {" "}
                Are you sure you want to delete this card?
              </p>
              {deletePaypalCardLoader && (
                <div className="spinner_div">
                  <Spinner/>
                </div>
              )}
              <div className="justify-content-center row">
                <div className="btn_wrap">
                  <button
                    className="btn no-btn-dt no_delete_btn"
                    onClick={(e) => {
                      setPaypalOpenDelete(!isPaypalOpenDelete);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn yes-btn-dt yes_delete_btn"
                    onClick={(e) => {
                      handleDeletePaypalCard(DeletePaypalId);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Stripe modal */}
      {
        isOpenPaypalCard && (
          <AddPayPalCardForm showModel={isOpenPaypalCard} setShowModel={handleCloseModel} hideModel={false}
                             customer={tempUserData?.customerInfo?.customer_data || tempUserData?.customerInfo} isUseTempUser={true}/>
        )
      }

    <div className="bottom_layout white">
      <div className=''>
      <Stack sx={{textAlign:'left'}}>
      <h3 style={{fontWeight: 600, color:"#919191"}}>Product Billing Information</h3>

      <Stack sx={{
        marginTop:"14px",
        padding: "10px",
        fontSize:"12px",
        fontFamily:'Poppins',
        color: "white",
        textAlign: "center",
        backgroundColor:"#657967"
      }}>
        This payment info used for product payments only
      </Stack>
      </Stack>
      
      <Stack sx={{textAlign:'left', mt:2}}>
      <h3 style={{fontWeight: 600, color:"#919191"}}>Card Information</h3>
      <div className="autopay_container_wrap">
          <div className="autop_pay_cont">
            <label className="autopay_ct">
            <input
                value={''}
                  type="checkbox"
                  name="autopay_lb"
                  checked={paypalAutopay}
                  className="autopay_checkbox"
                  onChange={() => handlePaypalAutopay(tempUserData?.customerInfo?.customer_data?.id || tempUserData?.customerInfo?.shopify_id)}
                />{" "}
                Enable Autopay 
            </label>
          </div>
        </div>
      </Stack>
        
      </div>
      <div className="account_detail billing_form">
            <div className="row">
              <div className="col-12">
                <div className="table_session">
                  <table className="table table-bordered">
                    <thead>
                    <tr>
                      <th>Name on Card</th>
                      <th>Expire Date</th>
                      <th>Card Info</th>
                      <th>Default card</th>
                      <th>Action</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {cardLoader ? (
                     <tr><td>
                      <div className="spinner_div">
                        <Spinner/>
                      </div>
                      </td></tr>
                    ) : (
                      <>
                        {!!paypalCardData &&
                        paypalCardData != null &&
                        paypalCardData.length > 0 ? (
                          paypalCardData.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.card_name}</td>
                                <td>
                                  {item.card_expiry_month} / {item.card_expiry_year}
                                </td>
                                <td>
                                  {"**** **** **** "}
                                  {item.card_number}
                                </td>
                                <td>
                                  <div className="img_bx">
                                    <input
                                      type="radio"
                                      name="ccard"
                                      className="form-check-input"
                                      value={item?._id}
                                      onClick={handlePayPalCardChange}
                                      checked={
                                        paypalChooseCard == item?._id
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="icon_view">
                                  <p>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        deletePaypalCardAlert(item?._id);
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </p>{" "}
                                </td>
                                <td></td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <p>
                              No cards found. Please add a new card
                              </p>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    </tbody>
                  </table>
                </div>
                <div className="button_session">
                  <a
                    className="go_to_save_card"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpenPaypalCard(!isOpenPaypalCard);
                    }}
                  >
                    Add Card
                  </a>
                </div>
              </div>
            </div>
          </div>
      {/* Stripe modal */}
      {isOpencard && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setOpencard(!isOpencard);
                } }
              >
                ×
              </div>

              <h2 style={{ textAlign: "left" }}>Add card</h2>
              <hr />
              <div className="justify-content-center row">
                <div className="col-md-12">
                  <Elements stripe={promise}>
                    <AddCardForm
                      onClick={handleToken} />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-12">
        <Grid container spacing={2} sx={{ marginTop: '20px' }} direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
          <Grid item>
            <Button
              variant="contained"
              sx={{
                padding: "8px",
                width: isMobile ? '100%' : '150px !important',
                backgroundColor: '#f1f1f1',
                color: '#919191',
                boxShadow: 'none',
                // border: '1px solid #919191',
                '&:hover': {
                  backgroundColor: 'white',
                  boxShadow: 'none'
                },
              }}
              onClick={()=>{
            onTabPrev();
            setItem("step", 1)
          }}
              name="join"
              startIcon={<Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
                <ArrowBackIcon sx={{ color: '#5b5b5b' }} />
              </Box>}
            >
              <span style={{ fontWeight: 500 }}>Previous</span>
            </Button>
          </Grid>
          <Grid item>
            {Loader && (
              <div className="spinner_div">
                <Spinner />
              </div>
            )}
            <Button
              variant="contained"
              type="submit"
              name="join"
              sx={{
                padding: "8px",
                color: 'white',
                backgroundColor: '#B78580',
                width: isMobile ? '100%' : '150px !important',
                boxShadow: 'none',


                // border: '1px solid #B78580',
                '&:hover': {
                  backgroundColor: darken('#B78580', 0.1),
                  boxShadow: 'none',
                },
              }}
              onClick={()=>{
                onTabNext();
                setItem("step", 3)
              }}
              endIcon={<ArrowForwardIcon sx={{ color: 'white', fontWeight: 600 }} />}
            >
              Next
            </Button>
          </Grid>
        </Grid>

      </div>


      <FormLogoInfo/>
    </div>
    <Dialog 
    fullScreen
    open={openDialog}
    onClose={onCloseDialog}
    >

      <Service noNavLinks={true} isSelectPlan={true}/>
      <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            cursor:"pointer",
            position: 'absolute',
            right: 2,
            top: 2,
            zIndex:100,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{color:"#456645" }} fontSize="medium"/>
        </IconButton>
    </Dialog>
      </>
  )
}
