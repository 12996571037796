import React, {useState, useEffect} from "react";
import {GetPlans, GetOldMerchantConnections, subscribeConnection, SelectPlan, SubscribeDefaultConnection, ApproveSubscription} from "../components/auth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getItem, setItem, getUserData, setUserData} from "../components/utils";
import Spinner from "../components/spinner/Spinner";
import {toast} from "react-toastify";
import Footer from "../components/FooterNew";

const UpgradePlan = () => {
  
  const [searchParams] = useSearchParams();
  const selectedSavedPlans = getItem("selectedConnections");
  const [Loader, setLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  const [maxLimit, setMaxLimit] = useState(0);
  // const [userData, setUserData] = useState()
  const [userData, setUserDataState] = useState()
  const [selectedPlans, setSelecedPlans] = useState(selectedSavedPlans ? selectedSavedPlans :[])
  const [subscription, setSubscription] = useState(null)
  const [showUpgrade, setShowUpgrade] = useState(false);

  var subscriptionId = getItem('subscription_id');

  useEffect(() => {
    const charge_id = searchParams.get('charge_id')
    if(charge_id && subscriptionId) {
      approveSubscription(charge_id)
    }

  },[searchParams,subscriptionId])

  let navigate = useNavigate();

  const approveSubscription = (charge_id) => {
    setLoader(true);
    const payload = {
      charge_id: charge_id,
      subscription_id: subscriptionId
    }
    ApproveSubscription(payload)
    .then((res) => {
      if(res.status === 200) {
        let auth = getUserData();
        setUserData({
          ...auth,
          customerInfo: {
            ...auth.customerInfo,
            customer_data: {
              ...auth.customerInfo.customer_data,
              is_previous_merchant: 0
            }
          }
        });
        setLoader(false);
        // getCurrentSubscription()
        if(selectedPlans && selectedPlans.length > 0){
          onSubscribe();
        }else {
          navigate("/")
        }
       
        // setItem("selectedConnections",[]);
        // navigate("/upgrade-plan");
        toast.success(res.message);
      }else {
        setLoader(false);
        toast.error(res.message);
      }
    }).catch((err) => {
      setLoader(false);
      toast.error(err.message);
    })
  }


  const onSubscribeDefault = () => {
    setLoader(true);
    SubscribeDefaultConnection()
    .then((response) => {
      let auth = getUserData();
      setUserData({
        ...auth,
        customerInfo: {
          ...auth.customerInfo,
          customer_data: {
            ...auth.customerInfo.customer_data,
            is_previous_merchant: 0
          }
        }
      });
      setLoader(false);
      setItem("selectedConnections",[]);
      navigate("/")
    })
  }

  const getAllPlans = () => {
    setLoader(true);
    GetPlans()
    .then((res) => {
      if(res.status === 200) {
        setLoader(false);
        // toast.success(res.message);
        setPlans(res.data);
        let limit = 0
        res.data.forEach(plan => {
            if(plan.connection_limit > limit){
              limit = plan.connection_limit;
            }
        });
        setMaxLimit(limit);
      }else {
        setLoader(false);
        toast.error(res.message);
      }
    }).catch((err) => {
      setLoader(false);
      toast.error(err.message);
    })
  }

  useEffect(() => {
    getAllPlans();
    GetOldMerchantConnections()
    .then((response) => {
      setItem('migratedUser', response.data);
      setUserDataState(response.data)
      setSubscription({...response.data, plan_info: response.data.plan });
    })
  },[])

  const onCheckConnection = (con) => {
    if(selectedPlans.filter(x=> x.connection_uid === con.connection_uid).length > 0){
        setSelecedPlans(selectedPlans.filter(x => x.connection_uid !== con.connection_uid));
    }else{
          if((selectedPlans.length + (userData ? userData.subscribed_connection_count : 0))  === maxLimit)
            return;
          setSelecedPlans([...selectedPlans, con])
    }
  }

  useEffect(() => {
    if(userData && subscription){
      let connection_limit = subscription.plan_info.connection_limit;
      if(subscription.plan_info.is_free === 1){
        connection_limit = connection_limit - 1
      }
      if(connection_limit < (userData.subscribed_connection_count + selectedPlans.length)){
        setShowUpgrade(true)
      }else  setShowUpgrade(false)
    }else  setShowUpgrade(false)
   
  }, [selectedPlans, userData, subscription])


  const onSubscribe = () => {
    // if(!code){
    //   toast.error("please enter code");
    //   return;
    // }
    setLoader(true)
    subscribeConnection({
      connection_uid: selectedPlans.map(x => x.connection_uid)
    }).then((response) => {
      if (response.status === 200) {
        // setShowSubscribe(false)
        setItem("selectedConnections",[]);
        toast.success(response.message);
        setLoader(false);
        navigate("/")
      } else {
        setLoader(false);
        toast.error(response.message);
      }
    }).catch((error) => {
      toast.error(error.message);
      setLoader(false);
    })
  }

  const subscribePlanWithShopify = () => {
    setLoader(true);
    const payload = {
      plan_id: subscription.plan_info.id,
      callback_url:window.location.href //.replace('suggest-plan','upgrade-plan'),
    }
    SelectPlan(payload)
    .then((res) => {
      if(res.status === 200) {
        setLoader(false);
        setItem('subscription_id', res.data.subscription_id)
        window.open(res.data.confirm_url,"_self")
        toast.success(res.message);
      }else {
        setLoader(false);
        toast.error(res.message);
      }
    }).catch((err) => {
      setLoader(false);
      toast.error(err.message);
    })
  }

  const onConfirm = () => {
    // navigate("/suggest-plan")
    if(showUpgrade){
      console.log("selectedPlans ==", selectedPlans) 
      setItem("selectedConnections", selectedPlans)
      //navigate to next page
      navigate("/suggest-plan")
    }
    else if (selectedPlans.length === 0 && subscription.plan_info.is_free === 1) {
      onSubscribeDefault()
    }
    // else if(selectedPlans.length === 0){
    //   navigate("/")
    // }
    else{
      subscribePlanWithShopify()
      // onSubscribe()
    }
  }

  return (
    <section className="bt_batch_overview py-0">
    <div className="container-fluid px-0">
      <div className="row g-0">
        <div className="col-12 d-flex">
        <div className="auth_bg">
          <div className="container-fluid bg-white box-shadow">
            
            <div className="row g-0 x">
              
                {/* Sign up or Create profile */}
                <div className=" text-center">
                 
                  <div className="signup_title gap-5 mt-5 d-flex justify-content-center align-items-center">
                    <img className="top_img_text mt-0" src="assets/images/welcome-back.png"  alt="logo"  />
                    <div>
                      {/* <h1 >Choose the Plan that Works for your Boutique</h1> */}
                      <div className="tex-start upcoming-heading">
                        <b>We have new features for you! </b><br/>
                        Please review and confirm your existing <br/>
                        plan details to continue using Boutique Tree.
                      </div>
                    </div>
                  </div>
                  <div className="package-container row align-items-center">
                    <div className="old-plan-container">
                      <div className="package-item">
                        <div className="imgDiv">
                          <img src="assets/images/light.png"  alt="logo"  />
                        </div>
                        {/* {
                          (subscription.current_plan_id === plan.id) &&(
                            <div className="package-status">Active</div>
                          )
                        } */}
                        {/* <h1>{"plan.name"}</h1> */}
                        <h4 className="text-brown mb-5">{'Your Plan'}</h4>
                        {/* <h2></h2>
                        <h5></h5> */}
                      </div>
                      <div className="getstarted-brown">{subscription ? subscription.plan_info.name : 'Free'}</div>
                    </div>
                    
                    <div className={`col-6`}>
                      <div className="border p-2 rounded bg-light">
                        <h3 className="upcoming-text">Exciting Upcoming Collections</h3>
                        <div className="flex flex-wrap row">
                          {
                            userData && [...userData.unsubscribed_connections].map((con, index) => {
                              let products = [];
                              let productImages = [];
                              let allImages = []
                              con.collections.forEach(x => {
                                products = [...products, ...x.products]
                              });
                              products.forEach((x) => {
                                if(x.images && x.images.length>0){
                                  productImages = [...productImages, x.images[0]];
                                  if(x.images.length > 1){
                                    allImages = [...allImages, ...x.images.slice(1, x.images.length)]
                                  }
                                }
                              });
                              if(productImages.length < 4 && allImages.length > 0){
                                  productImages = [...productImages, ...allImages];
                              }
                              
                             return(
                               <div className="col-4 p-1">
                                <div className="border rounded shadow">
                                        <div className="p-0">
                                          <div style={{height:'50px'}} className="bg-light p-0">
                                            <img alt="" className="bg-light" style={{height:'50px', objectFit:'contain', width:'100%'}} src={(productImages && productImages.length > 0) ? productImages[0].src : ""}></img>
                                          </div>
                                        </div>
                                       
                                        <div className="d-flex p-0">
                                            <div className="p-0 col-4">
                                              <div style={{height:'50px'}} className="bg-light py-1 px-0">
                                                <img alt="" style={{height:'50px', objectFit:'contain', width:'100%'}} src={(productImages && productImages.length > 1) ? productImages[1].src : ""}></img>
                                              </div>
                                            </div>
                                            <div className="p-0 col-4">
                                              <div style={{height:'50px'}} className="bg-light p-1">
                                                <img alt="" style={{height:'50px', objectFit:'contain', width:'100%'}} src={(productImages && productImages.length > 2) ? productImages[2].src : ""}></img>
                                              </div>
                                            </div>
                                            <div className="p-0 col-4">
                                              <div style={{height:'50px'}} className="bg-light py-1 px-0">
                                                <img alt="" style={{height:'50px', objectFit:'contain', width:'100%'}} src={(productImages && productImages.length > 3) ? productImages[3].src : ""}></img>
                                              </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between align-items-start px-0">
                                          <div className="upcoming-connections-name">
                                            {con.connection_name}
                                            </div>
                                            <input
                                              type="checkbox"
                                              className="form-check-input select_full_order"
                                              id={`${index}populateTracking`}
                                              name={`${index}populateTracking`}
                                              // disabled={selectedPlans.length === maxLimit}
                                              // style={{marginLeft: "0.8rem"}}
                                              checked={selectedPlans.filter(x=> x.connection_uid === con.connection_uid).length > 0}
                                              onChange={(e) => {
                                                
                                                  onCheckConnection(con);
                                                  // updateTrackingStatus(!populateTracking)
                                                  // setPopulateTracking(!populateTracking)
                                                }
                                              }
                                            />
                                        </div>
                                        
                                </div>
                      
                               </div>
                              )
                            })
                          }
                          {
                            (!userData)&&(
                              <div className="coming-soon">Loading ...</div>
                            )
                          }
                          {
                            (userData && userData.unsubscribed_connections.length < 1)&&(
                              <div className="coming-soon">coming soon ...</div>
                            )
                          }
                        </div>
                      </div>
                    </div>

                  </div>

                  {
                    (selectedPlans.length + (userData ? userData.subscribed_connection_count : 0) === maxLimit) && (
                      <div className="d-flex justify-content-center py-0 px-auto">
                        <div className="bottom-upcoming-div">
                          {(selectedPlans.length > 0) ? "You have reached maximum selection limit!" : "You have already reached the maximum connection limit!"}
                        </div>  
                      </div>
                    )
                  }

                  <div className="d-flex justify-content-center py-3 pb-5 px-auto">
                    {
                      showUpgrade && (
                        <div className="bottom-upcoming-div">
                        This is a part of a connection you are not subscribed to! <b>To continue please upgrade now</b>
                      </div>
                      )
                    }
                        <div
                          className="px-5 py-2 upcoming-confirm"
                          onClick={(e) => {onConfirm()}}
                          // disabled={Loader}
                        >CONFIRM</div>
                  </div>
                </div>

                {/* End create profile  */}
            </div>
          </div>
        </div>
        
        </div>
        </div>
        {Loader && (
          <div className="spinner_div">
            <Spinner/>
          </div>
        )}
      </div>
      <Footer/>
    </section>
  );
};

export default UpgradePlan;
