import React from "react";
import {
  apiGet,
  apiPost,
  apiPut,
  setUserData,
  getUserData,
  clearUserData,
  clearFcmToken,
  getTempUserData,
  removeItem,
} from "./utils";
import {
  GET_CUSTOMER_INFO_DOMAIN,
  COUPON_GET_API,
  DELETE_ORDERS_API,
  GET_ALL_ORDERS_API,
  GET_FULL_ORDERS_API,
  LOGIN_API,
  LOGIN_TOKEN_API,
  LOGOUT_API,
  REMOVE_LINE_ITEM_API,
  SEARCH_ORDER_API,
  SIGNUP_API,
  SORT_ORDER_API,
  GET_MESSAGE,
  UPDATE_ADDRESS_ORDER_ITEM_API,
  UPDATE_ORDER_ITEM_API,
  SAVE_CARD,
  COMBINE_ORDERS_API,
  PAYMENT_API,
  GET_CARDS,
  PAYMENT_INTENT,
  PAYMENT_INTENT_CARD,
  GET_AUTOPAY,
  SET_AUTOPAY,
  PRIMARY_CARDS,
  TRANSACTION_API,
  ANALYTICS_API,
  ANALYTICS_GRAPH_API,
  REMOVE_CARD_API,
  CUSTOMER_FORGET,
  ADD_SHIPPING_ADDRESS,
  GET_CUSTOMER_ACCOUNT_DETAILS,
  UPDATE_CUSTOMER_ACCOUNT_DETAILS,
  UPDATE_CUSTOMER_SIGNUP,
  GET_CUSTOMER_ADDRESS_DETAILS,
  CAPTURE_ORDER_PAYMENT,
  GET_PAYPAL_CARDS,
  SAVE_PAYPAL_CARD,
  PRIMARY_PAYPAL_CARDS,
  SET_PAYAPL_AUTOPAY,
  CAPTURE_ORDER_PAYMENT_USING_CREDITS,
  GET_PAYPAL_AUTOPAY,
  REMOVE_PAYPAL_CARD_API,
  CAPTURE_ORDER_PAYMENT_USING_SAVED_CARDS,
  GET_REMAINING_AMOUNT_API,
  CUSTOMER_RESET,
  UPLOAD_LICENCE,
  SUBSCRIBE_CONNECTION,
  GET_CONNECTION,
  REMOVE_CONNECTION_SUBSCRIPTION,
  SUBSCRIPTION_PREFERENCES,
  SUBSCRIPTION_SETTINGS,
  GET_ALL_CONNECTION,
  UPDATE_SYNC_SETTING,
  GET_SYNC_SETTING,
  GET_ALL_PLANS,
  CREATE_PLAN,
  APPROVE_PLAN,
  CHECK_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_CONNECTION_DETAIL,
  COLLECTION_PRODUCTS,
  TRACKING_STATUS,
  GET_OLD_MERCHANT_CONNECTIONS,
  SUBSCRIBE_DEFAULT_CONNECTION,
  UPDATE_INVENTORY_LOCATION,
  FETCH_ALL_INVENTORY_LOCATIONS,
  REGISTER_USER_BY_SHOP,
  UPDATE_CUSTOMER_SIGNUP_ADDRESS,
  INSTALL_APP
} from "./Api";

export function getInstallAPP(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(INSTALL_APP, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AppLoginUsingToken(data) {
  return new Promise((resolve, reject) => {
    apiPost(LOGIN_TOKEN_API, data)
      .then((res) => {
        // user Object
        var userData = res.data;
        // Store in local storage
        if (res.status == 200) {
          removeItem("tempUserData")
          setUserData(userData);
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AppLogin(data) {
  return new Promise((resolve, reject) => {
    apiPost(LOGIN_API, data)
      .then((res) => {
        // user Object
        var userData = res.data;
        // Store in local storage
        if (res.status == 200) {
          removeItem("tempUserData")
          setUserData(userData);
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetRemainigAmount(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_REMAINING_AMOUNT_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AppSignUp(data, merchant_id) {
  return new Promise((resolve, reject) => {
    var tempUserData = getTempUserData();
    apiPut(`${UPDATE_CUSTOMER_SIGNUP}?customerid=${merchant_id}`, data, { Authorization: `Bearer ${tempUserData.customerAccessToken.accessToken}` })
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCustomerInfoByDomainName(data) {
  return new Promise((resolve, reject) => {
    apiGet(`${GET_CUSTOMER_INFO_DOMAIN}${data}`)
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function RegisterUserByShop(data) {
  return new Promise((resolve, reject) => {
    apiPost(`${REGISTER_USER_BY_SHOP}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateCustomerSignup(data, id) {
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPut(`${UPDATE_CUSTOMER_SIGNUP_ADDRESS}?customerid=${id}`, data, { Authorization: `Bearer ${tempUserData.customerAccessToken.accessToken}` })
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function UploadLicence(data, merchant_id) {
  return new Promise((resolve, reject) => {
    apiPost(`${UPLOAD_LICENCE}/${merchant_id}`, data)
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetPlans(isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiGet(GET_ALL_PLANS, {}, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function CheckSubscription(isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiGet(CHECK_SUBSCRIPTION, {}, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function SelectPlan(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(`${CREATE_PLAN}`, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function CancelSubscription(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(`${CANCEL_SUBSCRIPTION}`, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function ApproveSubscription(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(`${APPROVE_PLAN}`, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        // user Object
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addShippingAddress(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(ADD_SHIPPING_ADDRESS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateShippingAddress(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(UPDATE_ADDRESS_ORDER_ITEM_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeLineItem(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(REMOVE_LINE_ITEM_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetAllOrders(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_ALL_ORDERS_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetFullOrders(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_FULL_ORDERS_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get admin setting message
export function GetMessage() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(GET_MESSAGE, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCustomerAccountDetail(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_CUSTOMER_ACCOUNT_DETAILS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCustomerAddressDetail(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_CUSTOMER_ADDRESS_DETAILS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateCustomerAccountDetail(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(UPDATE_CUSTOMER_ACCOUNT_DETAILS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// customer save card
export function SaveCustomerCard(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(SAVE_CARD, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// customer save card
export function SaveCustomerPaypalCard(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  console.log(tempUserData, isUseTempUser);
  let token = "";
  if (userData) {
    token = userData?.customerAccessToken?.accessToken;
  } else {
    token = tempUserData?.customerAccessToken?.accessToken;
  }
  return new Promise((resolve, reject) => {
    apiPost(SAVE_PAYPAL_CARD, data, { Authorization: `Bearer ${token}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Combine order
export function CombineOrders(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(COMBINE_ORDERS_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

// Payment api
export function PaymentOrders(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(PAYMENT_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Create payment intent
export function CreatePaymentIntent(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(PAYMENT_INTENT, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Create payment intent Card
export function CardPaymentIntent(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(PAYMENT_INTENT_CARD, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get customers cards
export function GetCustomerCard(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_CARDS, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get customers cards
export function GetCustomerPaypalCard(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_PAYPAL_CARDS, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get Transactions
export function GetTransactions(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(TRANSACTION_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get Analytics Info
export function AnalyticsInfo(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(ANALYTICS_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get Analytics Info
export function GraphInfo(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(ANALYTICS_GRAPH_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get customers cards
export function ActivateCard(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(PRIMARY_CARDS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get customers cards
export function ActivatePaypalCard(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(PRIMARY_PAYPAL_CARDS, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Delete customers cards
export function DeleteCardById(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(REMOVE_CARD_API, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Delete customers cards
export function DeletePaypalCardById(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(REMOVE_PAYPAL_CARD_API, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get customers auto pay
export function GetCustomerAutopay(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_AUTOPAY, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get customers auto pay
export function GetCustomerPaypalAutopay(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(GET_PAYPAL_AUTOPAY, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Set customers auto pay
export function SetCustomerAutopay(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SET_AUTOPAY, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Set customers auto pay
export function SetCustomerPaypalAutopay(data, isUseTempUser = false) {
  var userData = getUserData();
  var tempUserData = getTempUserData();
  return new Promise((resolve, reject) => {
    apiPost(SET_PAYAPL_AUTOPAY, data, { Authorization: `Bearer ${isUseTempUser ? tempUserData.customerAccessToken.accessToken : userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetDiscountCoupon(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(COUPON_GET_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        // Store in local storage
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteOrderById(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(DELETE_ORDERS_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        // Store in local storage
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateOrderItem(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(UPDATE_ORDER_ITEM_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        // Store in local storage
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logoutUser(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(LOGOUT_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function searchOrder(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SEARCH_ORDER_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function orderSort(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SORT_ORDER_API, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Customer forget password
export function customerForget(data) {
  // var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(CUSTOMER_FORGET, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Customer reset password
export function customerReset(data) {
  // var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(CUSTOMER_RESET, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function captureOrderPayment(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(CAPTURE_ORDER_PAYMENT, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function captureOrderPaymentUsingSavedCards(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(CAPTURE_ORDER_PAYMENT_USING_SAVED_CARDS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function capturePaymentUsingcredits(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(CAPTURE_ORDER_PAYMENT_USING_CREDITS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function subscribeConnection(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SUBSCRIBE_CONNECTION, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCollectionProducts(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(`${COLLECTION_PRODUCTS}/${data.connection_id}`, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeConnection(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPut(REMOVE_CONNECTION_SUBSCRIPTION, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getConnections() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(GET_CONNECTION, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getConnectionDetail(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(`${GET_CONNECTION_DETAIL}/${data.subscription_id}`, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllConnections() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(GET_ALL_CONNECTION, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePreferences(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SUBSCRIPTION_PREFERENCES, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSettings(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SUBSCRIPTION_SETTINGS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateSyncSettings(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(UPDATE_SYNC_SETTING, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetSyncSetting() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(GET_SYNC_SETTING, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTracking(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPut(TRACKING_STATUS, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetOldMerchantConnections() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(GET_OLD_MERCHANT_CONNECTIONS, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function SubscribeDefaultConnection() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(SUBSCRIBE_DEFAULT_CONNECTION, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateInventoryLocation(data) {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiPost(UPDATE_INVENTORY_LOCATION, data, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetAllMerchantShopLocations() {
  var userData = getUserData();
  return new Promise((resolve, reject) => {
    apiGet(FETCH_ALL_INVENTORY_LOCATIONS, {}, { Authorization: `Bearer ${userData.customerAccessToken.accessToken}` })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logout() {
  // dispatch({type: types.CLEAR_REDUX_STATE});
  clearUserData();
  clearFcmToken();
}
