import React, { useState, useEffect, useMemo } from "react";
import { AppLogin, AppSignUp, UploadLicence } from "../../components/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  PhoneNumberInputRegex,
  formatPhoneNumberInput,
  getItem,
  getTempUserData,
  getUserData,
  removeItem,
  setItem,
  setTempUserData,
  setUserData,
} from "../../components/utils";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Country, State, City } from "country-state-city";
import { Stack, Grid, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { darken } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { userDetail } from "../../features/userSlice";

export default function SignUpInfoForm({ onTabNext, onTabPrev, formData }) {
  const [Loader, setLoader] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const countries = Country.getAllCountries();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { country: "US" } });

  useEffect(() => {
    let LoggedIn = getUserData();
    if (LoggedIn && LoggedIn != "") {
      removeItem("tempUserData");
      return navigate("/");
    }
    setError("email", { type: "required", message: "" });
    setError("phone_number", { type: "required", message: "" });
    setError("shop_domain", { type: "required", message: "" });
    setError("first_name", { type: "required", message: "" });
    setError("last_name", { type: "required", message: "" });
  }, []);

  useEffect(() => {
    setValue("first_name", formData.first_name);
    setValue("last_name", formData.last_name);
    setValue("shop_name", formData.shop_name);
    setValue("shop_domain", formData.shop_domain);
    setValue("phone_number", formatPhoneNumberInput(formData.phone_number));
    setValue("address1", formData.address1);
    setValue("address2", formData.address2);
    setValue("city", formData.city);
    setValue("country", formData.country);
    setValue("zip_code", formData.zip_code);
    setValue("state", formData.state);
    setValue("email", formData.email);
    setValue("use_comment_sold", formData.use_comment_sold);
    setValue("your_website", formData.your_website);
    setValue("hear_about_boutique_tree", formData.hear_about_boutique_tree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const isMobile = useMediaQuery("(max-width:500px)");

  const onSubmit = (data) => {
    let tempUserData = getTempUserData();
    let customerId =
      tempUserData?.customerInfo?.customer_data?.id ||
      tempUserData?.customerInfo?.shopify_id;

    let payload = {
      ...data,
      have_shopify_account: "yes",
    };

    // delete payload.shop_domain;
    delete payload.country;
    delete payload.city;
    delete payload.state;
    delete payload.zip_code;
    delete payload.address1;
    delete payload.address2;
    delete payload.cpassword;
    delete payload.submit_resale_license;
    delete payload.terms;

    setLoader(true);

    AppSignUp(payload, customerId)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoader(false);
          // user Object
          var userData = res.data;
          // Store in redux
          // dispatch(
          //   userDetail({
          //     userDetail: userData,
          //   })
          // );
          setItem("step", 2);
          setTempUserData(userData);
          onTabNext();
          toast.success(res.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  };

  const onError = (data) => {
    console.log("onError== ", data);
    setLoader(false);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopName = queryParams.get("shop");
  const from = queryParams.get("from");

  return (
    <div>
      <div className="signup_form">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <h3 style={{ fontWeight: 600 }}>Customer Information</h3>
          <div className="row">
            <Box
              // rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
            >
              {/* Email */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    id="email"
                    className="disabled_input_field"
                    disabled={true}
                    {...register("email", {
                      required: "Email is required",
                      minLength: {
                        value: 3,
                        message: "Invalid email",
                      },
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Invalid email",
                      },
                    })}
                  />
                  {errors.email && errors.email.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>
              {/* First name */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="first_name">First name*</label>
                  <input
                    type="text"
                    id="first_name"
                    {...register("first_name", {
                      required: "First Name is required",
                    })}
                  />
                  {errors.first_name && errors.first_name.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.first_name.message}
                    </p>
                  )}
                </div>
              </div>
            </Box>
            <Box
              // rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
            >
              {/* Last name */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="last_name">Last name*</label>
                  <input
                    type="text"
                    id="last_name"
                    {...register("last_name", {
                      required: "Last Name is required",
                    })}
                  />
                  {errors.last_name && errors.last_name.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.last_name.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Shop name */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="shop_name">Shop or Boutique Tree Name*</label>
                  <input
                    type="text"
                    // name="shop"
                    id="shop_name"
                    {...register("shop_name", { required: true })}
                  />
                  {errors.shop_name?.type === "required" && (
                    <p role="alert" className="invalid-feedback">
                      Shop name is required
                    </p>
                  )}
                </div>
              </div>
            </Box>
            <Box
              // rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
            >
              {/* Phone name */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="phone_number">Phone Number*</label>
                  <input
                    type="text"
                    id="phone_number"
                    {...register("phone_number", {
                      required: "Phone number is required",
                      pattern: {
                        value: PhoneNumberInputRegex,
                        message: "Invalid phone number.",
                      },
                    })}
                    // value={`getValues("phone_number")`}
                    onChange={(e) => {
                      setValue(
                        "phone_number",
                        formatPhoneNumberInput(e.target.value)
                      );
                    }}
                  />
                  {errors.phone_number && errors.phone_number.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.phone_number.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Where did you hear about Boutique Tree? */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="hear_about_boutique_tree">
                    Where did you hear about Boutique Tree?
                  </label>
                  <input
                    type="text"
                    id="hear_about_boutique_tree"
                    {...register("hear_about_boutique_tree", {
                      required: false,
                    })}
                  />
                  {errors.hear_about_boutique_tree?.type === "required" && (
                    <p role="alert" className="invalid-feedback">
                      Phone number is required
                    </p>
                  )}
                </div>
              </div>
            </Box>
            <Box
              // rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
            >
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="your_website">Your website*</label>
                  <input
                    type="text"
                    id="your_website"
                    {...register("your_website", { required: true })}
                  />
                  {errors.your_website?.type === "required" && (
                    <p role="alert" className="invalid-feedback">
                      Website is required
                    </p>
                  )}
                </div>
              </div>
              {/* website */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="shop_domain">
                    Shopify URL (myshopify.com).
                  </label>
                  <input
                    type="text"
                    id="shop_domain"
                    className="disabled_input_field"
                    disabled={true}
                    {...register("shop_domain", {
                      required: "Domain is required",
                      validate: (value) =>
                        value.indexOf("myshopify.com") > 0 || "Invalid domain",
                    })}
                  />
                  {errors.shop_domain && errors.shop_domain.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.shop_domain.message}
                    </p>
                  )}
                </div>
              </div>
            </Box>
            {/* shop domain */}
            <Box
              // rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
            >
              {/* create password */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="password">Create Password*</label>
                  <input
                    style={{ marginBottom: "0px !important" }}
                    type="password"
                    id="password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password should be minimum 8 characters", // JS only: <p>error message</p> TS only support string
                      },
                      pattern: {
                        value: /[A-Z]/,
                        message:
                          "Password should contain atleast one capital letter", // JS only: <p>error message</p> TS only support string
                      },
                    })}
                  />
                  {errors.password && errors.password.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>
              {/* confirm password */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="cpassword">Confirm Password*</label>
                  <input
                    style={{ marginBottom: "0px !important" }}
                    type="password"
                    id="cpassword"
                    {...register("cpassword", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password should be minimum 8 characters", // JS only: <p>error message</p> TS only support string
                      },
                      pattern: {
                        value: /[A-Z]/,
                        message:
                          "Password should contain atleast one capital letter", // JS only: <p>error message</p> TS only support string
                      },
                    })}
                  />
                  {errors.cpassword && errors.cpassword.message && (
                    <p role="alert" className="invalid-feedback">
                      {errors.cpassword.message}
                    </p>
                  )}
                </div>
              </div>
            </Box>
            <div className="col-12">
              <Grid
                container
                spacing={2}
                sx={{ marginTop: "20px" }}
                direction={isMobile ? "column" : "row"}
                justifyContent="space-between"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      padding: "8px",
                      width: isMobile ? "100%" : "150px !important",
                      backgroundColor: "#f1f1f1",
                      color: "#919191",
                      boxShadow: "none",
                      // border: '1px solid #919191',
                      "&:hover": {
                        backgroundColor: "white",
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => {
                      onTabPrev();
                      navigate(
                        `/select-plan?shop=${shopName}${
                          from ? `&from=${from}` : ""
                        }`
                      );
                    }}
                    name="join"
                    startIcon={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 600,
                        }}
                      >
                        <ArrowBackIcon sx={{ color: "#5b5b5b" }} />
                      </Box>
                    }
                  >
                    <span style={{ fontWeight: 500 }}>Previous</span>
                  </Button>
                </Grid>
                <Grid item>
                  {Loader && (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    name="join"
                    sx={{
                      padding: "8px",
                      color: "white",
                      backgroundColor: "#B78580",
                      width: isMobile ? "100%" : "150px !important",
                      boxShadow: "none",
                      // border: '1px solid #B78580',
                      "&:hover": {
                        backgroundColor: darken("#B78580", 0.1),
                        boxShadow: "none",
                      },
                    }}
                    onClick={onSubmit}
                    endIcon={
                      <ArrowForwardIcon
                        sx={{ color: "white", fontWeight: 600 }}
                      />
                    }
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </form>

        <FormLogoInfo />
      </div>
    </div>
  );
}

export const FormLogoInfo = () => {
  return (
    <>
      <Stack sx={{ flexDirection: "row", mb: 5, flexWrap: "wrap" }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={3} className="login-bottom-item-first">
            <img
              className="bottom_img"
              src="assets/images/shipping.png"
              alt="logo"
            />
            <div style={{ marginTop: 10 }}>
              Fast <br /> Shipping
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="login-bottom-item-first">
            <img
              className="bottom_img"
              src="assets/images/plan-options.png"
              alt="logo"
            />
            <div style={{ marginTop: 10 }}>
              Multiple <br /> Plan Options
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="login-bottom-item-first">
            <img
              className="bottom_img"
              src="assets/images/referal.png"
              alt="logo"
            />
            <div style={{ marginTop: 10 }}>
              Referal <br /> Program
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="login-bottom-item-first">
            <img
              className="bottom_img"
              src="assets/images/help.png"
              alt="logo"
            />
            <div style={{ marginTop: 10 }}>
              Get <br /> Help
            </div>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
