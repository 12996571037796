import { Navigate, useLocation } from "react-router";
import { getUserData, setItem, clearUserData } from "./components/utils";

const ProtectedRoute = ({ children }) => {
  
  let auth = getUserData(); //gettting local storage data auth

  let location = useLocation();
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }else if(auth && auth !== ""){
    // if(auth.customerInfo.customer_data.is_previous_merchant){
    //     setItem("selectedConnections",[]);
    //     // navigate("/upgrade-plan");
    //     return <Navigate to="/upgrade-plan" state={{ from: location }} />;
    //   }
    if(!auth.customerInfo.customer_data.hasOwnProperty('is_app_installed')){
      clearUserData();
      return <Navigate to="/login" state={{ from: location }} />;
    }

    if(auth.customerInfo.customer_data.is_app_installed === 0){
      setItem("hideSync", "1");
      // navigate("/");
    }
    else if(auth.customerInfo.customer_data.is_previous_merchant === 1 &&
      auth.customerInfo.customer_data.is_app_installed === 1 ){
      setItem("selectedConnections",[]);
      return <Navigate to="/upgrade-plan" state={{ from: location }} />;
    }
    else if(auth.customerInfo.customer_data.is_previous_merchant === 2 &&
      auth.customerInfo.customer_data.is_app_installed === 1 ){
        setItem("hideSync", "1");
        // navigate("/");
    }

   }
  return children;
};

export default ProtectedRoute

