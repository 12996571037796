import React, {useState, useEffect} from "react";
import {GetPlans, GetOldMerchantConnections, SelectPlan, ApproveSubscription, CheckSubscription, subscribeConnection} from "../components/auth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {getItem, setItem, getUserData, setUserData} from "../components/utils";
import Spinner from "../components/spinner/Spinner";
import {toast} from "react-toastify";
import Footer from "../components/FooterNew";

const SuggestPlan = () => {
  const [searchParams] = useSearchParams();
  const [Loader, setLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  const [suggestedPlan, setSuggestedPlan] = useState();
  // const [subscription, setSubscription] = useState(null)

  const userData = getItem("migratedUser");
  const selectedPlans = getItem("selectedConnections");
  var subscriptionId = getItem('subscription_id');

  let navigate = useNavigate();

  useEffect(() => {
    const charge_id = searchParams.get('charge_id')
    if(charge_id && subscriptionId) {
      approveSubscription(charge_id)
    }

  },[searchParams,subscriptionId])


  const onSubscribe = () => {
    // if(!code){
    //   toast.error("please enter code");
    //   return;
    // }
    setLoader(true)
    subscribeConnection({
      connection_uid: selectedPlans.map(x => x.connection_uid)
    }).then((response) => {
      if (response.status === 200) {
        // setShowSubscribe(false)
        toast.success(response.message);
        setItem("selectedConnections",[]);
        setLoader(false);
        navigate("/")
      } else {
        setLoader(false);
        toast.error(response.message);
      }
    }).catch((error) => {
      toast.error(error.message);
      setLoader(false);
    })
  }

  // const getCurrentSubscription = () => {
  //   // setLoader(true);
  //   CheckSubscription()
  //   .then((res) => {
  //     if(res.status === 200 || res.status === 201) {
  //       setLoader(false);
  //       // toast.success(res.message);
  //       setSubscription(res.data);
  //     }else {
  //       setLoader(false);
  //       toast.error(res.message);
  //     }
  //   }).catch((err) => {
  //     setLoader(false);
  //     // toast.error(err.message);
  //   })
  // }

  const approveSubscription = (charge_id) => {
    setLoader(true);
    const payload = {
      charge_id: charge_id,
      subscription_id: subscriptionId
    }
    ApproveSubscription(payload)
    .then((res) => {
      if(res.status === 200) {
        let auth = getUserData();
        setUserData({
          ...auth,
          customerInfo: {
            ...auth.customerInfo,
            customer_data: {
              ...auth.customerInfo.customer_data,
              is_previous_merchant: 0
            }
          }
        });
        setLoader(false);
        // getCurrentSubscription()
        onSubscribe();
        // setItem("selectedConnections",[]);
        // navigate("/upgrade-plan");
        toast.success(res.message);
      }else {
        setLoader(false);
        toast.error(res.message);
      }
    }).catch((err) => {
      setLoader(false);
      toast.error(err.message);
    })
  }

  const getAllPlans = () => {
    setLoader(true);
    GetPlans()
    .then((res) => {
      if(res.status === 200) {
        setLoader(false);
        // toast.success(res.message);
        setPlans(res.data);
      }else {
        setLoader(false);
        toast.error(res.message);
      }
    }).catch((err) => {
      setLoader(false);
      toast.error(err.message);
    })
  }


  useEffect(() => {
    getAllPlans();
    
  },[])

  useEffect(() => {
    
    if(plans && userData){
      const reqLimit = userData.subscribed_connection_count + (selectedPlans ? selectedPlans.length : 0);
      const nextPlan = plans.filter(p => p.is_free === 0).filter(x => x.connection_limit >= reqLimit).sort((a, b) => (a.connection_limit) - (b.connection_limit))[0];
      // console.log("nextPlan ==", nextPlan);
      setSuggestedPlan(nextPlan)
    }

  },[plans, userData, selectedPlans])

  const onConfirm = () => {
    setLoader(true);
    const payload = {
      plan_id: suggestedPlan.id,
      callback_url:window.location.href //.replace('suggest-plan','upgrade-plan'),
    }
    SelectPlan(payload)
    .then((res) => {
      if(res.status === 200) {
        setLoader(false);
        setItem('subscription_id', res.data.subscription_id)
        window.open(res.data.confirm_url,"_self")
        toast.success(res.message);
      }else {
        setLoader(false);
        toast.error(res.message);
      }
    }).catch((err) => {
      setLoader(false);
      toast.error(err.message);
    })
  }

  return (
    <section className="bt_batch_overview py-0">
    <div className="container-fluid px-0">
      <div className="row g-0">
        <div className="col-12 d-flex">
        <div className="auth_bg">
          <div className="container-fluid bg-white box-shadow">
            
            <div className="row g-0 x">
              
                {/* Sign up or Create profile */}
                <div className=" text-center">
                 
                  <div className="signup_title gap-5 mt-5 d-flex justify-content-center align-items-center">
                    <img className="top_img_text mt-0" src="assets/images/well-done.png"  alt="logo"  />
                    <div>
                      {/* <h1 >Choose the Plan that Works for your Boutique</h1> */}
                      <div className="tex-start upcoming-heading">
                        <b>Congratulations, we are excited<br/>
                          to show you our new features!
                         </b>
                      </div>
                    </div>
                  </div>
                  <div className="tex-center">
                    Your upgraded plan is shown below
                  </div>
                  {
                    suggestedPlan && (
                      <div className="package-container row align-items-center mt-5">
                        <div className="suggested-plan-container">
                          <div className="package-item">
                            <div className="imgDiv">
                              <img src="assets/images/light.png"  alt="logo"  />
                            </div>
                            <h1>{suggestedPlan.name}</h1>
                            <h4>{`${suggestedPlan.is_free === 0 ? 'Free + ': ''}${suggestedPlan.connection_limit} ${suggestedPlan.is_free === 0 ? 'Connections': 'Sample Connection'}`}</h4>
                            <h2>{`$${suggestedPlan.price}`}</h2>
                            <h5>usd per month billed.</h5>
                          </div>
                          {/* <div className="getstarted-brown">{userData ? userData.plan.name : 'Test'}</div> */}
                        </div>
    
                      </div>
                    )
                  }
                

                  {/* <div className="d-flex justify-content-center py-5 px-auto"> */}
                        <div
                          className="px-5 py-2 upcoming-confirm m-5"
                          onClick={(e) => {onConfirm()}}
                          // disabled={Loader}
                        >CONFIRM</div>
                  {/* </div> */}
                </div>

                {/* End create profile  */}
            </div>
          </div>
        </div>
        
        </div>
        </div>
        {Loader && (
          <div className="spinner_div">
            <Spinner/>
          </div>
        )}
      </div>
      <Footer/>
    </section>
  );
};

export default SuggestPlan;
