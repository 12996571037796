import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  let navigate = useNavigate();

  const handleBackButton = (e) => {
    e.preventDefault();
    return navigate("/");
  };

  return (
    <div className="button_session">
      <a href="#" onClick={handleBackButton}>
        Dashboard
      </a>
    </div>
  );
};

export default BackButton;
