import React, {useEffect, useRef, useState} from "react";
import {getUserData} from "../utils";
import {
  updateSettings
} from "../auth";
import {toast} from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";

const Settings = (props) => {

  const [Loader, setLoader] = useState(false);
  const [settings, setSettings] = useState((props.settings && props.settings.container) ? props.settings.container : {
    description:false,
    images: {
        enabled:false,
        add_images: false
    },
    price:false,
    charge_taxes:false,
    sku:false,
    tags: {
        enabled:false,
        add_tags:false,
        // barcode:false
    },
    barcode:false,
    requires_shipping:false
  })

  const onSettingsChange = () => {
    setLoader(true);
    updateSettings({
      subscription_id: props.subscriptionId,
      settings:{
        container: settings
      }
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(response.message);
      }
    }).catch((error) => {
      toast.error(error.message);
      setLoader(false);
    })
  }

  useEffect(() => {
    setSettings((props.settings && props.settings.container) ? props.settings.container : {
      description:false,
      images: {
          enabled:false,
          add_images: false
      },
      price:false,
      charge_taxes:false,
      sku:false,
      tags: {
          enabled:false,
          add_tags:false,
          // barcode:false
      },
      barcode:false
    })
  },[props])

  return (
    <div className="bottom_layout white">
      <div className="preference">
      <div className="row">
        <div className='col'>
        <h2>{props.name}</h2>
        {
          props.isDuplicate && (
            <div className='duplicate'>
              It seems this connection has some duplicate products from other connections. Any update you will be making in settings/preferences here would be updated for those other connections as well.
            </div>
          )
        }
        </div>
          <div className="connection-btn">
            <input
                type="button"
                defaultValue="Save Settings"
                onClick={(event) => {
                  // event.preventDefault();
                  onSettingsChange()
                }}
              />
            </div>
          </div>
        <div>
          <h3>Sync Settings</h3>
          <p>All product data will sync over initially.  If you would like to edit a data component, then disable the sync setting of that component below before making changes so that your changes are not overwritten with the original Boutique Tree data.</p>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.description === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    description: e.target.checked
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}} >Product Description</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo" />
              <span className="tooltip-text" style={{width:300}}>
              This is the product description including size chart and fabric content.  Toggle this sync off if you plan to adjust the product descriptions.  Please note that if this is turned off then future updates from Boutique Tree will not sync.
              </span>
            </span>
              
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.images.enabled === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    images: {
                      ...settings.images,
                      enabled: e.target.checked ,
                      add_images : !e.target.checked ? false : settings.images.add_images
                    }
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Product Images</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              If you plan to add or manipulate the order of product images be sure to toggle off the product images sync so that your changes are not overwritten.
              </span>
            </span>
            
          </div>
          <div className="switch-container" style={{marginLeft:30}}>
            <label className="switch col-md-1">
            <input disabled={!settings.images.enabled} checked={settings.images.add_images === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    images: {
                      ...settings.images,
                      add_images: e.target.checked 
                    }
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Add NEW Images</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              This setting will allow NEW Boutique Tree images to be added to the end of the current images of the product.  This will not impact any changes you have made. Toggle this off if you do not want new images to be added.
              </span>
            </span>
            
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.price === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    price:e.target.checked 
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Product Price</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              This is the retail price you will be charging your customers.  The initial data will be set based on your “Pricing Transformation” under preferences.  If you plan to change prices manually, then toggle off this setting.  Warning: This setting is global across all products.  It is recommended that prices are set based on the Price Transformation to automate repricing for sales and permanent changes from Boutique Tree.
              </span>
            </span>
           
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.charge_taxes === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    charge_taxes:e.target.checked 
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Charge Taxes</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              Products are synced over with Charge Taxes set to ‘Yes’.  If you need to change these settings, then toggle Charge Taxes off.  Then change the settings in your product detail
              </span>
            </span>
           
          </div>
          {/* <div className="switch-container">
            <label class="switch col-md-1">
            <input checked={settings.sku === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    sku:e.target.checked 
                  })
              }}/>
              <span class="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>SKU</span>
            <img src="../assets/images/info-icon.png"  alt="logo"  />
          </div> */}
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.tags.enabled === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    tags:{
                      ...settings.tags,
                      enabled:e.target.checked,
                      add_tags: e.target.checked === false ? false : settings.tags.add_tags,
                      // barcode: e.target.checked === false ? false : settings.tags.barcode
                    }
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Tags</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              If you plan to add or change product tags then toggle this setting off so that your changes are not overwritten.
              </span>
            </span>
           
          </div>
          <div className="switch-container" style={{marginLeft:30}}>
            <label className="switch col-md-1">
            <input disabled={!settings.tags.enabled} checked={settings.tags.add_tags === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    tags:{
                      ...settings.tags,
                      add_tags:e.target.checked 
                    }
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Add NEW Tags</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              This setting will add new tags to your products without overwriting the current product tags. It is recommended to keep this setting toggled ON as it allows Boutique Tree to sync over new tags without impacting changes you may have made.  These new tags will make identifying products in upcoming sales much easier.
              </span>
            </span>
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.barcode === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    barcode:e.target.checked 
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Barcodes</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              Barcode data is included in the initial data sync for easy live selling and organization.  If you use a different barcoding system, then turn off this toggle before making changes.
              </span>
            </span>
           
          </div>

          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={settings.requires_shipping === true} type="checkbox" onChange={(e) => {
                  setSettings({
                    ...settings,
                    requires_shipping: e.target.checked
                  })
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}} >Requires Shipping</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo" />
              <span className="tooltip-text" style={{width:300}}>
              If you offer local pickup then unsync Requires Shipping and change your product data in bulk editor.
              </span>
            </span>
              
          </div>
        </div>
     
       
      </div>
      {Loader && (
          <div className="spinner_div">
            <Spinner/>
          </div>
        )}
    </div>
  );
};
export default Settings;
