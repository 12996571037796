import React, {useEffect, useRef, useState} from "react";
import {getUserData} from "../utils";

import {
  getCustomerAccountDetail,
  updateCustomerAccountDetail,
} from "../auth";
import {toast} from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";

const AccountReturnLabel = () => {
  var userData = getUserData();

  var customerEmail =
    !!userData && userData ? userData?.customerInfo?.customer_data?.email : "";

  var customerId =
    !!userData && userData ? userData?.customerInfo?.customer_data?.id : "";

  const [form, setForm] = useState({
    id:"",
    returnLabelName:"",
    addressId:"",
    address1: "",
    address2: "",
    city: "",
    country: "united states",
    province: "",
    zip: "",
  });


  const [country, setCountry] = useState("united states");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [isSameAsBusinessAddress, setIsSameAsBusinessAddress] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});

  const inputRef = useRef();
  console.log();

  useEffect(() => {
    let autocompleted = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {componentRestrictions: {country: "us"}},
      {types: ["establishment"]}
    );
    // autocomplete.setFields("address_components");

    let autoListners = autocompleted.addListener("place_changed", () => {
      console.log("hanle change hanlder", autocompleted.getPlace());
      let data = autocompleted.getPlace();
      data.address_components.map((item) => {
        
        if (
          item.types.includes("country") &&
          item.types.includes("political")
        ) {
          setForm((prev) => {
            return {...prev, country: "united states", address1: data?.name};
          });
          // setZipcodes([item.long_name])
        }
        if (
          item.types.includes("political") &&
          item.types.includes("administrative_area_level_1")
        ) {
            setForm((prev) => {
              return {...prev, province: item.long_name};
            });
            setStates([{state: item.long_name}])
        }
        if (
          item.types.includes("political") &&
          (item.types.includes("locality") || item.types.includes("sublocality") || item.types.includes("sublocality_level_1"))
	){
            setForm((prev) => {
              return {...prev, city: item.long_name};
            });
            setCities([{name: item.long_name}])
        }
        if (
          item.types[0] == "postal_code" ||
          item.types.includes("colloquial_area")
        ) {
            setForm((prev) => {
              return {...prev, zip: item.long_name, address1: data?.name};
            });
            setZipcodes([item.long_name])
        }
      });
    });
  }, []);

  const getAccountDetail = (data) => {
    getCustomerAccountDetail(data).then((response) => {
      if (response.data) {
        const states = response.data.states ? response.data.states : [];
        setStates(states);
        const cities = response.data.cities ? response.data.cities?.cities : [];
        setCities(cities);
        setCurrentUserData(response?.data);
        setData(response?.data);
        const selectedCity = response?.data?.customer?.returnAddress?.city;

        const findSelectedCity = cities
          ? cities.find((city) => city.name === selectedCity)
          : "";
        if (findSelectedCity) {
          setZipcodes(findSelectedCity.zipcodes);
        }
      }
    });
  };

  // userInfo handle Change
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    if(e.target.name === "address1"){
      if(e.target.value === ""){
        setForm({...form, address1: "",
        address2: "",
        city: "",
        country: "united states",
        province: "",
        zip: "",})
      }
    }
  };

  const setData = (userData) => {
    if (
      userData?.customer?.returnAddress?.address1 == userData?.customer?.customerAddress?.address1 &&
      userData?.customer?.returnAddress?.city == userData?.customer?.customerAddress?.city &&
      userData?.customer?.returnAddress?.province == userData?.customer?.customerAddress?.province &&
      userData?.customer?.returnAddress?.country == userData?.customer?.customerAddress?.country &&
      userData?.customer?.returnAddress?.zip == userData?.customer?.customerAddress?.zip
    ) {
      setIsSameAsBusinessAddress(true);
    }
    if (userData) {
      setForm({
        id: userData?.customer?.customerId || "",
        returnLabelName: userData?.customer?.returnAddress?.returnLabelName || "",
        addressId: userData?.customer?.returnAddress?.id || "",
        address1: userData?.customer?.returnAddress?.address1 || "",
        address2: userData?.customer?.returnAddress?.address2 || "",
        city: userData?.customer?.returnAddress?.city || "",
        country:
          userData?.customer?.returnAddress?.country || "united states",
        province: userData?.customer?.returnAddress?.province || "",
        zip: userData?.customer?.returnAddress?.zip || "",
      });
    }
  };

  let preparePayload = {
    accessToken: userData.customerAccessToken.accessToken,
    email: customerEmail,
    customerId: userData?.customerInfo?.customer_data?.id,
    country: "united states",
  };


  const updateReturnLabelDetailInfo = (e) => {
    e.preventDefault();
    const defaultAddress = {
      id: form.addressId,
      returnLabelName:form.returnLabelName,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      country: form.country,
      province: form.province,
      zip: form.zip,
    };
    if (defaultAddress.address1 === "" || defaultAddress.address1 === null) {
      toast.error("please fill  address")
      return
    }
    setAddressLoader(true);
    updateCustomerAccountDetail({
      customer_info: {
        ...preparePayload,
        returnAddress: {
          ...defaultAddress,
        }
      },
      customerId: form.id,
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        if (response.data) {
          setCurrentUserData(response?.data);
          setData(response?.data);
          const selectedCity = response?.data?.customer?.returnAddress?.city;
          const findSelectedCity = cities
            ? cities.find((city) => city.name === selectedCity)
            : "";
          if (findSelectedCity) {
            setZipcodes(findSelectedCity.zipcodes);
          }
        }
        // window.location.reload();
        setAddressLoader(false);
      } else {
        setAddressLoader(false);
        toast.error(response.message);
      }
    });
  };

  useEffect(() => {
    getAccountDetail(preparePayload);
  }, []);

  return (
    <>
      {/* Return Label Information */}
      <div className="order_colm billing_sec">
        <div className="top_section">
          <h1 style={{color: "#596f5a", fontWeight: "700"}}>Return Label Information</h1>
        </div>
        <div className="bottom_layout white">
        <p style={{color: "#596f5a", fontWeight: 500, fontSize: "18px"}}>We customize the return label on all packages to your name and address :-)</p>
        <p style={{color:"#596f5a"}}>Please provide the address for your custom return label below:</p>
          <div className="account_detail account_form">
            <form action="#" method="post" id="account_form">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Return Label Name*</label>
                    <input
                      type="text"
                      name="returnLabelName"
                      value={form.returnLabelName ? form.returnLabelName : ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="autopay_ct" style={{ color: "#596f5a" }}>
                      <input
                        value={''}
                        type="checkbox"
                        name="autopay_lb"
                        checked={isSameAsBusinessAddress}
                        className="autopay_checkbox"
                        onChange={() => {
                          if(isSameAsBusinessAddress) {
                            setIsSameAsBusinessAddress(false);
                          }else{
                            setIsSameAsBusinessAddress(true);
                            setForm({
                              ...form,
                              addressId: currentUserData?.customer?.customerAddress?.id || "",
                              address1: currentUserData?.customer?.customerAddress?.address1 || "",
                              address2: currentUserData?.customer?.customerAddress?.address2 || "",
                              city: currentUserData?.customer?.customerAddress?.city || "",
                              country:
                                currentUserData?.customer?.customerAddress?.country || "united states",
                              province: currentUserData?.customer?.customerAddress?.province || "",
                              zip: currentUserData?.customer?.customerAddress?.zip || "",
                            });
                          }
                        }}
                      />{" "}
                      Same as Business Address
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Address*</label>
                    <input
                      ref={inputRef}
                      type="text"
                      name="address1"
                      value={form.address1 ? form.address1 : ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Country*</label>
                    <input
                      type="text"
                      id="country"
                      value={form.country ? form.country : ''}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>State*</label>
                    <input
                      type="text"
                      id="province"
                      value={form.province ? form.province : ''}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>City*</label>
                    <input
                      type="text"
                      id="city"
                      value={form.city ? form.city : ''}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Zip code*</label>
                    <input
                      type="text"
                      id="zip"
                      value={form.zip ? form.zip : ''}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  {addressLoader && (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  )}
                  <div className="form-group">
                    <input
                      type="button"
                      value="Update"
                      onClick={(event) => updateReturnLabelDetailInfo(event)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountReturnLabel;
