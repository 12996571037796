import React, { useEffect, useRef, useState } from "react";
import NavLinks from "../Nav";
import {
  PhoneNumberInputRegex,
  formatPhoneNumberInput,
  getUserData,
} from "../utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardForm from "../payment/stripe/AddCardForm";
import Select from "react-select";

import {
  UploadLicence,
  getCustomerAccountDetail,
  getCustomerAddressDetail,
  updateCustomerAccountDetail,
} from "../auth";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";

const Account = () => {
  var userData = getUserData();

  var customerEmail =
    !!userData && userData ? userData?.customerInfo?.customer_data?.email : "";

  var customerId =
    !!userData && userData ? userData?.customerInfo?.customer_data?.id : "";

  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    shopName: "",
    phone: "",
    hearAboutBoutiqueTree: "",
    useCommentSold: "",
    website: "",
    shopDomain: "",
    company: "",
    addressId: "",
    taxId: "",
    address1: "",
    address2: "",
    city: "",
    country: "united states",
    province: "",
    zip: "",
    resale_licence_file: "" || {},
    session_id: "",
  });

  const [country, setCountry] = useState("united states");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});

  const inputRef = useRef();
  console.log();

  useEffect(() => {
    let autocompleted = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { componentRestrictions: { country: "us" } },
      { types: ["establishment"] }
    );
    // autocomplete.setFields("address_components");

    let autoListners = autocompleted.addListener("place_changed", () => {
      console.log("hanle change hanlder", autocompleted.getPlace());
      let data = autocompleted.getPlace();
      data.address_components.map((item) => {
        if (
          item.types.includes("country") &&
          item.types.includes("political")
        ) {
          setForm((prev) => {
            return { ...prev, country: "united states", address1: data?.name };
          });
          // setZipcodes([item.long_name])
        }
        if (
          item.types.includes("political") &&
          item.types.includes("administrative_area_level_1")
        ) {
          setForm((prev) => {
            return { ...prev, province: item.long_name };
          });
          setStates([{ state: item.long_name }]);
        }
        if (
          item.types.includes("political") &&
          (item.types.includes("locality") ||
            item.types.includes("sublocality") ||
            item.types.includes("sublocality_level_1"))
        ) {
          setForm((prev) => {
            return { ...prev, city: item.long_name };
          });
          setCities([{ name: item.long_name }]);
        }
        if (
          item.types[0] == "postal_code" ||
          item.types.includes("colloquial_area")
        ) {
          setForm((prev) => {
            return { ...prev, zip: item.long_name, address1: data?.name };
          });
          setZipcodes([item.long_name]);
        }
        //else {
        //    setForm((prev) => {
        //      return {...prev, zip: "", address1: data?.name};
        //    });
        //    setZipcodes([""])
        // }
      });
    });
  }, []);

  const getAccountDetail = (data) => {
    getCustomerAccountDetail(data).then((response) => {
      if (response.data) {
        const states = response.data.states ? response.data.states : [];
        setStates(states);
        const cities = response.data.cities ? response.data.cities?.cities : [];
        setCities(cities);
        setCurrentUserData(response?.data);
        setData(response?.data);
        const selectedCity = response?.data?.customer?.customerAddress?.city;

        const findSelectedCity = cities
          ? cities.find((city) => city.name === selectedCity)
          : "";
        if (findSelectedCity) {
          setZipcodes(findSelectedCity.zipcodes);
        }
      }
    });
  };

  const [phoneError, setPhoneError] = useState({ status: false, message: "" });

  // userInfo handle Change
  const handleChange = (e) => {
    if (e.target.name === "phone") {
      const getFormateNumber = formatPhoneNumberInput(e.target.value);
      const checkNumber = PhoneNumberInputRegex.test(getFormateNumber);
      setForm({
        ...form,
        [e.target.name]: getFormateNumber,
      });
      if (phoneError.status) {
        if (checkNumber) {
          setPhoneError({ status: false, message: "" });
          return;
        } else {
          setPhoneError({ status: true, message: "Invalid phone number." });
          return;
        }
      } else {
        return;
      }
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "address1") {
      if (e.target.value === "") {
        setForm({
          ...form,
          address1: "",
          address2: "",
          city: "",
          country: "united states",
          province: "",
          zip: "",
        });
      }
    }
  };

  const setData = async (userData) => {
    //TODO Chcek for the resetting and user and address
    if (userData) {
      setForm({
        id: userData?.customer?.customerId || "",
        firstName: userData?.customer?.firstName || "",
        lastName: userData?.customer?.lastName || "",
        email: userData?.customer?.email || "",
        shopName: userData?.customer?.shopName || "",
        phone: formatPhoneNumberInput(userData?.customer?.phone || ""),
        hearAboutBoutiqueTree: userData?.customer?.hearAboutBoutiqueTree || "",
        useCommentSold: userData?.customer?.useCommentSold || "No",
        website: userData?.customer?.website || "",
        shopDomain: userData?.customer?.shopDomain || "",
        addressId: userData?.customer?.customerAddress?.id || "",
        company: userData?.customer?.customerAddress?.company || "",
        taxId: userData?.customer?.taxId || "",
        address1: userData?.customer?.customerAddress?.address1 || "",
        address2: userData?.customer?.customerAddress?.address2 || "",
        city: userData?.customer?.customerAddress?.city || "",
        country:
          userData?.customer?.customerAddress?.country || "united states",
        province: userData?.customer?.customerAddress?.province || "",
        zip: userData?.customer?.customerAddress?.zip || "",
        resale_licence_file:
          {
            name:
              userData?.customer?.resale_licence_file?.split("/")?.pop() || "",
          } || {},
        session_id: userData?.customer?.session_id || "",
      });
    }

    // if(userData?.customer?.resale_licence_file != "" && typeof userData?.customer?.resale_licence_file== "string"){
    //   await fetchFileFromUrl(userData?.customer?.resale_licence_file)
    // }
  };

  let preparePayload = {
    accessToken: userData.customerAccessToken.accessToken,
    email: customerEmail,
    customerId: userData?.customerInfo?.customer_data?.id,
    country: "united states",
  };

  const handleCountryChange = (e) => {
    console.log("e---", e.target.value);
    if (e.target.value !== "") {
      setForm({
        ...form,
        country: e.target.value,
      });
      preparePayload = {
        ...preparePayload,
        country: e.target.value,
      };
      getAddressDetail(preparePayload);
    }
  };

  const handleProvinceChange = (e) => {
    console.log("province change----", e.target.value);
    console.log("province change----", form);
    if (e.target.value !== "") {
      setForm({
        ...form,
        province: e.target.value,
      });
      console.log("check province----", e.target.value);
      preparePayload = {
        ...preparePayload,
        country: form.country,
        province: e.target.value,
      };
      getAddressDetail(preparePayload);
    }
  };

  const handleCityChange = (e) => {
    console.log("handleCityChange change----", form);

    if (e.target.value !== "") {
      setForm({
        ...form,
        city: e.target.value,
      });
      const findSelectedCity = cities
        ? cities.find((city) => city.name === e.target.value)
        : "";
      console.log("findSelectedCity ----", findSelectedCity);
      if (findSelectedCity) {
        setZipcodes(findSelectedCity.zipcodes);
      }
    }
  };

  const handleZipCodeChange = (e) => {
    if (e.target.value !== "") {
      setForm({
        ...form,
        zip: e.target.value,
      });
    }
  };

  const updateAccountDetailInfo = (e) => {
    e.preventDefault();
    if (form.phone == "" || form.phone != "") {
      const checkNumber = PhoneNumberInputRegex.test(form.phone);
      if (!checkNumber) {
        setPhoneError({ status: true, message: "Invalid phone number" });
        return;
      }
    }

    const userDetail = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      shopName: form.shopName,
      phone: form.phone,
      hearAboutBoutiqueTree: form.hearAboutBoutiqueTree,
      useCommentSold: form.useCommentSold,
      website: form.website,
      shopDomain: form.shopDomain,
    };
    updateCustomerAccountDetail({
      customer_info: {
        ...preparePayload,
        userDetail,
      },
      customerId: form.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          if (response.data) {
            setCurrentUserData(response?.data);
            setData(response?.data);
            const selectedCity =
              response?.data?.customer?.customerAddress?.city;
            const findSelectedCity = cities
              ? cities.find((city) => city.name === selectedCity)
              : "";
            if (findSelectedCity) {
              setZipcodes(findSelectedCity.zipcodes);
            }
          }
          // window.location.reload();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const updateBusinessDetailInfo = (e) => {
    e.preventDefault();
    const defaultAddress = {
      id: form.addressId,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      company: form.company,
      country: form.country,
      province: form.province,
      zip: form.zip,
    };

    const userDetail = {
      taxId: form.taxId,
    };

    if (defaultAddress.address1 === "" || defaultAddress.address1 === null) {
      toast.error("please fill  address");
      return;
    }
    setAddressLoader(true);
    updateCustomerAccountDetail({
      customer_info: {
        ...preparePayload,
        userDetail,
        address: {
          ...defaultAddress,
        },
      },
      customerId: form.id,
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        if (form.resale_licence_file && form.resale_licence_file.length > 0) {
          let file = form.resale_licence_file[0];
          let formData = new FormData();
          formData.append("resale_licence_file", file);
          UploadLicence(formData, currentUserData?.customer?.merchant_id).then(
            (res1) => {
              if (res1.status == 200) {
                if (response.data) {
                  setCurrentUserData(response?.data);
                  setData(response?.data);
                  const selectedCity =
                    response?.data?.customer?.customerAddress?.city;
                  const findSelectedCity = cities
                    ? cities.find((city) => city.name === selectedCity)
                    : "";
                  if (findSelectedCity) {
                    setZipcodes(findSelectedCity.zipcodes);
                  }
                }
                // window.location.reload();
                setAddressLoader(false);
                toast.success(res1.message);
              } else {
                setAddressLoader(false);
                toast.error(res1.message);
              }
            }
          );
        } else {
          if (response.data) {
            setCurrentUserData(response?.data);
            setData(response?.data);
            const selectedCity =
              response?.data?.customer?.customerAddress?.city;
            const findSelectedCity = cities
              ? cities.find((city) => city.name === selectedCity)
              : "";
            if (findSelectedCity) {
              setZipcodes(findSelectedCity.zipcodes);
            }
          }
          // window.location.reload();
          setAddressLoader(false);
        }
      } else {
        setAddressLoader(false);
        toast.error(response.message);
      }
    });
  };

  useEffect(() => {
    getAccountDetail(preparePayload);
  }, []);

  const getAddressDetail = (data) => {
    if (data && data !== "" && data !== undefined) {
      getCustomerAddressDetail(data).then((response) => {
        if (response.data) {
          const states = response?.data?.states ? response?.data?.states : [];
          setStates(states);
          const cities = response?.data?.cities
            ? response?.data?.cities?.cities
            : [];
          setCities(cities);

          const selectedCity = response?.data?.customer?.customerAddress?.city;

          const findSelectedCity = cities
            ? cities.find((city) => city.name === selectedCity)
            : "";
          if (findSelectedCity) {
            setZipcodes(findSelectedCity.zipcodes);
          }
        }
      });
    }
  };

  // const fetchFileFromUrl = async (url) => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();

  //     const urlObj = new URL(url);
  //     const fileName = urlObj.pathname.split('/').pop();

  //     const file = new File([blob], fileName, { type: blob.type });
  //     setForm((prevForm) => ({
  //       ...prevForm,
  //       resale_licence_file: file
  //     }));
  //   } catch (error) {
  //     console.error("Error fetching the file from the URL", error);
  //   }
  // };

  return (
    <>
      {/* Account Details  */}
      <div className="bottom_layout white">
        <div className="account_detail account_form">
          <form action="#" method="post" id="account_form">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>First Name*</label>
                  <input
                    type="text"
                    name="firstName"
                    value={form.firstName ? form.firstName : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Last Name*</label>
                  <input
                    type="text"
                    name="lastName"
                    value={form.lastName ? form.lastName : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={form.email ? form.email : ""}
                    disabled={true}
                    className="disabled"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Shop Name*</label>
                  <input
                    type="text"
                    name="shopName"
                    value={form.shopName ? form.shopName : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Phone Number*</label>
                  <input
                    type="text"
                    name="phone"
                    value={form.phone ? form.phone : ""}
                    onChange={handleChange}
                    // placeholder="123 456 78901"
                  />
                  {phoneError.status && phoneError.message && (
                    <p role="alert" className="invalid-feedback d-block">
                      {phoneError.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Where did you hear about Boutique Tree?</label>
                  <input
                    type="text"
                    name="hearAboutBoutiqueTree"
                    value={
                      form.hearAboutBoutiqueTree
                        ? form.hearAboutBoutiqueTree
                        : ""
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Your Website*</label>
                  <input
                    type="text"
                    name="website"
                    value={form.website ? form.website : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group">
                  <label>
                    myshopify.com domain? Ex: boutique-tree.myshopify.com. This
                    is used to identify your data sync request.*
                  </label>
                  <input
                    type="text"
                    name="shopDomain"
                    value={form.shopDomain ? form.shopDomain : ""}
                    disabled={true}
                    className="disabled"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    type="button"
                    value="Update"
                    onClick={(event) => updateAccountDetailInfo(event)}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Business Information */}
      <div className="order_colm billing_sec">
        <div className="top_section">
          <h1 style={{ color: "#596f5a", fontWeight: "700" }}>
            Business Information
          </h1>
        </div>
        <div className="bottom_layout white">
          <div className="account_detail account_form">
            <form action="#" method="post" id="account_form">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label
                      htmlFor="submit_resale_license"
                      className="custom-file-upload"
                    >
                      Submit Resale License*
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ display: "flex" }}>
                        <input
                          id="submit_resale_license"
                          type="file"
                          autoComplete="do-not-autofill"
                          name="file"
                          accept="image/*,.pdf"
                          placeholder="Submit Resale License"
                          aria-required="true"
                          onChange={(e) => {
                            const files = e.target.files || [];
                            if (files.length > 0) {
                              if (files?.[0]?.size > 2 * 1000 * 1024) {
                                toast.error(
                                  "File with maximum size of 2MB is allowed"
                                );
                              } else {
                                setForm({
                                  ...form,
                                  resale_licence_file: files,
                                });
                              }
                            }
                          }}
                          style={{ color: "white", cursor: "pointer" }}
                        />
                        <label
                          htmlFor="submit_resale_license"
                          id="#submit_resale_license"
                          style={{ marginLeft: "-50px" }}
                        >
                          {form?.resale_licence_file?.name ||
                            form?.resale_licence_file?.[0]?.name ||
                            "No file chosen"}
                        </label>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Tax ID / ABN / VAT Number*</label>
                    <input
                      type="text"
                      name="taxId"
                      value={form.taxId ? form.taxId : ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Address*</label>
                    <input
                      ref={inputRef}
                      type="text"
                      name="address1"
                      value={form.address1 ? form.address1 : ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Country*</label>
                    <input
                      type="text"
                      id="country"
                      value={form.country ? form.country : ""}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>State*</label>
                    <input
                      type="text"
                      id="province"
                      value={form.province ? form.province : ""}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>City*</label>
                    <input
                      type="text"
                      id="city"
                      value={form.city ? form.city : ""}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Zip code*</label>
                    <input
                      type="text"
                      id="zip"
                      value={form.zip ? form.zip : ""}
                      disabled
                      className="disabled"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  {addressLoader && (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  )}
                  <div className="form-group">
                    <input
                      type="button"
                      value="Update"
                      onClick={(event) => updateBusinessDetailInfo(event)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Account;
