import React, { useState, useEffect } from "react";

import Account from "../components/Account-Detail/Account";
import Billing from "../components/Account-Detail/Billing";
import SyncSettings from "../components/Account-Detail/SyncSettings";
import NavLinks from "../components/Nav";
import Logout from "../components/Logout";
import { useSearchParams } from "react-router-dom";
import { getItem, getUserData, removeItem, setItem } from "../components/utils";
import { GetAllMerchantShopLocations } from "../components/auth";
import { toast } from "react-toastify";

const AccountDetail = () => {
  const [searchParams] = useSearchParams();
  const [chargeId, setChargeId] = useState("");
  const [merchantLocations, setMerchantLocations] = useState([]);
  const [hideSync, setHideSync] = useState(false);

  useEffect(() => {
    console.log("get user =", getUserData());
    let hide = getItem("hideSync");
    setHideSync(hide && hide === "1" ? true : false);
  }, []);

  useEffect(() => {
    const charge_id = searchParams.get("charge_id");
    if (charge_id) {
      setChargeId(charge_id);
    }
  }, [searchParams]);

  useEffect(() => {
    GetAllMerchantShopLocations()
      .then((res) => {
        if (res.status == 200) {
          console.log(res?.data);
          setMerchantLocations(res?.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className="bt_batch_overview py-0">
      <div className="container-fluid px-0">
        <div className="row g-0">
          <div className="col-12 d-flex">
            <NavLinks />
            <div className="right_sidebar">
              <div className="top_section">
                <h1 class="account_heading">Account Details</h1>
                <Logout />
              </div>
              {/* Account Detail component */}
              <Account />
              {/* End Account Detail component */}

              {/* Billing Detail component */}
              <Billing hideSync={hideSync} />
              {/* End Billing Detail component */}
              {!hideSync && (
                <SyncSettings
                  //Pass the locations
                  merchantLocations={merchantLocations}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountDetail;
