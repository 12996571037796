import React from "react";
import { useNavigate } from "react-router-dom";
import { clearUserData, removeItem } from "./utils";

const Logout = () => {
  let navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    clearUserData();
    removeItem("step")
    return navigate("/login");
  };

  return (
    <div className="button_session">
      <a href="#" onClick={handleLogout}>
        logout
      </a>
    </div>
  );
};

export default Logout;
