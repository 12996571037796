import React from 'react';
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
  Typography,
  Stack
} from '@mui/material';
import { High_Quantity } from './content';

export default function HighQuantity({ open, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      sx={{fontFamily: '"Poppins-Regular", "Poppins", sans-serif',}}
    >
      <DialogTitle>
      <Stack sx={{flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
        <Typography sx={{
           fontFamily: '"Poppins-Regular", "Poppins", sans-serif',
          fontSize:"18px",
          color:"#5c715f", 
          fontWeight:900
        }}>{High_Quantity.Heading}</Typography>
        <Button onClick={onClose} color="primary">
        <svg xmlns="http://www.w3.org/2000/svg" width="0.93em" height="1em" viewBox="0 0 1216 1312"><path fill="#6b7d62" d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"/></svg>
        </Button>
        </Stack>
        <Stack spacing={1.5}>
        <Typography sx={{
           fontSize:"18px",
           color:"#5c715f", 
        }}>
                {High_Quantity.title}
            </Typography>
            <Typography sx={{
                color:"#6b7d62", fontSize:"11px", mb:2
            }}>
                {High_Quantity.details}
            </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent 
      sx={{
        overflowY: 'auto',
        '& .MuiPaper-root': {
          borderRadius: "2px",
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
            width:0,
            background:"transparent"
          },
          msOverflowStyle: 'none',  
          scrollbarWidth: 'none',  
        },
      }}>
        <TableContainer component={Paper}    style={{ overflowX: 'hidden', overflowY: 'hidden'}}>
          <Table aria-label="comparison table" sx={{boxShadow:"none"}}>
            <TableHead>
              {/* <TableRow sx={{border:"none"}}> */}
                <TableCell></TableCell>
                <TableCell align="center" sx={{
                  background: "linear-gradient(-180deg, #6b7d62 0%, rgba(127, 127, 127, 0.1) 100%)",
                  fontSize:"18px",
                  fontWeight:900,
                  color:"white"
                }}>
                Freemium
                </TableCell>
                <TableCell align="center"
                sx={{
                  background: "linear-gradient(-180deg, #f2a602 0%, rgba(240, 203, 50, 0.18) 100%)",
                  fontSize:"18px",
                  fontWeight:900,
                  color:"white"
                }}
                >Platinum</TableCell>
              {/* </TableRow> */}
            </TableHead>
            <TableBody>
              
              <TableRow sx={{backgroundColor:"#f6f6f6", borderRadius:"none"}}>
                <TableCell>Fee</TableCell>
                <TableCell align="center" sx={{color:"#5c715f", fontWeight:900}}>No monthly fee</TableCell>
                <TableCell align="center" sx={{color:"#f2a602", fontWeight:900}}>$299/Month</TableCell>
              </TableRow>
             {
                High_Quantity.rowData.map((e, index)=>(
                <TableRow key={e.fee} sx={{backgroundColor: ((index + 1) % 2 === 0) ? "#f6f6f6":"" }}>
                <TableCell>{e.fee}</TableCell>
                <TableCell align="left">
                {!e.link && (<Typography width="100%" sx={{fontSize:"14px"}} textAlign="left">{e.no_monthly_fee}</Typography>)}
                {e.link && (<Typography width="100%" sx={{fontSize:"14px"}} textAlign="left"><a href={e.href} style={{textDecoration:"none", color:"blue"}} target='_blank' rel="noreferrer">{e.no_monthly_fee}</a></Typography>)}
                </TableCell>
                <TableCell align="left" >
                <Stack sx={{justifyContent:"center", alignItems:"center"}}>
                {e.star && (
                  <div style={{marginBottom:"10px"}}>
                  <svg width="24" height="24" viewBox="0 0 92 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.1518 26.4082L3.56841 30.2624L3.09757 30.3582C2.38482 30.5474 1.73505 30.9225 1.21462 31.4449C0.694202 31.9674 0.321752 32.6186 0.135332 33.3321C-0.051088 34.0456 -0.0448077 34.7958 0.153522 35.5061C0.351862 36.2163 0.735142 36.8612 1.26424 37.3749L20.5226 56.1208L15.981 82.5999L15.9268 83.0583C15.8832 83.7954 16.0362 84.531 16.3703 85.1895C16.7044 85.8481 17.2076 86.406 17.8282 86.8062C18.4488 87.2064 19.1647 87.4344 19.9024 87.4669C20.6402 87.4995 21.3733 87.3355 22.0268 86.9916L45.8018 74.4916L69.5226 86.9916L69.9393 87.1833C70.627 87.4541 71.3745 87.5372 72.1049 87.4239C72.8354 87.3106 73.5225 87.005 74.0959 86.5385C74.6693 86.072 75.1083 85.4613 75.3678 84.7691C75.6273 84.077 75.698 83.3284 75.5726 82.5999L71.0267 56.1208L90.2934 37.3708L90.6184 37.0166C91.0827 36.4448 91.387 35.7601 91.5005 35.0324C91.614 34.3047 91.5327 33.5598 91.2647 32.8738C90.9966 32.1878 90.5515 31.5851 89.9746 31.1271C89.3978 30.6691 88.7098 30.3722 87.9809 30.2666L61.3976 26.4082L49.5143 2.32487C49.1704 1.6271 48.6381 1.03952 47.9776 0.62864C47.317 0.21776 46.5547 0 45.7768 0C44.9989 0 44.2365 0.21776 43.576 0.62864C42.9155 1.03952 42.3831 1.6271 42.0393 2.32487L30.1518 26.4082Z" fill="url(#paint0_linear_38_12)"/>
                    <defs>
                    <linearGradient id="paint0_linear_38_12" x1="45.7752" y1="0" x2="50" y2="61.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FCD532"/>
                    <stop offset="1" stop-color="#F8AE1A"/>
                    </linearGradient>
                    </defs>
                    </svg>
              </div>
                )}
                {!e.link && (<Typography width="100%" sx={{fontSize:"14px"}} textAlign="left">{e.$299_per_Month}</Typography>)}
                {e.link && (<Typography width="100%" sx={{fontSize:"14px"}} textAlign="left"><a href={e.href} style={{textDecoration:"none", color:"blue"}} target='_blank' rel="noreferrer">{e.$299_per_Month}</a></Typography>)}
                </Stack>
                </TableCell>
              </TableRow>
                ))
             }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {/* <DialogActions>
        
      </DialogActions> */}
    </Dialog>
  );
}
