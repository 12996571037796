import React, { useState, useEffect,  useRef } from "react";
import { AppLogin, AppSignUp, customerForget } from "../components/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userDetail } from "../features/userSlice";
import { getItem, getUserData, removeItem, setItem } from "../components/utils";
import Spinner from "../components/spinner/Spinner";
import { toast } from "react-toastify";
import Header from "../components/Header";
import Footer from "../components/FooterNew";
import RegistrationTabs from "../components/RegistrationTabs";
import Dropdown from 'react-bootstrap/Dropdown';
import {
  PayPalScriptProvider,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields,
  PayPalButtons
} from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID, STRIPE_PUBLIC_KEY, PAYPAL_SECRET } from "../components/Api";
import { Buffer } from 'buffer';


const SubmitPayment = ({ customStyle }) => {
  const [paying, setPaying] = useState(false);
  const cardHolderName = useRef(null);
  const hostedField = usePayPalHostedFields();
  let navigate = useNavigate();

  // Here declare the variable containing the hostedField instance
  

  // const submitHandler = () => {
  //     if (typeof hostedFields.submit !== "function") return; // validate that `submit()` exists before using it
  //     hostedFields
  //         .submit({
  //             // The full name as shown in the card and billing address
  //             cardholderName: "John Wick",
  //         })
  //         .then((order) => {
  //             fetch(
  //                 "/your-server-side-integration-endpoint/capture-payment-info"
  //             )
  //                 .then((response) => response.json())
  //                 .then((data) => {
  //                     // Inside the data you can find all the information related to the payment
  //                 })
  //                 .catch((err) => {
  //                     // Handle any error
  //                 });
  //         });
  // };
  const submitHandler = async() => {
    const auth = Buffer.from(PAYPAL_CLIENT_ID + ":" + PAYPAL_SECRET).toString("base64");
    const accessToken = ""// await generateAccessToken();
  const response = await fetch(`https://www.sandbox.paypal.com/v1/billing/subscriptions`, {
    method: "POST",
    body: JSON.stringify({
      plan_id: "P-3RX065706M3469222L5IFM4I"
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'PayPal-Request-Id': 'SUBSCRIPTION-21092019-001',
      'Prefer': 'return=representation'
    
    },
  })//.catch(err => console.log("err=", err));

  console.log("response=", response)
}
  const handleClick = () => {
    
    console.log("hostedField=", hostedField)
    // if (!hostedField?.cardFields) {
    //         const childErrorMessage = 'Unable to find any child components in the <PayPalHostedFieldsProvider />';

    //         action(ERROR)(childErrorMessage);
    //         throw new Error(childErrorMessage);
    //     }
    // const isFormInvalid =
    //   Object.values(hostedField.cardFields.getState().fields).some(
    //     (field) => !field.isValid
    //   ) || !cardHolderName?.current?.value;

    // if (isFormInvalid) {
    //   return alert(
    //     "The payment form is invalid"
    //   );
    // }
    // setPaying(true);
    hostedField.cardFields
      .submit({
        cardholderName: cardHolderName?.current?.value,
      })
      .then((data) => {
        // Your logic to capture the transaction
        fetch("url_to_capture_transaction", {
          method: "POST",
        })
          .then((response) => response.json())
          .then((data) => {
            // Here use the captured info
          })
          .catch((err) => {
            // Here handle error
          })
          .finally(() => {
            // setPaying(false);
          });
      })
      .catch((err) => {
        // Here handle error
        // setPaying(false);
      });
  };
  return (
    <>
     <PayPalButtons
    className="card-btn"
     createSubscription={(data, actions) => {
      return actions.subscription
        .create({
          plan_id: "P-58V39178Y42846355MQ72MFQ",
        })
        .then((orderId) => {
          console.log("orderId ==", orderId)
          // Your code here after create the order
          return orderId;
        });
    }}
     ></PayPalButtons>
      <div className="card-btn" onClick={handleClick}>SAVE</div>
      <div  className="col-12 cancelBtn"  onClick={(e) => navigate('/service')}>
        CANCEL
      </div>
  </>
  );
};


const Billing = () => {
  const [clientToken, setClientToken] = useState("");

  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Remember, setRemember] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  var getRememberMeData = getItem("rememberme"); //getting customer id from local storage

  var RemeberData = !!getRememberMeData && getRememberMeData;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const base = "https://api-m.sandbox.paypal.com";

  const generateAccessToken = async() => {
    const auth = Buffer.from(PAYPAL_CLIENT_ID + ":" + PAYPAL_SECRET).toString("base64");
    const response = await fetch(`${base}/v1/oauth2/token`, {
      method: "POST",
      body: "grant_type=client_credentials",
      headers: {
        Authorization: `Basic ${auth}`,
      },
    }).catch(err => console.log("err=", err));
    const data = await response.json();
    
    return data.access_token;
  }

  const generateClientToken = async() => {
    const accessToken = await generateAccessToken();
    const response = await fetch(`${base}/v1/identity/generate-token`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": "en_US",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    // console.log("client_token ==",data.client_token)
    setClientToken(data.client_token)
    return data.client_token;
  }

  // Login here
  const handleSignup = (e) => {
    e.preventDefault();
    var signupInfo = {
      firstName: firstName,
      lastName: lastName,
      email: Email,
      password: Password,
    };

    navigate("/service");

    // setLoader(true);
    // AppSignUp(signupInfo)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setLoader(false);
    //       // user Object
    //       toast.success(res.message);
    //       setTimeout(() => {
    //         // navigate("/login");
    //         navigate("/service");
    //       }, 1000);
    //     } else {
    //       setLoader(false);
    //       toast.error(res.message);
    //     }
    //   })
    //   .catch((err) => {
    //     setLoader(false);
    //     toast.error(err.message);
    //   });
  };

  useEffect(() => {
     generateClientToken()
    
    // let LoggedIn = getUserData();
    // console.log("client_token ==",client_token)
    // if (LoggedIn && LoggedIn != "") {
    //   return navigate("/");
    // }
  }, []);
  const cardHolderName = useRef(null);
  const hostedField = usePayPalHostedFields();

const CUSTOM_FIELD_STYLE = {"border":"0.5px solid #4D4D4D"};
const INVALID_COLOR = {
	color: "#dc3545",
};

  return (
    <>
      <Header />
      <div className="auth_bg">
        <div className="container-fluid bg-white box-shadow">
          <div className="row g-0">
            
              {/* Sign up or Create profile */}
              <div className=" text-center">
                <div className="bt-logo">
                  <a href="/">
                    <img className="top_img_text" src="assets/images/logo_text.png"  alt="logo"  />
                  </a>
                </div>
                <div className="signup_title">
                  Registration
                </div>
                <RegistrationTabs active={2}/>
                <div className="signup_title">
                  <h1 >Choose your payment method</h1>
                </div>
                <div className="login_form" style={{padding:30}}>
                  <h3>Billing information</h3>
               
               
                  {
                    clientToken && (
                      <PayPalScriptProvider
                      options={{
                          "client-id": PAYPAL_CLIENT_ID,
                          "data-client-token": clientToken,
                          components: 'buttons',
                          currency: "USD",
                          intent: "subscription",
                          vault: true,
                      }}
                  >
                      {/* <PayPalHostedFieldsProvider
                        createSubscription={(data, actions) => {
                          return actions.subscription
                            .create({
                              plan_id: "P-58V39178Y42846355MQ72MFQ",
                            })
                            .then((orderId) => {
                              // Your code here after create the order
                              return orderId;
                            });
                        }}
                          createOrder={function () {
                            const auth = Buffer.from(PAYPAL_CLIENT_ID + ":" + PAYPAL_SECRET).toString("base64");
                            return fetch(
                              "https://www.sandbox.paypal.com/v1/billing/subscriptions",
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Basic ${auth}`,
                                },
                                body: JSON.stringify({
                                  plan_id: "P-58V39178Y42846355MQ72MFQ",
                                  // intent: "subscription",
                                }),
                              }
                            )
                              .then((response) => response.json())
                              .then((order) => {
                                // Your code here after create the order
                                return order.id;
                              })
                              .catch((err) => {
                                alert(err);
                              });
                          }}
                      >
                                    <div className="row">
                                      <div className="col-6">
                                      <label htmlFor="card-holder" className="card-label">
                                              Card Holder Name
                                          <span style={INVALID_COLOR}>*</span>
                                        </label>
                                        <input
                                          id="card-holder"
                                          ref={cardHolderName}
                                          className="card-field"
                                          style={{ ...CUSTOM_FIELD_STYLE, outline: "none", fontSize:'16px' }}
                                          type="text"
                                          // placeholder="Full name"
                                        />
                                      </div>
                                      <div className="col-6">
                                      <label htmlFor="postal-code" className="card-label">
                                                  Billing ZIP Code
                                              <span style={INVALID_COLOR}>*</span>
                                          </label>
                                          <PayPalHostedField
                                              id="postal-code"
                                              className="card-field"
                                              style={CUSTOM_FIELD_STYLE}
                                              hostedFieldType="postalCode"
                                              options={{
                                                  selector: "#postal-code",
                                                  placeholder: "27001",
                                              }}
                                          />

                                      </div>
                                      <div className="col-6">
                                      <label htmlFor="card-number" className="card-label">
                                              Card Number
                                              <span style={INVALID_COLOR}>*</span>
                                          </label>
                                          <PayPalHostedField
                                              id="card-number"
                                              className="card-field"
                                              style={CUSTOM_FIELD_STYLE}
                                              hostedFieldType="number"
                                              options={{
                                                  selector: "#card-number",
                                                  placeholder: "4111 1111 1111 1111",
                                              }}
                                          />
                                      </div>
                                      <div className="col-3">
                                      <label htmlFor="cvv" className="card-label">
                                              CVV<span style={INVALID_COLOR}>*</span>
                                          </label>
                                          <PayPalHostedField
                                              id="cvv"
                                              className="card-field"
                                              style={CUSTOM_FIELD_STYLE}
                                              hostedFieldType="cvv"
                                              options={{
                                                  selector: "#cvv",
                                                  placeholder: "123",
                                                  maskInput: true,
                                              }}
                                          />
                                      </div>
                                      <div className="col-3">
                                      <label htmlFor="expiration-date" className="card-label">
                                              Expiration Date
                                              <span style={INVALID_COLOR}>*</span>
                                          </label>
                                          <PayPalHostedField
                                              id="expiration-date"
                                              className="card-field"
                                              style={CUSTOM_FIELD_STYLE}
                                              hostedFieldType="expirationDate"
                                              options={{
                                                  selector: "#expiration-date",
                                                  placeholder: "MM/YYYY",
                                              }}
                                          />
                                      </div>
                                    </div>
                                    
                                      
                                      
                                        
                                        
                              
                      </PayPalHostedFieldsProvider>
                       */}
                      {/* <SubmitPayment customStyle={{"border":"0.5px solid #4D4D4D"}} /> */}
                      <PayPalButtons
                        // className="card-btn"
                        // amount = "<amount>"
                        // currency = "USD"
                        createSubscription={(data, actions) => {
                          return actions.subscription
                            .create({
                              plan_id: "P-58V39178Y42846355MQ72MFQ",
                            })
                            .then((orderId) => {
                              console.log("orderId ==", orderId)
                              // Your code here after create the order
                              return orderId;
                            });
                        }}
                        onApprove={(data, detail) => {
                          console.log("Payapl approved")
                          console.log(data)
                        }}
                        // catchError={paypalOnError}
                        // onError={paypalOnError}
                        // onCancel={paypalOnError}
                        ></PayPalButtons>
                  </PayPalScriptProvider>
                      
                    )
                  }
                 </div>
                {/* <div className="login_form">
                  <form
                    method="post"
                    action="/account"
                    id="create_customer"
                    acceptCharset="UTF-8"
                  >
                    <h3>Billing information</h3>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <h4>Card Holder name</h4>
                          <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            value={firstName}
                            // placeholder="First name"
                            onChange={(e) => setFirst(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                        <h4>Billing ZIP Code</h4>
                          <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            value={lastName}
                            // placeholder="Last name"
                            onChange={(e) => setLast(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      <div className="col-6">
                        <div className="form-group">
                        <h4>Card Number</h4>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={lastName}
                            // placeholder="Shop or Boutique Tree Name"
                            onChange={(e) => setLast(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                        <h4>VC</h4>
                          <input
                            type="text"
                            name="shop"
                            id="shop"
                            value={lastName}
                            // placeholder="Email Address"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      <div className="col-3">
                        <div className="form-group">
                        <h4>Expiry Date</h4>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            value={Password}
                            // placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                     
                    
                          <div className="col-12">
                            <div className="form-group">
                              {Loader && (
                                <div className="spinner_div">
                                  <Spinner />
                                </div>
                              )}
                              <input
                                type="submit"
                                value="SAVE"
                                name="name"
                                onClick={(e) => handleSignup(e)}
                                disabled={Loader}
                              />
                            </div>
                          </div>
                          <div  className="col-12 cancelBtn"  onClick={(e) => navigate('/service')}>
                            CANCEL
                          </div>
                    </div>
                  </form>
                
                </div> */}
              </div>

              {/* End create profile  */}
           

      
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Billing;


