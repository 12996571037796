import React, { useEffect, useRef, useState } from "react";
import NavLinks from "../Nav";
import { getUserData } from "../utils";
import SubscribeConnection from "./SubscribeConnection";
import SubscribePrivateConnection from "./SubscribePrivateConnection";

import {
  subscribeConnection,
  getConnections,
  removeConnection,
  getAllConnections,
  getCollectionProducts,
} from "../auth";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";
import { useNavigate, useLocation } from "react-router-dom";
import Products from "./Products";

const Connections = () => {
  var userData = getUserData();
  let navigate = useNavigate();
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showPrivateSubscribe, setShowPrivateSubscribe] = useState(false);
  const [Loader, setLoader] = useState(false);

  const [connections, setConnections] = useState([]);
  const [allConnections, setAllConnections] = useState([]);
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [collapsed, setCollapsed] = useState();
  const [products, setProducts] = useState();

  const fetchConnections = () => {
    setLoader(true);
    getConnections()
      .then((response) => {
        if (response.status === 200) {
          setConnections(response.data);
          // toast.success(response.message);
          setLoader(false);
        } else {
          setLoader(false);
          // toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  const fetchAllConnections = () => {
    setLoader(true);
    getAllConnections()
      .then((response) => {
        if (response.status === 200) {
          setAllConnections(response.data);
          // toast.success(response.message);
          setLoader(false);
        } else {
          setLoader(false);
          // toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchConnections();
    fetchAllConnections();
  }, []);

  const onSubscribe = (code) => {
    if (!code) {
      toast.error("please enter code");
      return;
    }
    setLoader(true);
    subscribeConnection({
      connection_uid: [code],
    })
      .then((response) => {
        if (response.status === 200) {
          // setShowSubscribe(false)
          toast.success(response.message);
          if (showPrivateSubscribe) {
            setShowPrivateSubscribe(false);
            setShowSubscribe(false);
          }

          setLoader(false);
          fetchConnections();
          fetchAllConnections();
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  const onRemoveSubscription = (data) => {
    setLoader(true);
    setOpenDelete(!isOpenDelete);
    removeConnection({
      subscription_id: data._id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.message);
          setLoader(false);
          fetchConnections();
          fetchAllConnections();
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  const onEdit = (data) => {
    navigate(`/sync-shop/${data._id}`);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onGetCollectionProducts = (item) => {
    // if(!code){
    //   toast.error("please enter code");
    //   return;
    // }
    // console.log("item == ", item)
    // const collectionIds = item.connection.collections.map(x => x.collectionId);
    // if(collectionIds.length < 1){
    //   setProducts([]);
    //   return;
    // }
    setLoader(true);
    getCollectionProducts({
      connection_id: item.connection.connection_id,
      // collection_id: collectionIds
    })
      .then((response) => {
        if (response.status === 200) {
          setProducts(response.data);
          // setShowSubscribe(false)
          // toast.success(response.message);
          setLoader(false);
          // fetchConnections();
          // fetchAllConnections();
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoader(false);
      });
  };

  return (
    <div //className="bottom_layout white"
      className={`bottom_layout white ${Loader ? "bg_blur" : ""}`}
    >
      {isOpenDelete && selectedSubscription && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setOpenDelete(!isOpenDelete);
                }}
              >
                ×
              </div>

              <p style={{ textAlign: "center" }}>
                {" "}
                Are you sure you want to remove this subscription?
              </p>
              <div className="justify-content-center row">
                <div className="btn_wrap">
                  <button
                    className="btn no-btn-dt no_delete_btn"
                    onClick={(e) => {
                      setOpenDelete(!isOpenDelete);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn yes-btn-dt yes_delete_btn"
                    onClick={(e) => {
                      onRemoveSubscription(selectedSubscription);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPrivateSubscribe ? (
        <SubscribePrivateConnection
          allConnections={allConnections}
          onBack={() => setShowPrivateSubscribe(!showPrivateSubscribe)}
          onSubscribe={onSubscribe}
        />
      ) : showSubscribe ? (
        <SubscribeConnection
          onPrivateSubascribe={() => setShowPrivateSubscribe(true)}
          allConnections={allConnections}
          onBack={() => setShowSubscribe(!showSubscribe)}
          onSubscribe={onSubscribe}
        />
      ) : (
        <div
          className="sync-shop"
          // className={`sync-shop ${
          //   Loader ? "bg_blur" : ""
          // }`}
        >
          <div className="row">
            <div className="col">
              <h1>My Connections</h1>
            </div>
            <div className="connection-btn">
              <input
                type="button"
                defaultValue="Subscribe Connection"
                onClick={(event) => {
                  setShowPrivateSubscribe(!showPrivateSubscribe);
                }}
              />
            </div>
          </div>
          <div
            className="col-12"
            //  className={`col-12 ${
            //   Loader ? "bg_blur" : ""
            // }`}
          >
            <div className="table_session">
              <table
                className="table table-bordered"
                // className={`table table-bordered ${
                //   Loader ? "bg_blur" : ""
                // }`}
              >
                <thead>
                  <tr>
                    <th>Connection Name</th>
                    <th>Collections</th>
                    <th>Code</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {Loader && (
                      <tr>
                        <td>
                          <div className="spinner_div">
                            <Spinner />
                          </div>
                        </td>
                      </tr>
                    )}
                    {connections && connections.length > 0 ? (
                      connections.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>{item.connection.connection_name}</td>
                              <td>
                                {item.connection.collections &&
                                item.connection.collections.length > 0 ? (
                                  <div>
                                    {item.connection.collections.map(
                                      (collection) => (
                                        <div className="collection-box">
                                          {collection.title}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="no-record"></div>
                                )}
                              </td>
                              <td>{item.connection.connection_uid}</td>
                              <td>
                                {capitalizeFirstLetter(item.approval_status)}
                              </td>
                              <td className="icon_view">
                                <p>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target={`#orderDetails-${i}`}
                                    onClick={() => {
                                      onEdit(item);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    />
                                  </a>
                                </p>
                                {!item.connection.is_default && (
                                  <p>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        setSelectedSubscription(item);
                                        setOpenDelete(true);
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      />
                                    </a>
                                  </p>
                                )}{" "}
                              </td>
                              <td className="icon-container col-2">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setProducts([]);
                                    if (
                                      collapsed &&
                                      collapsed._id === item._id
                                    ) {
                                      setCollapsed(null);
                                    } else {
                                      setCollapsed(item);
                                      //call api
                                      onGetCollectionProducts(item);
                                    }
                                    // console.log(collapsed)
                                  }}
                                >
                                  {!collapsed ||
                                  (collapsed && collapsed._id !== item._id) ? (
                                    <img
                                      className="img"
                                      src="../assets/images/collapse-right.png"
                                      alt="logo"
                                    />
                                  ) : (
                                    <img
                                      className="img"
                                      src="../assets/images/collapse.png"
                                      alt="logo"
                                    />
                                  )}
                                </a>
                              </td>
                            </tr>
                            {products &&
                              collapsed &&
                              collapsed._id === item._id && (
                                <tr>
                                  <td colspan="6">
                                    <Products products={products} />
                                  </td>
                                </tr>
                              )}
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>No connections found</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Connections;
