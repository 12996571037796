import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/FooterNew";
import RegistrationTabs from "../components/RegistrationTabs";
import countryList from "react-select-country-list";
import SignUpInfoForm from "../components/forms/SignUpInfoForm";
import ReturnLabelForm from "../components/forms/ReturnLabelForm"
import ProductBillingForm from "../components/forms/ProductBillingForm";
import { useLocation } from 'react-router-dom';
import { getCustomerInfoByDomainName } from "../components/auth";
import { getItem } from "../components/utils";

const SignUp = () => {
  const [Loader, setLoader] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const options = useMemo(() => countryList().getData(), [])

  const [active, setActive] = useState(0)
  const [formData, setFormData] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryString = location.search;
  const shopName = queryParams.get("shop");

    useEffect(() => {
     if(shopName && active === 0){
      getCustomerInfoByDomainName(queryString).then((res)=>{
        if(res.status === 200) {
          setFormData(res.data)
        }
      })
     }
    }, [shopName,active]);

    const currentStep = getItem("step");

    useEffect(()=>{
      if(Number(currentStep) === 2 && active !== 1){
        setActive(1)
      }
    },[currentStep])

  const onTabPrev = (e) => {
    // e.preventDefault();
    if(active === 0) return;
    setActive(prev => prev -1)
  }

  const onTabNext = (e) => {
    // e.preventDefault();
    if(active >= 3) return;
    setActive(prev => prev +1)
  }
  

  return (
    <>
      <Header />
      <div className="auth_bg">
        <div className="container-fluid bg-white box-shadow">
          <div className="row g-0">
            
              {/* Sign up or Create profile */}
              <div className=" text-center">
                <div className="bt-logo">
                  <a href="/">
                    <img className="top_img_text" src="assets/images/logo_text.png"  alt="logo"  />
                  </a>
                </div>
             <div className="signup_title">
                  Registration
                </div>
                <RegistrationTabs active={active}/>
                <div className="signup_title">
                  <h1 >Join Boutique Tree Today!</h1>
                  {/* <h2>Already have an account? <a href="/" style={{textDecoration:'underline'}}>Login here</a></h2> */}
                </div>
                
               {active === 0 && <SignUpInfoForm onTabPrev={onTabPrev} onTabNext={onTabNext} formData={formData}/>}
               {active === 1 && <ProductBillingForm onTabPrev={onTabPrev} onTabNext={onTabNext} />}
               {active === 2 && <ReturnLabelForm onTabPrev={onTabPrev} onTabNext={onTabNext} />}
              </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default SignUp;
