import React, {useEffect, useRef, useState} from "react";

import "../payment/stripe/style.css";


const Products = (props) => {

  return (
    
    <div className="bg-white w-100 col-24 product-view">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="product-heading">Upcoming Launches</h3>
            {
              (props.products.sample_url) && (
                <div className="p-1 mt-3">
                <div className="buy-sample" onClick={() => window.open(props.products.sample_url, "_blank")}>Buy Sample</div>
                {/* <p className="product-title">{product.title}</p> */}
              </div>
              )
            }
        </div>
       
         <div className="d-flex flex-wrap">
          <>
            {
              (props.products && props.products.upcoming_launches && props.products.upcoming_launches.length > 0) ? props.products.upcoming_launches.map(product => (
               
                <div className="p-1 mt-3">
                  <img className="product-img" src={product.images.length > 0 ? product.images[0].src : ''} />
                  <p className="product-title">{product.title && product.title.length < 40 ? product.title : `${product.title.substring(0,40)}...`}</p>
                </div>
              ))
              : <p className="no-product-message">No Upcoming launches</p>
            }
            
            {/* {
              ((!props.products || !props.products.upcoming_launches || props.products.upcoming_launches.length < 1) && !props.products.sample_url) && (
                <p className="no-product-message">No Upcoming launches</p>
              )
            } */}
            </>
         </div>
         <h3 className="product-heading" style={{marginTop:10}}>Past Launches</h3>
         <div className="d-flex flex-wrap">
            {
              (props.products && props.products.past_launches && props.products.past_launches.length>0) ? props.products.past_launches.map(product => (
                <div className="p-1 mt-3">
                  <img className="product-img" src={product.images.length > 0 ? product.images[0].src : ''} />
                  <p className="product-title">{product.title && product.title.length < 40 ? product.title : `${product.title.substring(0,40)}...`}</p>
                </div>
              ))
              : <p className="no-product-message">No Past launches</p>
            }
         </div>
    </div>
  );
};
export default Products;
