import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getItem, getTempUserData, getUserData, removeItem, setItem, setTempUserData } from "../../components/utils";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { Country } from 'country-state-city';
import { TextField, Stack, Grid, Box, Button, Typography } from "@mui/material";
import { FormLogoInfo } from "./SignUpInfoForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { darken } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WelcomeDialog from "../dialogBox/WelcomeDialog";
import { UpdateCustomerSignup } from "../auth";

export default function SignUpInfoForm({ onTabNext, onTabPrev }) {
  const [Loader, setLoader] = useState(false);
  const isMobile = useMediaQuery('(max-width:500px)');
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let tempUserData = getTempUserData();
  let customerId = tempUserData?.customerInfo?.customer_data?.id || tempUserData?.customerInfo?.shopify_id;
  const [openBox, setOpenBox] = useState(false);
  const countries = Country.getAllCountries();
  const { register, handleSubmit, getValues,setValue, setError, control, formState: { errors } } = useForm({ defaultValues: { country: "united states" } });

  useEffect(() => {
    let LoggedIn = getUserData();
    if (LoggedIn && LoggedIn !== "") {
      removeItem("tempUserData");
      return navigate("/");
    }
    setError('returnLableName', { type: 'required', message: '' });
    setError('address1', { type: 'required', message: '' });
  }, []);

  const onSubmit = data => {
    let payload = {
      ...data,
      updates_on_bt_offers: data.updates_on_bt_offers === true ? 1 : 0
    };
    delete payload.cpassword;
    delete payload.submit_resale_license;
    delete payload.terms;
    setLoader(true);
    UpdateCustomerSignup(payload, customerId)
      .then((res) => {
        if (res.status === 200) {
          setTempUserData(res.data);
          setOpenBox(true);
          setLoader(false);
          toast.success(res.message);
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
  };

  const onError = data => {
    console.log("onError== ", data);
    setLoader(false);
  };

  const inputRef = useRef(null);

  const [addressData, setAddressData] = useState({
    address1: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  })

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (inputRef.current) {
        let autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          { componentRestrictions: { country: "us" }, types: ["address"] }
        );
        autocomplete.addListener("place_changed", () => {
          let place = autocomplete.getPlace();
          place.address_components.forEach((component) => {
            const componentType = component.types[0];
            switch (componentType) {
              case "street_number":
              case "route":
                setValue("address1", `${place.name}`);
                setAddressData({...addressData, address1: place.name})
                break;
              case "country":
                setValue("country", "united states");
                setAddressData({...addressData, country: "United States"})
                break;
              case "administrative_area_level_1":
                setValue("state", component.long_name);
                setAddressData({...addressData, state: component.long_name})
                break;
              case "locality":
              case "sublocality":
              case "sublocality_level_1":
                setValue("city", component.long_name);
                setAddressData({...addressData, city: component.long_name})
                break;
              case "postal_code":
                setValue("zipCode", component.long_name);
                setAddressData({...addressData, zipCode: component.long_name})
              default:
                break;
            }
          });
        });
      }
    };

    if (window.google) {
      initializeAutocomplete();
    } else {
      console.error("Unable to initialize google map")
    }
  }, [setValue]);

  return (
    <div>
      <div className="signup_form">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <h3 style={{ fontWeight: 600 }}>Return Label Information</h3>
          <Stack sx={{ textAlign: 'left' }}>
            <Typography sx={{ color: "#919191", fontSize: "14px", fontWeight: 600, margin: "0 !important" }}>
              We customize the return label on all packages to your name and address :-)
            </Typography>
            <p style={{ color: "#919191", fontSize: "12px" }}>
              Please provide the address for your custom return label below:
            </p>
          </Stack>
          <div className="row">
            <Box columnGap={2} display="grid" gridTemplateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="email">Return Label Name*</label>
                  <input
                    type="text"
                    id="returnLableName"
                    {...register("returnLableName", { required: "Return Label Name is required" })}
                  />
                  {errors.returnLableName && errors.returnLableName.message && <p role="alert" className="invalid-feedback">{errors.returnLableName.message}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="address1">Address*</label>
                  <Controller
                    name="address1"
                    control={control}
                    rules={{ required: "Address is required" }}
                    render={({ field }) => (
                      <input
                        {...field}
                        ref={(e) => {
                          field.ref(e);
                          inputRef.current = e;
                        }}
                        type="text"
                        id="address1"
                      />
                    )}
                  />
                  {errors.address1 && errors.address1.message && <p role="alert" className="invalid-feedback">{errors.address1.message}</p>}
                </div>
              </div>
            </Box>
            <Box columnGap={2} display="grid" gridTemplateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="country">Country*</label>
                  <input
                    type="text"
                    id="country"
                    className="disabled disabled_input_field"
                    disabled
                    value={getValues('country')}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="state">State*</label>
                  <input
                    type="text"
                    id="state"
                    className="disabled disabled_input_field"
                    disabled
                    value={getValues('state')}
                  />
                </div>
              </div>
            </Box>
            <Box columnGap={2} display="grid" gridTemplateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="city">City*</label>
                  <input
                    type="text"
                    id="city"
                    className="disabled disabled_input_field"
                    disabled
                    value={getValues('city')}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="zipCode">Zip/Postal Code*</label>
                  <input
                    type="text"
                    id="zipCode"
                    className="disabled disabled_input_field"
                    disabled
                    value={getValues('zipCode')}
                  />
                </div>
              </div>
            </Box>
            <div className="col-12">
              <Grid container spacing={2} sx={{ marginTop: '20px' }} direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      padding: "8px",
                      width: isMobile ? '100%' : '150px !important',
                      backgroundColor: '#f1f1f1',
                      color: '#919191',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: 'white',
                        boxShadow: 'none'
                      },
                    }}
                    onClick={() => {
                      onTabPrev();
                      setItem("step", 2);
                    }}
                    name="join"
                    startIcon={<Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
                      <ArrowBackIcon sx={{ color: '#5b5b5b' }} />
                    </Box>}
                  >
                    <span style={{ fontWeight: 500 }}>Previous</span>
                  </Button>
                </Grid>
                <Grid item>
                  {Loader && (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  )}
                  <Button
                    variant="contained"
                    type="submit"
                    name="join"
                    sx={{
                      padding: "8px",
                      color: 'white',
                      backgroundColor: '#B78580',
                      width: isMobile ? '100%' : '150px !important',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: darken('#B78580', 0.1),
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => { }}
                    endIcon={<ArrowForwardIcon sx={{ color: 'white', fontWeight: 600 }} />}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </form>
        <FormLogoInfo />
      </div>
      <WelcomeDialog openBox={openBox} setOpenBox={setOpenBox} onTabNext={onTabNext} />
    </div>
  );
}