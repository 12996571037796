import React, { useEffect, useRef, useState } from "react";

// Common function toggle
export default function useToggle(initialValue = false) {
  const [value, setValue] = React.useState(initialValue);
  const toggle = React.useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle];
}

export function addBodyClass(className) {
  return () =>
    useEffect(() => {
      document.body.className = className;
      return () => {
        document.body.className = "no-bg";
      };
    });
}

export function stringLimit(string, counts) {
  var text = string;
  var count = counts;
  var result = text.slice(0, count) + (text.length > count ? "**********" : "");
  return result;
}

export function setStorage(key, value) {
  return localStorage.setItem(key, value);
}

export function removeStorage(key) {
  return localStorage.removeItem(key);
}

export function randomString(len = 5) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export const removeDublicateFrds = (friendList) => {

  friendList.forEach((data_outer, i) => {
    let count = 0;
    friendList.forEach((data_inner, j) => {
      if (
        data_inner.address1 == data_outer.address1 &&
        data_inner.address2 == data_outer.address2 &&
        data_inner.city == data_outer.city &&
        data_inner.firstName == data_outer.firstName&&
        data_inner.lastName == data_outer.lastName
      ) {
        count += 1;
        if (count > 1) {
          friendList.splice(j, 1);
        }
      }
    });
  });
  return friendList;
};

export const useOutsideAlerter = (ref, setOpendatePicker) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpendatePicker(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const dateFormatReturn = (userdate) => {
  return new Date(userdate).toISOString().split("T")[0];
};

export const finalremoveDublicateFrds = (friendList) => {
  console.log("friendList".friendList);
  friendList.forEach((data_outer, i) => {
    let count = 0;
    friendList.forEach((data_inner, j) => {
      if (
        data_inner.address1 == data_outer.address1 &&
        data_inner.address2 == data_inner.address2 &&
        data_inner.city == data_inner.city &&
        data_inner.firstName == data_inner.firstName &&
        data_inner.isPosition != "first"
      ) {
        count += 1;
        if (count > 1) {
          friendList.splice(j, 1);
        }
      }
    });
  });
  return friendList;
};
