import React, {useEffect, useState} from "react";
import Preferences from "../components/Shop-Sync/Preferences";
import Settings from "../components/Shop-Sync/Settings";
import NavLinks from "../components/Nav";
import Logout from "../components/Logout";
import { useNavigate, useParams } from "react-router-dom";
import {
  getConnections,getConnectionDetail
} from "../components/auth";

const ShopSyncSettings = () => {

  let { subscriptionId } = useParams(); //getting url parameter
  const [name, setName] = useState('')
  const [preferences, setPreferences] = useState()
  const [settings, setSettings] = useState()
  const [Loader, setLoader] = useState(false);
  const [connections, setConnections] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const fetchConnections = ()  =>  {
    setLoader(true);
    getConnections().then((response) => {
      if (response.status === 200) {
        setConnections(response.data)
        setLoader(false);
      } else {
        setLoader(false);
      }
    }).catch((error) => {
      // toast.error(error.message);
      setLoader(false);
    })
  }

  const getConnection = ()  =>  {
    setLoader(true);
    getConnectionDetail({
      subscription_id:subscriptionId
    }).then((response) => {
      if (response.status === 200) {
        const connection = response.data;
        // setConnections(response.data)
      setName(connection.connection.name);
      setIsDuplicate(connection.has_duplicate_products);
      setPreferences(connection.preferences ? connection.preferences : null);
      setSettings(connection.settings ? connection.settings : null)
        setLoader(false);
      } else {
        setLoader(false);
      }
    }).catch((error) => {
      // toast.error(error.message);
      setLoader(false);
    })
  }

  useEffect(() => {
    if(subscriptionId){
      getConnection()
    }  
  }, [subscriptionId]);

  return (
    <section className="bt_batch_overview py-0">
      <div className="container-fluid px-0">
        <div className="row g-0">
          <div className="col-12 d-flex">
            <NavLinks/>
            <div className="right_sidebar">
              <div className="top_section">
                <h1>Sync Shop
                <h6>With Boutique Tree</h6>
                </h1>
                <Logout/>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="preferences-tab" data-bs-toggle="tab" data-bs-target="#preferences" type="button" role="tab" aria-controls="preferences" aria-selected="true">Preferences</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">Settings</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                
                <div class="tab-pane fade show active" id="preferences" role="tabpanel" aria-labelledby="preferences-tab">
                  <Preferences isDuplicate={isDuplicate} subscriptionId={subscriptionId} preferences={preferences} name={name}/>
                </div>
                <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                  <Settings isDuplicate={isDuplicate} subscriptionId={subscriptionId} settings={settings} name={name} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopSyncSettings;