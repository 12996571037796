import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { getItem, getTempUserData, getUserData, removeItem, setItem, setUserData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userDetail } from '../../features/userSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  border:"5px solid #B78580 !important",
}));

export default function WelcomeDialog({openBox, setOpenBox, onTabNext}) {
  const dispatch = useDispatch()
  let userData = getUserData();
  const navigate = useNavigate();

  let  accessToken = userData?.customerAccessToken?.accessToken
  
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpenBox(true);
  };

  const handleClose = () => {
    setOpenBox(false);
    const tempUserData = getTempUserData()
    dispatch(
      userDetail({
        userDetail: tempUserData,
      })
    );
    setUserData(tempUserData)
    removeItem("tempUserData")
    navigate(`/`)
    onTabNext()
  };

  return (
    <React.Fragment>
      <BootstrapDialog
       fullWidth
        maxWidth={"xs"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openBox}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography sx={{ fontSize:"23px",  fontFamily: 'Playball-Regular', fontWeight:700, color:"#456645"}} >
          Congrats!
          </Typography>
          <Typography sx={{fontSize:"13px", fontFamily: 'Poppins', fontWeight:700, color:"#456645"}} >
          Welcome to Boutique Tree!
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            cursor:"pointer",
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex:100,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{color:"#456645" }} fontSize="medium"/>
        </IconButton>
        <Stack sx={{width:"25%"}}>
         <img 
          src="/assets/images/welcome-image.png" 
          alt='welcome-img' 
          style={{
            width:"130px", 
            height:"130px",  
            position: 'absolute',
            right: 18,
            top: 8,}}
            />
         </Stack>
        <DialogContent sx={{padding  :"0px 16px 12px 16px !important"}}>
        <Stack sx={{flexDirection:"row",  justifyContent:"space-between", }}>
         <Stack sx={{width:"65%"}}>
            <Typography sx={{fontSize:"13px", padding:"0 0 15px 0", fontFamily: 'Poppins', fontWeight:700, color:"#B78580"}}>
            Your collection requests have been submitted. 
            </Typography>
            <Typography sx={{fontSize:"13px", padding:"0 0 15px 0", fontFamily: 'Poppins', fontWeight:700, color:"#B78580"}}>
            Please allow up to 24 hours for us to review your documentation and approve the collections.
            </Typography>
            <Typography sx={{fontSize:"13px", padding:"0 0 15px 0", fontFamily: 'Poppins', fontWeight:700, color:"#B78580"}}>
            Thank You!
            </Typography>
         </Stack>
        </Stack>  
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}    