import React, { useState, useEffect } from "react";
import {
  AppLogin,
  customerForget,
  AppLoginUsingToken,
} from "../components/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userDetail } from "../features/userSlice";
import { getItem, getUserData, removeItem, setItem } from "../components/utils";
import Spinner from "../components/spinner/Spinner";
import { toast } from "react-toastify";
import { BRAND_LOGO, BRAND_NAME, BRAND_LOGIN_BANNER } from "../components/Api";

const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Remember, setRemember] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  var getRememberMeData = getItem("rememberme"); //getting customer id from local storage

  var RemeberData = !!getRememberMeData && getRememberMeData;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const collectQueryParams = useQuery();

  const handleTokenLogin = (loginUsingToken) => {
    setLoader(true);
    AppLoginUsingToken(loginUsingToken)
      .then((res) => {
        if (res.status == 200) {
          setItem("hideSync", "0");
          setLoader(false);
          // user Object
          var userData = res.data;
          // Store in redux
          dispatch(
            userDetail({
              userDetail: userData,
            })
          );
          if (userData.customerInfo.customer_data.is_app_installed === 0) {
            setItem("hideSync", "1");
            navigate("/");
          } else if (
            userData.customerInfo.customer_data.is_previous_merchant === 1 &&
            userData.customerInfo.customer_data.is_app_installed === 1
          ) {
            setItem("selectedConnections", []);
            navigate("/upgrade-plan");
          } else if (
            userData.customerInfo.customer_data.is_previous_merchant === 2 &&
            userData.customerInfo.customer_data.is_app_installed === 1
          ) {
            setItem("hideSync", "1");
            navigate("/");
          } else navigate("/");
        } else {
          setLoader(false);
          toast.error(res.message);
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // setting remember checked
  const rememberChecked = (e) => {
    setRemember(e.target.checked);
  };

  // Login here
  const handleLogin = (e) => {
    e.preventDefault();
    var loginInfo = {
      email: Email,
      password: Password,
    };

    // Remember me functionality
    if (Remember && Email !== "") {
      var rememberMeData = {
        email: Email,
        password: Password,
        remember: Remember,
      };
      setItem("rememberme", rememberMeData);
    } else {
      removeItem("rememberme");
    }
    // End Remember me functionality
    setLoader(true);
    AppLogin(loginInfo)
      .then((res) => {
        if (res.status == 200) {
          setItem("hideSync", "0");
          setLoader(false);
          // user Object
          var userData = res.data;
          // Store in redux
          dispatch(
            userDetail({
              userDetail: userData,
            })
          );
          // if(userData.customerInfo.customer_data.is_previous_merchant){
          //   navigate("/upgrade-plan");
          // }
          if (userData.customerInfo.customer_data.is_app_installed === 0) {
            setItem("hideSync", "1");
            navigate("/");
          } else if (
            userData.customerInfo.customer_data.is_previous_merchant === 1 &&
            userData.customerInfo.customer_data.is_app_installed === 1
          ) {
            setItem("selectedConnections", []);
            navigate("/upgrade-plan");
          } else if (
            userData.customerInfo.customer_data.is_previous_merchant === 2 &&
            userData.customerInfo.customer_data.is_app_installed === 1
          ) {
            console.log("hideSync set to 1");
            setItem("hideSync", "1");
            navigate("/");
          } else navigate("/");
        } else {
          setLoader(false);
          toast.error(res.message);
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_SHOPIFY_APP_URL, "_blank");
    // navigate("/signup");
  };

  // Forget Password here
  const handleReset = (e) => {
    e.preventDefault();
    if (Email == "") {
      toast.error("Please enter your email");
      return false;
    }
    var resetInfo = {
      email: Email,
    };
    // End Remember me functionality
    setLoader(true);
    customerForget(resetInfo)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          toast.success(res.message);
          setForgotPasswordStatus(!forgotPasswordStatus);
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
        // console.log("err ==",err);
      });
  };

  useEffect(() => {
    const token = collectQueryParams.get("access_token");
    if (token) {
      removeItem("userData");
    }
    let LoggedIn = getUserData();

    if (
      collectQueryParams.get("source") === "admin" &&
      !LoggedIn &&
      LoggedIn === null
    ) {
      const loginUsingToken = {
        token: token,
      };
      handleTokenLogin(loginUsingToken);
    }
    setItem("hideSync", "0");
    if (LoggedIn && LoggedIn != "") {
      if (LoggedIn.customerInfo.customer_data.is_app_installed === 0) {
        setItem("hideSync", "1");
        navigate("/");
      } else if (
        LoggedIn.customerInfo.customer_data.is_previous_merchant === 1 &&
        LoggedIn.customerInfo.customer_data.is_app_installed === 1
      ) {
        setItem("selectedConnections", []);
        navigate("/upgrade-plan");
      } else if (
        LoggedIn.customerInfo.customer_data.is_previous_merchant === 2 &&
        LoggedIn.customerInfo.customer_data.is_app_installed === 1
      ) {
        setItem("hideSync", "1");
        navigate("/");
      } else return navigate("/");
    }

    if (RemeberData.remember && RemeberData.email !== "") {
      setEmail(RemeberData.email);
      setPassword(RemeberData.password);
      setRemember(true);
    }
  }, []);

  return (
    <>
      <section className="image-layer py-0">
        <div className="container-fluid px-0">
          <div className="row g-0">
            <div className="col-md-7">
              {/* RECOVERY PASSWORD  */}
              {forgotPasswordStatus && forgotPasswordStatus ? (
                <div
                  className="login_form left_content"
                  id="recover-password"
                  style={{ display: "block" }}
                >
                  <div className="bt-logo">
                    <a href="/">
                      <img
                        src="//cdn.shopify.com/s/files/1/0256/7281/0559/t/13/assets/logo_150x.png?v=67130605259994743631655922859"
                        alt="logo"
                      />
                    </a>
                  </div>
                  <div className="login_title">
                    <h1>Reset your password</h1>
                    <h2>We will send you an email to reset your password:</h2>
                  </div>
                  <form
                    action="#"
                    method="post"
                    id="login_form"
                    encType="multipart/form-data"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            id="recover-email"
                            placeholder="Email Address"
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          {Loader && (
                            <div className="spinner_div">
                              <Spinner />
                            </div>
                          )}
                          <input
                            type="submit"
                            value="Reset"
                            name="Submit"
                            onClick={(e) => handleReset(e)}
                            disabled={Loader}
                          />{" "}
                          <a
                            href="#"
                            className="bt-reg"
                            onClick={(e) => {
                              e.preventDefault();
                              setForgotPasswordStatus(!forgotPasswordStatus);
                            }}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="left_content">
                  <div className="logo">
                    <a href="#">
                      <img src={`${BRAND_LOGO}`} alt={`${BRAND_NAME}`} />
                    </a>
                  </div>
                  <div className="login_title">
                    <h1>Welcome back</h1>
                    <h2>Please enter your details to sign in</h2>
                  </div>
                  <div className="login_form">
                    <form
                      action="#"
                      method="post"
                      id="login_form"
                      encType="multipart/form-data"
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              name="your-email"
                              value={Email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              name="your-password"
                              value={Password}
                              onChange={(e) => setPassword(e.target.value)}
                              type="password"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="flexCheckDefault"
                              className="form-check-input"
                              checked={Remember}
                              onChange={rememberChecked}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <p>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForgotPasswordStatus(
                                    !forgotPasswordStatus
                                  );
                                }}
                              >
                                Forgot password?
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            {Loader && (
                              <div className="spinner_div">
                                <Spinner />
                              </div>
                            )}
                            <input
                              type="submit"
                              value="Login"
                              name="name"
                              onClick={(e) => handleLogin(e)}
                              disabled={Loader}
                            />
                            <input
                              type="submit"
                              value="SignUp"
                              name="SignUp"
                              onClick={(e) => handleSignup(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {/* Sign up or Create profile */}

              {/* End create profile  */}
            </div>

            <div className="col-md-5">
              <div className="bg_layout">
                <img
                  src={`${BRAND_LOGIN_BANNER}`}
                  alt="login_banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
