import React, {useState, useEffect} from "react";
import {AppLogin, customerForget, AppLoginUsingToken, customerReset} from "../components/auth";
import {useNavigate, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {userDetail} from "../features/userSlice";
import {getItem, getUserData, removeItem, setItem} from "../components/utils";
import Spinner from "../components/spinner/Spinner";
import {toast} from "react-toastify";

const ResetPassword = () => {

  const [Loader, setLoader] = useState(false);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  // var getRememberMeData = getItem("rememberme"); //getting customer id from local storage

  const [newPassword, setNewPassword] = useState("");
  const [cNewPassword, setCNewPassword] = useState("");

  const [token, setToken] = useState("");

  // var RemeberData = !!getRememberMeData && getRememberMeData;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const collectQueryParams = useQuery();

  // const handleTokenLogin = (loginUsingToken) => {
  //   setLoader(true);
  //   AppLoginUsingToken(loginUsingToken)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setLoader(false);
  //         // user Object
  //         var userData = res.data;
  //         // Store in redux
  //         dispatch(
  //           userDetail({
  //             userDetail: userData,
  //           })
  //         );
  //         navigate("/");
  //       } else {
  //         setLoader(false);
  //         toast.error(res.message);
  //         console.log("error");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // setting remember checked
  // const rememberChecked = (e) => {
  //   setRemember(e.target.checked);
  // };

  // Login here
  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   var loginInfo = {
  //     email: Email,
  //     password: Password,
  //   };

  //   // Remember me functionality
  //   if (Remember && Email !== "") {
  //     var rememberMeData = {
  //       email: Email,
  //       password: Password,
  //       remember: Remember,
  //     };
  //     setItem("rememberme", rememberMeData);
  //   } else {
  //     removeItem("rememberme");
  //   }
  //   // End Remember me functionality
  //   setLoader(true);
  //   AppLogin(loginInfo)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setLoader(false);
  //         // user Object
  //         var userData = res.data;
  //         // Store in redux
  //         dispatch(
  //           userDetail({
  //             userDetail: userData,
  //           })
  //         );
  //         navigate("/");
  //       } else {
  //         setLoader(false);
  //         toast.error(res.message);
  //         console.log("error");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleSignup = (e) =>{
  //   e.preventDefault();
  //   navigate("/create");
  // }

  // Forget Password here
  // const handleReset = (e) => {
  //   e.preventDefault();
  //   if (Email == "") {
  //     toast.error("Please enter your email");
  //     return false;
  //   }
  //   var resetInfo = {
  //     email: Email,
  //   };
  //   // End Remember me functionality
  //   setLoader(true);
  //   customerForget(resetInfo)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setLoader(false);
  //         toast.success(res.message);
  //         setForgotPasswordStatus(!forgotPasswordStatus);
          
  //       } else {
  //         setLoader(false);
  //         toast.error(res.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleOnPasswordChange = (e) => {
    e.preventDefault();
    if (newPassword === "") {
      toast.error("Please enter new password!");
      return false;
    }
    if (newPassword !== cNewPassword) {
      toast.error("Password do not match!");
      return false;
    }
    var resetInfo = {
      token:token,
      password:newPassword
    };
    // End Remember me functionality
    setLoader(true);
    customerReset(resetInfo)
      .then((res) => {
        if (res.status == 200) {
          setLoader(false);
          toast.success(res.message);
          // setForgotPasswordStatus(!forgotPasswordStatus);
          // setShowResetPassword(!showResetPassword)
          navigate('/');
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const token = collectQueryParams.get("token");
    if (token) {
      // removeItem("userData");
      setToken(token);
      // setShowResetPassword(true);
    }
    // let LoggedIn = getUserData();

    // if (collectQueryParams.get("source") === "admin" && !LoggedIn && LoggedIn === null) {
    //   const loginUsingToken = {
    //     token: token,
    //   };
    //   handleTokenLogin(loginUsingToken);
    // }

    // if (LoggedIn && LoggedIn != "") {
    //   return navigate("/");
    // }

    // if (RemeberData.remember && RemeberData.email !== "") {
    //   setEmail(RemeberData.email);
    //   setPassword(RemeberData.password);
    //   setRemember(true);
    // }
  }, []);

  return (
    <>
      <section className="image-layer py-0">
        <div className="container-fluid px-0">
          <div className="row g-0">
            <div className="col-md-7">
              {/* RECOVERY PASSWORD  */}
         
                <div
                className="login_form left_content"
                id="recover-password"
                style={{display: "block"}}
              >
                <div className="bt-logo">
                  <a href="/">
                    <img
                      src="//cdn.shopify.com/s/files/1/0256/7281/0559/t/13/assets/logo_150x.png?v=67130605259994743631655922859"
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="login_title">
                  <h1>Reset your password</h1>
                  {/* <h2>We will send you an email to reset your password:</h2> */}
                </div>
                <form
                  action="#"
                  method="post"
                  id="login_form"
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          name="Password"
                          id="password"
                          placeholder="Enter Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          name="cPassword"
                          id="cpassword"
                          placeholder="Re-enter Password"
                          value={cNewPassword}
                          onChange={(e) => setCNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        {Loader && (
                          <div className="spinner_div">
                            <Spinner/>
                          </div>
                        )}
                        <input
                          type="submit"
                          value="Reset"
                          name="Submit"
                          onClick={(e) => handleOnPasswordChange(e)}
                          disabled={Loader}
                        />{" "}
                        <a
                          href="#"
                          className="bt-reg"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/")
                            // setForgotPasswordStatus(!forgotPasswordStatus);
                            // setShowResetPassword(!showResetPassword);
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
           
             
              {/* Sign up or Create profile */}


              {/* End create profile  */}

            </div>

            <div className="col-md-5">
              <div className="bg_layout">
                <img
                  src="/assets/images/login_banner.jpg"
                  alt="login_banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default ResetPassword;
