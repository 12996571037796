import React, { useState, useRef, useEffect } from "react";

import { useForm } from "react-hook-form";

import useInputMask from "../hooks/useInputMask";

import { captureOrderPayment } from "../components/auth";

import Spinner from "./spinner/Spinner";

import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

const PaypalAdvanceCheckout = ({
  cartDetails,

  cartId,

  totalAmount,

  orderIds,

  paypalAdvanceCheckoutObj,

  customerEmail,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, submitCount },
  } = useForm();

  useInputMask();

  const [cvv, setCVV] = useState("");

  const [cardNumber, setCardNumber] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]{0,4}$/;

    if (regex.test(inputValue)) {
      setCVV(inputValue);
    } else {
      return false;
    }
  };

  const handleCardNumberChange = (e) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]{0,16}$/;

    if (regex.test(inputValue)) {
      setCardNumber(inputValue);
    } else {
      return false;
    }
  };

  const [cardFrom, setCardForm] = useState({
    name: "",

    cvv: "",

    cardNumber: "",

    expiryMonth: "",

    expiryYear: "",
  });

  const [processing, setProcessing] = useState(false);

  let navigate = useNavigate();

  // const onSubmit = data => console.log(data);

  console.log("HERE");

  console.log(paypalAdvanceCheckoutObj);

  const onSubmit = (data) => {
    data.cvv = cvv;

    data.cardNumber = cardNumber;

    if (data.cardNumber.length < 14) {
      touchedFields.cardNumber = false;
    } else {
      const payload = {
        creditCardInformation: data,

        orderInformation: orderIds,

        orderInfo: paypalAdvanceCheckoutObj,

        email: customerEmail,
      };

      setProcessing(true);

      captureOrderPayment(payload)
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              setProcessing(false);

              toast.success(response.message);

              navigate("/");
            }, 5000);
          } else {
            setTimeout(() => {
              setProcessing(false);

              toast.error(response.message);

              navigate("/");
            }, 5000);
          }
        })
        .catch((errors) => {
          setTimeout(() => {
            setProcessing(false);

            toast.error(errors);

            // navigate("/");
          }, 5000);

          console.log(errors);
        });
    }
  };

  return (
    <>
      <div className="form-container" style={{ marginTop: "20px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field-container">
            <label htmlFor="name">Name</label>

            <input
              id="name"
              type="text"
              placeholder="Card Holder's Name"
              {...register("name", { required: true })}
            />

            {errors.name?.type === "required" && (
              <p role="alert" className="invalid-feedback">
                Name is required
              </p>
            )}
          </div>

          <div className="field-container">
            <label htmlFor="cardNumber">Card Number</label>

            <input
              id="cardNumber"
              type="text"
              placeholder="Card Number"
              value={cardNumber}
              onChange={handleCardNumberChange}

              // {...register("cardNumber", {

              //   required: true

              // })}
            />

            {(touchedFields.cardNumber || submitCount > 0) &&
              !/^[0-9]{14,16}$/.test(cardNumber) && (
                <p role="alert" className="invalid-feedback">
                  Please enter valid card number
                </p>
              )}
          </div>

          <div className="field-container">
            <label htmlFor="expiryMonth">Expiration Month</label>

            <input
              id="expiryMonth"
              type="text"
              data-inputmask-regex="[0-1][0-9]"
              placeholder="MM"
              {...register("expiryMonth", { required: true, max: 12 })}
            />

            {errors.expiryMonth?.type === "required" && (
              <p role="alert" className="invalid-feedback">
                Expiration Month is required
              </p>
            )}
          </div>

          <div className="field-container">
            <label htmlFor="expiryYear">Expiration Year</label>

            <input
              id="expiryYear"
              type="text"
              data-inputmask-regex="[0-9][0-9][0-9][0-9]"
              placeholder="YYYY"
              {...register("expiryYear", {
                required: true,
                valueAsNumber: true,
              })}
            />

            {errors.expiryYear?.type === "required" && (
              <p role="alert" className="invalid-feedback">
                Expiration Year is required
              </p>
            )}
          </div>

          <div className="field-container">
            <label htmlFor="cvv">Security Code</label>

            <input
              id="cvv"
              type="text"
              value={cvv}
              placeholder="CVV"
              onChange={handleChange}

              // {...register("cvv", {required: true, valueAsNumber: true, maxLength: 4, minLength: 3 })}
            />

            {(touchedFields.cvv || submitCount > 0) &&
              !/^[0-9]{3,4}$/.test(cvv) && (
                <p role="alert" className="invalid-feedback">
                  Please enter valid Security Code
                </p>
              )}
          </div>

          <div className="row gx-3">
            <div className="col-md-12">
              <div className="button_session custom_cx_button">
                {processing ? (
                  <div className="spinner_div">
                    <Spinner />
                  </div>
                ) : (
                  <div className="combine_btn_colm strip_payment_btn">
                    <button
                      type="submit"
                      className="combine_button"
                      // onClick={(e) => {

                      //   e.preventDefault();

                      //   payNowUsingCreditCard();

                      // }}

                      name="combine"
                    >
                      Pay ${totalAmount}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*<a className="go_to_save_card"*/}

          {/*   onClick={() => payNowUsingCreditCard()}*/}

          {/*>Pay Now {totalAmount}</a>*/}
        </form>
      </div>
    </>
  );
};

export default PaypalAdvanceCheckout;
