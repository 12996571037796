import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetail: [],
    allOrders: "",
    cartOrders: "",
    OrdersHistory: "",
    signUpFromData:{},
  },
  reducers: {
    userDetail: (state, action) => {
      state.userDetail = action.payload.userDetail;
    },
    allOrders: (state, action) => {
      state.allOrders = action.payload.allOrders;
    },
    cartOrders: (state, action) => {
      state.cartOrders = action.payload.cartOrders;
    },
    OrdersHistory: (state, action) => {
      state.OrdersHistory = action.payload.OrdersHistory;
    },
    customerInfoByDomainName : (state, action) => {
      state.signUpFromData = action.payload.signUpFromData;
    }
  },
});
export const { userDetail, allOrders, OrdersHistory, cartOrders } =
  userSlice.actions;

export const userDetailData = (state) => state.user.userDetail;
export const allOrdersData = (state) => state.user.allOrders;
export const cartOrdersData = (state) => state.user.cartOrders;
export const OrdersHistoryData = (state) => state.user.OrdersHistory;
export default userSlice.reducer;
