import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";

const RegistrationTabs = (props) => {
  let pathname = window.location.pathname;
  // const [active, setActive] = useState(props.active)

  function Opens() {
    "https://boutiquetree.com/";
  }

  return (
    <div style={{ justifyContent: "center", display: "grid" }}>
      <div className="tab-container">
        <div>
          <div className="row tab-item">
            <div className="tabIcon-complete">1</div>
            <div className="left-bar-inactive"></div>
          </div>
          <div className="bottom-bar-inactive"></div>
        </div>
        {props.active === 0 ? (
          <div>
            <div className="row tab-item">
              <div className="tabIcon">2</div>
              <div className="left-bar-active"></div>
            </div>
            <div className="bottom-bar-active"></div>
          </div>
        ) : (
          <div>
            <div className="row tab-item">
              <div className="tabIcon-complete">2</div>
              <div className="left-bar-inactive"></div>
            </div>
            <div className="bottom-bar-inactive"></div>
          </div>
        )}
        {props.active === 1 ? (
          <div>
            <div className="row tab-item">
              <div className="tabIcon">3</div>
              <div className="left-bar-active"></div>
            </div>
            <div className="bottom-bar-active"></div>
          </div>
        ) : (
          <>
            {props.active < 1 ? (
              <div>
                <div className="row tab-item">
                  <div className="tabIcon-inactive">3</div>
                  <div className="left-bar-inactive"></div>
                </div>
                <div className="bottom-bar-inactive"></div>
              </div>
            ) : (
              <div>
                <div className="row tab-item">
                  <div className="tabIcon-complete">3</div>
                  <div className="left-bar-inactive"></div>
                </div>
                <div className="bottom-bar-inactive"></div>
              </div>
            )}
          </>
        )}
        {props.active === 2 ? (
          <div>
            <div className="row tab-item">
              <div className="tabIcon">4</div>
            </div>
            <div className="bottom-bar-active"></div>
          </div>
        ) : (
          <div>
            <div className="row tab-item">
              <div className="tabIcon-inactive">4</div>
            </div>
            <div className="bottom-bar-inactive"></div>
          </div>
        )}
      </div>
      <div className="tab-label-container row">
        <div
          className={`col-3 ${
            props.active === 5 ? " tab-label-active" : " tab-label"
          }`}
        >
          Collection {">>"}
        </div>
        <div
          className={`col-3 ${
            props.active === 0 ? " tab-label-active" : " tab-label"
          }`}
        >
          Info {">>"}
        </div>
        <div
          className={`col-3 ${
            props.active === 1 ? " tab-label-active" : " tab-label"
          }`}
        >
          Product Billing {">>"}
        </div>
        <div
          className={`col-3 ${
            props.active === 2 ? " tab-label-active" : " tab-label"
          }`}
        >
          Return Label {">>"}
        </div>
      </div>
    </div>
  );
};
export default RegistrationTabs;
