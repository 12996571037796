import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Login,
  AccountDetail,
  Analytics,
  OrderHistory,
  Order,
  ResetPassword,
  ShopSync,
  ShopSyncSettings,
} from "./Pages";
import ProtectedRoute from "./protected.route";
import Checkout from "./Pages/Checkout";
import { STRIPE_PUBLIC_KEY } from "./components/Api";
import Transaction from "./Pages/Transaction";
import SignUp from "./Pages/SignUp";
import Service from "./Pages/Service";
import Billing from "./Pages/Billing";
import UpgradePlan from "./Pages/UpgradePlan";
import SuggestPlan from "./Pages/SuggestPlan";
import ConfirmFreePlan from "./Pages/ConfirmFreePlan";
import "./components/disableLogs.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";

library.add(fas, faTwitter, faFontAwesome);

const promise = loadStripe(STRIPE_PUBLIC_KEY);

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/upgrade-plan" element={<UpgradePlan />} />
      <Route path="/suggest-plan" element={<SuggestPlan />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/service" element={<Service />} />
      <Route
        path="/select-plan"
        element={<Service noNavLinks={true} isSelectPlan={true} />}
      />
      <Route
        path="/confirm-free-plan"
        element={<ConfirmFreePlan noNavLinks={true} />}
      />
      <Route path="/service?:charge_id" element={<Service />} />
      <Route path="/billing" element={<Billing />} />
      <Route
        path="/"
        exect
        element={
          <ProtectedRoute>
            <Order />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order-history"
        exect
        element={
          <ProtectedRoute>
            <OrderHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/account-detail"
        exect
        element={
          <ProtectedRoute>
            <AccountDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/analytics"
        exect
        element={
          <ProtectedRoute>
            <Analytics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/transactions"
        exect
        element={
          <ProtectedRoute>
            <Transaction />
          </ProtectedRoute>
        }
      />
      <Route
        path="/checkout/:checkout"
        exect
        element={
          <Elements stripe={promise}>
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          </Elements>
        }
      />
      <Route
        path="/sync-shop"
        exect
        element={
          <ProtectedRoute>
            <ShopSync />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sync-shop/:subscriptionId"
        exect
        element={
          <ProtectedRoute>
            <ShopSyncSettings />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
