import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {GetAllOrders, orderSort, searchOrder} from "../components/auth";
import NavLinks from "../components/Nav";
import Spinner from "../components/spinner/Spinner";
import {getUserData, removeItem} from "../components/utils";
import {OrdersHistory} from "../features/userSlice";
import axios from "axios";
import {API_BASE_URL} from "../components/Api";
import Logout from "../components/Logout";
import Moment from "react-moment";

const OrderHistory = () => {
  const dispatch = useDispatch(); //redux
  const [allOrder, setAllOrder] = useState("");
  const [allData, setAllData] = useState([]);
  const [sortingLoader, setSortingLoader] = useState(false);
  const [orderLoader, setOrderLoader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchText2, setSearchText2] = useState("");

  var getCustomerId = getUserData(); //getting customer id from local storage

  var customerId =
    !!getCustomerId && getCustomerId
      ? getCustomerId.customerInfo.customer_data.id
      : "";

  // Fetch all Order here
  const getAllOrders = () => {
    var AllOrderInfo = {
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
      status: "paid",
    };

    GetAllOrders(AllOrderInfo)
      .then((res) => {
        setOrderLoader(false);
        if (res.status === 200) {
          // user Object

          setAllOrder(res.data.data);
          setAllData(res.data);
          setSortingLoader(false);

          // Store in redux
          dispatch(
            OrdersHistory({
              OrdersHistory: res.data.data,
            })
          );
        } else {
          setOrderLoader(false);
          setSortingLoader(false);
          console.log("error");
        }
      })
      .catch((err) => {
        setOrderLoader(false);
        setSortingLoader(false);
        console.log(err);
      });
  };

  const lineItemHTML = (item, i) => {
    return (
      <>
        <div
          className="modal fade orders-history"
          id={`orderDetails-${i}`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Order Details
                  <br/>
                  <span>
                    Create At:{" "}
                    <Moment format="Do MMM YYYY h:mm:ss a z">
                      {item.createdAt}
                    </Moment>
                  </span>
                </h5>
                <div className="right_side_btn">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
              <div className="orders-history modal-body">
                <div className="product-order_title">
                  <h5>Order {item.name}</h5>
                  {/* Order Status */}
                  <span className="cancel_btn">
                    <span
                      className={`${
                        item.financialStatus.toLowerCase() == "paid"
                          ? "success"
                          : "warning"
                      }`}
                    >
                      {!!item?.cancelledAt
                        ? "Cancelled"
                        : item?.financialStatus}
                    </span>{" "}
                    {item?.combineOrderIds?.length > 0 &&
                    item?.combineStatus ? (
                      <span
                        className={`${
                          item.combineStatus && "combine_cls tooltip-custom"
                        }`}
                      >
                        Combined
                        <span className="tooltip-text">
                          <ul>
                            {item?.combineOrderIds?.length > 0 &&
                              item?.combineOrderIds.map((num,i) => (
                                <li key={i}>{num}</li>
                              ))}
                          </ul>
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.mergedIn != null && item?.mergedIn != "" && (
                      <span className={"combine_cls tooltip-custom"}>
                        Merged
                        <span className="tooltip-text">
                          <ul>
                            <li>{item?.mergedIn}</li>
                          </ul>
                        </span>
                      </span>
                    )}
                  </span>
                  {/* Order Status */}
                </div>
                <ul>
                  <li>
                    <p>
                      {item?.shippingAddr?.container?.firstName}{" "}
                      {item?.shippingAddr?.container?.lastName}
                      <br/>
                      <span>
                        {item.shippingAddr?.container?.address1}
                        {", "}
                        {item.shippingAddr?.container?.city}
                        <br/>
                        {item.shippingAddr?.container?.zip}
                        {", "}
                        {item.shippingAddr?.container?.phone}
                        {", "}
                        {item.shippingAddr?.container?.country}
                      </span>
                    </p>
                  </li>
                </ul>
                <div className="table popup_table">
                  {
                    item?.lineItems?.container?.length > 0
                      ?
                      <span className="orderefunds badge-warning text-center ">
                        Unfulfilled Items
                      </span>
                      :
                      ""
                  }
                  {
                    item?.fulfillments?.length > 0
                      ?
                      <span className="orderefunds badge-warning text-center ">
                          Fulfilled Items
                        </span>
                      :
                      ""
                  }
                  <table className="table table-bordered">
                    {
                      item?.lineItems?.container?.length > 0
                        ?
                        <>
                          <thead>
                          <tr>
                            <th>Title</th>
                            <th>Quantity</th>
                            <th>Price</th>
                          </tr>
                          </thead>
                          <tbody>
                          {/* line item print here */}
                          {item?.lineItems?.container.map(
                            (ordeItem, i) =>
                              ordeItem.quantity > 0 && (
                                <tr key={i} className="white_layout">
                                  <td>
                                    <b>{ordeItem.name}</b>
                                  </td>
                                  <td>{ordeItem.currentQuantity}</td>                                  
                                  <td>${ordeItem.originalUnitPrice*ordeItem.currentQuantity}</td>
                                </tr>
                              )
                          )}
                          </tbody>
                        </>
                        : ""
                    }


                    {
                      (item?.fulfillmentStatus === 'fulfilled' || item?.fulfillmentStatus === 'partial') && (item?.fulfillments?.length > 0) && (

                        <>
                          <thead>
                          <tr>
                            <th>Title</th>
                            <th>Quantity</th>
                            <th>Price</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            item?.fulfillments?.map((fulfillment, i) =>
                              fulfillment?.line_items?.map((item, i) =>
                                <tr key={i} className="white_layout">
                                  <td>
                                    <b>{item.name}</b>
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td>${item.price*item.quantity}</td>
                                </tr>
                              )
                            )
                          }
                          </tbody>
                        </>
                      )
                    }

                    {/* Refund line item  here*/}
                    {item?.refundLineItems?.container &&
                      item?.refundLineItems?.container.length > 0 && (
                        <>
                          <span className="orderefunds badge-warning text-center ">
                            Cancelled Items
                          </span>
                          <thead>
                          <tr>
                            <th>Title</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            {/* <th>Status</th> */}
                          </tr>
                          </thead>
                          <tbody>
                          {refundItemLoop(item?.refundLineItems?.container)}
                          {/* {item?.refundLineItems?.container.map(
                              (refundOrdeItem, i) => (
                                <tr class="white_layout">
                                  <td className="line_item_title">{refundOrdeItem.name}</td>
                                  <td>{refundOrdeItem.quantity}</td>
                                  <td>
                                    ${" "}
                                    {(
                                      refundOrdeItem.originalTotal *
                                      refundOrdeItem.quantity
                                    ).toFixed(2)}
                                  </td>
                                
                                </tr>
                              )
                            )} */}
                          </tbody>
                        </>
                      )}
                    {/* End refund line item */}
                    <tfoot>
                    <tr>
                      <td colSpan={2} style={{textAlign: "left"}}>
                        Sub Total
                        <br/>
                        Shipping Price
                        <br/>
                        Total
                      </td>
                      <td>
                        ${parseFloat(item?.subTotalPrice).toFixed(2)}
                        <br/>${Number(item.totalShippingPrice).toFixed(2)}
                        <br/>${parseFloat(item?.totalPrice).toFixed(2)}
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Refund line items html

  const refundItemLoop = (refundItem) => {
    var hash = Object.create(null);
    var result = [];

    refundItem.forEach(function (o) {
      if (!hash[o.id]) {
        hash[o.id] = {
          id: o.id,
          quantity: 0,
          name: o.name,
          originalTotal: o.originalTotal,
          originalUnitPrice: o.originalUnitPrice,
          currentQuantity: o.currentQuantity,
          discountedTotal: o.discountedTotal,
          discountedUnitPrice: o.discountedUnitPrice,
          fulfillableQuantity: o.fulfillableQuantity,
          title: o.title,
          variant: {
            id: o?.variant?.id,
          },
        };
        result.push(hash[o.id]);
      }
      hash[o.id].quantity += +o.quantity;
    });

    return (
      <>
        {!!result &&
          result?.length > 0 &&
          result.map((refundOrdeItem, i) => (
            <tr key={i} className="white_layout">
              <td className="line_item_title">{refundOrdeItem?.name}</td>
              <td>{refundOrdeItem?.quantity}</td>
              <td>
                ${" "}
                {(
                  refundOrdeItem.originalTotal * refundOrdeItem.quantity
                ).toFixed(2)}
              </td>
            </tr>
          ))}
      </>
    );
  };

  var customerEmail =
    !!getCustomerId && getCustomerId
      ? getCustomerId.customerInfo.customer_data.email
      : "";
  useEffect(() => {
    getAllOrders();
  }, []);

  // ================ Search Order ==================================//
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText == "" && searchText2 == "") {
      getAllOrders();
      return false;
    }

    var searchInfo = {
      status: ["paid", "cancelled", "voided"],
      keyword: searchText,
      keyword2: searchText2,
      email: customerEmail,
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
    };
    setSortingLoader(true);

    searchOrder(searchInfo)
      .then((res) => {
        if (res.status === 200) {
          // user Object
          setAllOrder(res.data.data);
          setAllData(res.data);
          setSortingLoader(false);
          // Store in redux
          dispatch(
            OrdersHistory({
              OrdersHistory: res.data.data,
            })
          );
        } else {
          setSortingLoader(false);

          console.log("error");
        }
      })
      .catch((err) => {
        setSortingLoader(false);
        console.log(err);
      });
  };

  // ================ Sorting Order ==================================//

  const [sort, setSort] = useState({
    sortBy: "asc",
    columnSortName: "name",
    status: false,
  }); // asc or desc

  const handleSorting = (sortType, columnName) => {
    setSort({
      sortBy: sortType,
      columnSortName: columnName,
      status: !sort.status,
    });

    var sortInfo = {
      status: "paid",
      sort_by: sortType,
      email: customerEmail,
      column: columnName,
    };
    setSortingLoader(true);
    orderSort(sortInfo)
      .then((res) => {
        if (res.status === 200) {
          // user Object
          setAllOrder(res.data.data);
          setAllData(res.data);
          setSortingLoader(false);
          // Store in redux
          dispatch(
            OrdersHistory({
              OrdersHistory: res.data.data,
            })
          );
        } else {
          setSortingLoader(false);
          console.log("error");
        }
      })
      .catch((err) => {
        setSortingLoader(false);
        console.log(err);
      });
  };

  // --------------Pagination------------------
  // Handel pagination customer
  const handlePagination = async (e, url) => {
    e.preventDefault();
    try {
      if (sort.status) {
        var AllOrderInfo = {
          customer_id: customerId && customerId.replace(/^\D+/g, ""),
          status: "paid",
          sort_by: sort.sortBy,
          column: sort.columnSortName,
        };
      } else {
        var AllOrderInfo = {
          customer_id: customerId && customerId.replace(/^\D+/g, ""),
          status: "paid",
        };
      }
      if (url && url != null) {
        setSortingLoader(true);
        const {
          data: {data, message, status},
        } = await axios.post(
          API_BASE_URL + "orders?" + url.split("?")[1],
          AllOrderInfo,
          { headers: {Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`}}
        );
        if (status == 200) {
          setAllOrder(data.data);
          setAllData(data);
          setSortingLoader(false);
          // Store in redux
          dispatch(
            OrdersHistory({
              OrdersHistory: data.data,
            })
          );
          window.scrollTo(0, 0);
        }
        // setCustomer(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const htmlPrint = () => {
    return (
      <ul>
        <li>working</li>
      </ul>
    );
  };
  return (
    <>
      <div>
        <div
          className="modal fade"
          id="editShipping"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Order Details
                  <br/>
                  <span>Status</span>
                </h5>
                <div className="right_side_btn">
                  <a href="#">
                    <i className="fa fa-print" aria-hidden="true"/>
                  </a>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
              <div className="modal-body">
                <div className="shipping_form">
                  <div className="product-order_title">
                    <h5>Shipping Address</h5>
                    <p>
                      <a href="#">
                        <i className="fa fa-trash-o" aria-hidden="true"/>
                      </a>
                    </p>
                  </div>
                  <form action="/">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>First Name*</label>
                          <input
                            type="text"
                            name="first-name"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name*</label>
                          <input
                            type="text"
                            name="last-name"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            type="text"
                            name="address"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Company</label>
                          <input
                            type="text"
                            name="company"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            type="tel"
                            name="phone"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <select id="state">
                            <option value="country">Country</option>
                            <option value="country">test</option>
                            <option value="country">test</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Zip Code</label>
                          <input
                            type="text"
                            name="zipcode"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            name="city"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>State</label>
                          <input
                            type="text"
                            name="provience"
                            defaultValue
                            placeholder=''
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group text-right">
                          <input type="submit" defaultValue="Save"/>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  data-bs-target="#orderDetails"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Back to first
                </button>
              </div>
            </div>
          </div>
        </div>

        <section className="bt_batch_overview py-0">
          <div className="container-fluid px-0">
            <div className="row g-0">
              <div className="col-12 d-flex">
                <NavLinks/>
                <div
                  className={`right_sidebar tab-content ${
                    orderLoader ? "bg_blur" : ""
                  }`}
                >
                  <div className="top_section">
                    <h1>Orders History</h1>
                    <Logout/>
                  </div>
                  <div className="bottom_layout">
                    <div className="create_order_overview">
                      <div className="row" style={{'flexWrap': 'nowrap'}}>
                        <div className="col-12 col-md-12 col-sm-12">
                            <div className="row">
                              <div className="col-3 col-lg-2 col-md-4 col-sm-4">
                                <div className="search-container">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      placeholder="Ship To"
                                      name="search"
                                      value={searchText}
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                    />
                                    <div className="search-container-button">
                                      {searchText !== "" ? (
                                        <button
                                          onClick={(e) => {
                                            setSearchText("");
                                            setSortingLoader(true);
                                            removeItem("orderSearch");
                                            getAllOrders();
                                          }}
                                        >
                                          <i className="fa fa-close"/>
                                        </button>
                                      ) : (
                                        <i className="fa fa-search"/>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* search box -2 */}
                              <div className="col-3 col-lg-2 col-md-4 col-sm-4">
                                <div className="search-container">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      placeholder="Order No."
                                      name="search"
                                      value={searchText2}
                                      onChange={(e) =>
                                        setSearchText2(e.target.value)
                                      }
                                    />
                                    <div className="search-container-button">
                                      {searchText2 !== "" ? (
                                        <button
                                          onClick={(e) => {
                                            setSearchText2("");
                                            setSortingLoader(true);
                                            removeItem("orderSearch2");
                                            getAllOrders();
                                          }}
                                        >
                                          <i className="fa fa-close"/>
                                        </button>
                                      ) : (
                                        <i className="fa fa-search"/>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-3 button_session"
                                style={{
                                  marginTop: "0px",
                                }}
                              >
                                <button
                                  className={`combine_button ${
                                    (searchText === "" && searchText2 === "" ) && "disabled_btn"
                                  }`}
                                  name="search"
                                  onClick={(e) => handleSearch(e)}
                                  style={{
                                    backgroundColor: "#5d725f",
                                    padding: "1.2em 25px",
                                    boxSizing: "content-box",
                                  }}
                                  disabled={(searchText === "" && searchText2 === "") ? true : false}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="order_colm">
                      <div className="row">
                        <div className="col-12">
                          <div className="order_session">
                            <h2>Order History</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {sortingLoader && (
                            <div className="spinner_div">
                              <Spinner/>
                            </div>
                          )}
                          <div className="table_session">
                            <table className="table table-bordered">
                              <thead>
                              <tr>
                                <th>
                                <div className="d-flex">
                                  Order Number {" "}
                                  {!!sort &&
                                  sort?.status == true &&
                                  sort.columnSortName == "name" ? (
                                    <p
                                      className="decending-data sortdata"
                                      data-order="Order_num"
                                      data-val="desc"
                                      onClick={(e) =>
                                        handleSorting("desc", "name")
                                      }
                                    >
                                        <span className="order_sorting_icon order_down">
                                          <i
                                            className="fa fa-sort-amount-desc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  ) : (
                                    <p
                                      className="assending-data sortdata"
                                      data-order="Order_num"
                                      data-val="asc"
                                      onClick={(e) =>
                                        handleSorting("asc", "name")
                                      }
                                    >
                                        <span className="order_sorting_icon order_up">
                                          <i
                                            className="fa fa-sort-amount-asc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  )}
                                  </div>
                                </th>
                                <th>
                                <div className="d-flex">
                                  Ship To {" "}
                                  {!!sort &&
                                  sort?.status == true &&
                                  sort.columnSortName ==
                                  "shippingAddr.container.firstName" ? (
                                    <p
                                      className="decending-data sortdata"
                                      data-order="Order_num"
                                      data-val="desc"
                                      onClick={(e) =>
                                        handleSorting(
                                          "desc",
                                          "shippingAddr.container.firstName"
                                        )
                                      }
                                    >
                                        <span className="order_sorting_icon order_down">
                                          <i
                                            className="fa fa-sort-amount-desc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  ) : (
                                    <p
                                      className="assending-data sortdata"
                                      data-order="Order_num"
                                      data-val="asc"
                                      onClick={(e) =>
                                        handleSorting(
                                          "asc",
                                          "shippingAddr.container.firstName"
                                        )
                                      }
                                    >
                                        <span className="order_sorting_icon order_up">
                                          <i
                                            className="fa fa-sort-amount-asc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  )}
                                  </div>
                                </th>
                                <th>Status</th>
                                <th>Shipping Address</th>
                                <th>Subtotal</th>
                                <th>Shipping</th>
                                <th>
                                <div className="d-flex">
                                  Total {" "}
                                  {!!sort &&
                                  sort?.status == true &&
                                  sort.columnSortName == "totalPrice" ? (
                                    <p
                                      className="decending-data sortdata"
                                      data-order="Order_num"
                                      data-val="desc"
                                      onClick={(e) =>
                                        handleSorting("desc", "totalPrice")
                                      }
                                    >
                                        <span className="order_sorting_icon order_down">
                                          <i
                                            className="fa fa-sort-amount-desc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  ) : (
                                    <p
                                      className="assending-data sortdata"
                                      data-order="Order_num"
                                      data-val="asc"
                                      onClick={(e) =>
                                        handleSorting("asc", "totalPrice")
                                      }
                                    >
                                        <span className="order_sorting_icon order_up">
                                          <i
                                            className="fa fa-sort-amount-asc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  )}
                                  </div>
                                </th>
                                <th>
                                <div className="d-flex">
                                  Updated At {" "}
                                  {!!sort &&
                                  sort?.status == true &&
                                  sort.columnSortName == "updated_at" ? (
                                    <p
                                      className="decending-data sortdata"
                                      data-order="Order_num"
                                      data-val="desc"
                                      onClick={(e) =>
                                        handleSorting("desc", "updated_at")
                                      }
                                    >
                                        <span className="order_sorting_icon order_down">
                                          <i
                                            className="fa fa-sort-amount-desc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  ) : (
                                    <p
                                      className="assending-data sortdata"
                                      data-order="Order_num"
                                      data-val="asc"
                                      onClick={(e) =>
                                        handleSorting("asc", "updated_at")
                                      }
                                    >
                                        <span className="order_sorting_icon order_up">
                                          <i
                                            className="fa fa-sort-amount-asc"
                                            aria-hidden="true"
                                          />
                                        </span>
                                    </p>
                                  )}
                                  </div>
                                </th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {orderLoader ? (
                                  <div className="spinner_div">
                                    <Spinner/>
                                  </div>
                              ) : (
                                <>
                                  {!!allOrder &&
                                  allOrder != null &&
                                  allOrder.length > 0 ? (
                                    allOrder.map((item, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>
                                            {item.name.replace(
                                              /[!@#$%^&*]/g,
                                              ""
                                            )}  
                                          </td>
                                          <td>
                                            {
                                              item?.shippingAddr?.container
                                                .firstName
                                            }{" "}
                                            {
                                              item?.shippingAddr?.container
                                                .lastName
                                            }
                                          </td>
                                          <td className="cancel_btn ">
                                            <div className="Tracking_div">
                                              <span className="Tracking_span ">
                                                <span
                                                  className={`${
                                                    item.financialStatus.toLowerCase() ==
                                                    "paid"
                                                      ? "success"
                                                      : "warning"
                                                  } mb-1`}
                                                >
                                                  {!!item?.cancelledAt
                                                    ? "Cancelled"
                                                    : item?.financialStatus}
                                                </span>{" "}
                                                
                                              </span>
                                              {item?.combineOrderIds?.length >
                                              0 && item?.combineStatus ? (
                                                <span
                                                  className={`${
                                                    item.combineStatus &&
                                                    "combine_cls tooltip-custom"
                                                  } me-1`}
                                                >
                                                  Combined
                                                  <span className="tooltip-text">
                                                    <ul>
                                                      {item?.combineOrderIds
                                                          ?.length > 0 &&
                                                        item?.combineOrderIds.map(
                                                          (num,i) => (
                                                            <li key={i}>{num}</li>
                                                          )
                                                        )}
                                                    </ul>
                                                  </span>
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {item?.mergedIn != null &&
                                                item?.mergedIn != "" && (
                                                  <span
                                                    className={
                                                      "combine_cls tooltip-custom"
                                                    }
                                                  >
                                                    Merged
                                                    <span className="tooltip-text">
                                                      <ul>
                                                        <li>
                                                          {item?.mergedIn}
                                                        </li>
                                                      </ul>
                                                    </span>
                                                  </span>
                                                )}
                                            </div>
                                            {
                                              item?.fulfillments?.length > 0 &&
                                              item?.fulfillments?.map((fulItem, i) => {
                                                return (
                                                  <div key={i}>
                                                    <a
                                                      href={fulItem?.tracking_url}
                                                      className="success tracking_url"
                                                      target="_blank"
                                                      key={i}
                                                    >
                                                      Tracking Number :{fulItem?.tracking_number}
                                                    </a>
                                                  </div>
                                                )
                                              })
                                            }
                                          </td>
                                          <td>
                                            {
                                              item.shippingAddr?.container
                                                ?.address1
                                            }
                                            {", "}
                                            {
                                              item.shippingAddr?.container
                                                ?.city
                                            }
                                            <br/>
                                            {
                                              item.shippingAddr?.container
                                                ?.zip
                                            }
                                            {", "}
                                            {
                                              item.shippingAddr?.container
                                                ?.country
                                            }
                                          </td>
                                          <td>
                                            $
                                            {Number(
                                              item.subTotalPrice
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            $
                                            {Number(
                                              item.totalShippingPrice
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            $
                                            {Number(item.totalPrice).toFixed(
                                              2
                                            )}
                                          </td>

                                          <td>
                                            {" "}
                                            <Moment format="Do MMM YYYY h:mm:ss a z">
                                              {item.updated_at}
                                            </Moment>
                                          </td>

                                          <td className="icon_view">
                                            <p>
                                              <a
                                                data-bs-toggle="modal"
                                                data-bs-target={`#orderDetails-${i}`}
                                              >
                                                <i
                                                  className="fa fa-eye"
                                                  aria-hidden="true"
                                                />
                                              </a>
                                            </p>
                                          </td>
                                          {lineItemHTML(item, i)}
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={10}>No orders found</td>
                                    </tr>
                                  )}
                                </>
                              )}
                              </tbody>
                            </table>
                            {/* Pagination */}
                            {!!allOrder &&
                            allOrder != null &&
                            allOrder.length > 0 ? (
                              <div
                                className="pagination_colm"
                                aria-label="Page navigation"
                              >
                                <ul className="pagination">
                                  {allData &&
                                    allData?.links?.map((link, i) => {
                                      return (
                                        <li className="page-item" key={i}>
                                          <a
                                            className={`page-link ${
                                              link.active ? "active" : "disable"
                                            }`}
                                            href="#"
                                            onClick={(e) => {
                                              handlePagination(e, link.url);
                                            }}
                                          >
                                            {link.label == "&laquo; Previous"
                                              ? "Previous"
                                              : link.label == "Next &raquo;"
                                                ? "Next"
                                                : link.label}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Pagination */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderHistory;
