import React, {useEffect, useRef, useState} from "react";
import {
  updatePreferences
} from "../auth";
import {toast} from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";

const Preferences = (props) => {
  const [Loader, setLoader] = useState(false);
  const [unlink, setUnlink] = useState((props.preferences && props.preferences.unlink) ? props.preferences.unlink : '')
  const [pricing, setPricing] = useState((props.preferences && props.preferences.pricing) ? props.preferences.pricing : {
    bt_compare_at_price:false,
    bt_transformation:  {
      enabled:false,
      percentage:'',
      rounding_amount:''
    },
    bt_price:false
  })
  const [shipmentTracking, setShipmentTracking] = useState((props.preferences && props.preferences.shipment_tracking) ? props.preferences.shipment_tracking : false);

  const onPreferenceChange = () => {
    setLoader(true);
    updatePreferences({
      subscription_id: props.subscriptionId,
      preferences:{
        unlink,
        pricing,
        shipment_tracking: shipmentTracking
      }
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.message);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(response.message);
      }
    }).catch((error) => {
      toast.error(error.message);
      setLoader(false);
    })
  }

  useEffect(()  => {
    setUnlink((props.preferences && props.preferences.unlink) ? props.preferences.unlink : '');
    setPricing((props.preferences && props.preferences.pricing) ? props.preferences.pricing : {
      bt_compare_at_price:false,
      bt_transformation:  {
        enabled:false,
        percentage:'',
        rounding_amount:''
      },
      bt_price:false
    });
    setShipmentTracking((props.preferences && props.preferences.shipment_tracking) ? props.preferences.shipment_tracking : false)
    
  },[props])

  return (
    <div className="bottom_layout white">
      <div className="preference">
      <div className="row">
        <div className='col'>
        <h2>{props.name}</h2>
        {
          props.isDuplicate && (
            <div className='duplicate'>
              It seems this connection has some duplicate products from other connections. Any update you will be making in settings/preferences here would be updated for those other connections as well.
            </div>
          )
        }
        </div>
        <div className="connection-btn">
              <input
                  type="button"
                  defaultValue="Save Preferences"
                  onClick={(event) => {
                    // event.preventDefault();
                    onPreferenceChange()
                  }}
                />
            </div>
        </div>
       
        <div>
          <h3>Retail Pricing Transformations</h3>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p> */}
          <div className="switch-container">
            <label className="switch col-md-1">
              <input checked={pricing.bt_compare_at_price === true} type="checkbox" onChange={(e) => {
                // if(e.target.checked){
                  setPricing({
                    ...pricing,
                    bt_compare_at_price:e.target.checked,
                    bt_transformation:{
                      ...pricing.bt_transformation,
                      enabled: e.target.checked ? false : pricing.bt_transformation.enabled
                    }
                  })
                // }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}} >Boutique Tree compare at price mapped to my shop retail price</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
              This will set the retail price in your shop to be equal to the Boutique Tree compare at price.  Typically, 2x Boutique Tree wholesale.
              </span>
            </span>
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
              <input checked={pricing.bt_transformation.enabled === true} type="checkbox" onChange={(e) => {
                // if(e.target.checked){
                  setPricing({
                    ...pricing,
                    bt_compare_at_price: e.target.checked ? false :  pricing.bt_transformation.bt_compare_at_price,
                    bt_transformation:{
                      ...pricing.bt_transformation,
                      enabled:e.target.checked,
                     
                    }
                  })
                // }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Price transformation</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
                Based on Boutique Tree wholesale.
              </span>
            </span>
          </div>
          
          <div className="price-transformation row">
            <div  className="col-md-4 switch-container">
              <label>Percentage Transform: {" "}
              <span className="tooltip-custom">
                <img src="../assets/images/info-icon.png"  alt="logo"  />
                <span className="tooltip-text" style={{width:300}}>
                Ex 175% for 1.75x or 200% for 2x
                </span>
              </span>
              </label>
            </div>
            
            <input className="col-md-3"
              type="number" 
              disabled={!pricing.bt_transformation.enabled}
              placeholder="%"
              min="0"
              value={pricing.bt_transformation.percentage} 
              onChange={(e) => {
                // if(e.target.value >= 0 && e.target.value <=99){
                  setPricing({
                    ...pricing,
                    bt_transformation:{
                      ...pricing.bt_transformation,
                      percentage:parseInt(e.target.value)
                    }
                  })
                // }
              }}/>
          </div>
          <div className="price-transformation row">
          <div  className="col-md-4 switch-container">
              <label>Rounding Amount (Optional): {" "}
              <span className="tooltip-custom">
                <img src="../assets/images/info-icon.png"  alt="logo"  />
                <span className="tooltip-text" style={{width:300}}>
                  If populated with a value from 0 to 99, prices will end with this value. (Ex If 99 is entered, pricing will round to nearest $.99)
                </span>
              </span>
              </label>
            </div>
            <input  
              className="col-md-3" 
              type="number"
              disabled={!pricing.bt_transformation.enabled}
              value={pricing.bt_transformation.rounding_amount} 
              min="0"
              max="99"
              onChange={(e) => {
                if(e.target.value >= 0 && e.target.value <=99){
                  setPricing({
                    ...pricing,
                    bt_transformation:{
                      ...pricing.bt_transformation,
                      rounding_amount:parseInt(e.target.value)
                    }
                  })
                }
              }}/>
          </div>
         
        </div>

        <div>
          <h3>Populate Wholesale Cost</h3>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p> */}
          <div className="switch-container">
            <label className="switch col-md-1">
              <input checked={pricing.bt_price === true} type="checkbox" onChange={(e) => {
                // if(e.target.checked){
                  setPricing({
                    ...pricing,
                    bt_price:e.target.checked
                  })
                // }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Boutique Tree wholesale mapped to my shop cost</span>
            <span className="tooltip-custom">
                <img src="../assets/images/info-icon.png"  alt="logo"  />
                <span className="tooltip-text" style={{width:300}}>
                Populate product cost with the Boutique Tree wholesale.  Recommended for Shopify COGS reporting. 
                </span>
              </span>
          </div>
       
        </div>
     
        <div>
          <h3>Shipment Tracking</h3>
          <p>Toggle this option ON for shipment tracking to populate in your system retail orders.</p>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={shipmentTracking === true} type="checkbox" onChange={(e) => {
                // if(e.target.checked){
                 setShipmentTracking(e.target.checked)
                // }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Use Boutique Tree tracking values</span>
            <span className="tooltip-custom">
                <img src="../assets/images/info-icon.png"  alt="logo"  />
                <span className="tooltip-text" style={{width:300}}>
                If this preference is turned on, there will be an option to disregard populating individual orders at payment. (Ex. Local pick-up orders)
                </span>
              </span>
          </div>
          {/* <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={shipmentTracking === false} type="checkbox" onChange={(e) => {
                // if(e.target.checked){
                 setShipmentTracking(!e.target.checked)
                // }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Do not populate tracking data</span>
            <img src="../assets/images/info-icon.png"  alt="logo"  />
          </div>
           */}
       
        </div>
     
        <div>
            <h3>Unlink Behavior</h3>
            <p>Select an option below for how products should be treated when disconnecting from a Boutique Tree collection.</p>
          <div className="switch-container">
            <label className="switch col-md-1">
              <input checked={unlink === "unpublish"} type="checkbox" onChange={(e) => {
                if(e.target.checked){
                  setUnlink("unpublish")
                }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}} >Unpublish Products</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
                Products will be unpublished but remain in your system.
              </span>
            </span>
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={unlink === "delete"} type="checkbox" onChange={(e) => {
                if(e.target.checked){
                  setUnlink("delete")
                }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Delete Products</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
                Products are removed from your system.
              </span>
            </span>
          </div>
          <div className="switch-container">
            <label className="switch col-md-1">
            <input checked={unlink === "delete_or_unpublish"} type="checkbox" onChange={(e) => {
                if(e.target.checked){
                  setUnlink("delete_or_unpublish")
                }
              }}/>
              <span className="slider round"></span>
            </label>
            <span style={{paddingLeft:10, paddingRight:10}}>Unpublish Sold then Delete Remaining Products</span>
            <span className="tooltip-custom">
              <img src="../assets/images/info-icon.png"  alt="logo"  />
              <span className="tooltip-text" style={{width:300}}>
                Unpublish products that have been sold in your shop, delete otherwise. Recommended to preserve reporting.
              </span>
            </span>
          </div>
       
        </div>
     
      </div>
      {Loader && (
          <div className="spinner_div">
            <Spinner/>
          </div>
        )}
    </div>
  );
};
export default Preferences;
