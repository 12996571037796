import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
  GetAllOrders,
  GetTransactions,
  orderSort,
  searchOrder,
} from "../components/auth";
import NavLinks from "../components/Nav";
import Spinner from "../components/spinner/Spinner";
import {getUserData} from "../components/utils";
import {OrdersHistory} from "../features/userSlice";
import axios from "axios";
import {API_BASE_URL} from "../components/Api";
import Logout from "../components/Logout";
import Moment from "react-moment";

const Transaction = () => {
  const dispatch = useDispatch(); //redux
  const [allTransaction, setAllTransaction] = useState("");
  const [allData, setAllData] = useState([]);
  const [sortingLoader, setSortingLoader] = useState(false);
  const [orderLoader, setOrderLoader] = useState(true);

  var getCustomerId = getUserData(); //getting customer id from local storage

  var customerId =
    !!getCustomerId && getCustomerId
      ? getCustomerId.customerInfo.customer_data.id
      : "";

  // Fetch all Order here
  const getTransactions = () => {
    var TransactionInfo = {
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
    };

    GetTransactions(TransactionInfo)
      .then((res) => {
        setOrderLoader(false);
        if (res.status == 200) {
          // console.log(res.data,'eeeeeeeeeeee');
          setAllTransaction(res.data.data);
          setAllData(res.data);
        } else {
          setOrderLoader(false);
          console.log("error");
        }
      })
      .catch((err) => {
        setOrderLoader(false);
        console.log(err);
      });
  };

  var customerEmail =
    !!getCustomerId && getCustomerId
      ? getCustomerId.customerInfo.customer_data.email
      : "";
  useEffect(() => {
    getTransactions();
  }, []);

  // --------------Pagination------------------
  // Handel pagination customer
  const handlePagination = async (e, url) => {
    e.preventDefault();
    try {
      var TransactionInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      if (url && url != null) {
        setSortingLoader(true);
        const {
          data: {data, message, status},
        } = await axios.post(
          API_BASE_URL + "transactions?" + url.split("?")[1],
          TransactionInfo,
          { headers: {Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`}}
        );
        if (status == 200) {
          setAllTransaction(data.data);
          setAllData(data);
          setSortingLoader(false);
          window.scrollTo(0, 0);
        }
        // setCustomer(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <section className="bt_batch_overview py-0">
          <div className="container-fluid px-0">
            <div className="row g-0">
              <div className="col-12 d-flex">
                <NavLinks/>
                <div
                  className={`right_sidebar tab-content ${
                    orderLoader ? "bg_blur" : ""
                  }`}
                >
                  <div className="top_section">
                    <h1>Transactions History</h1>
                    <Logout/>
                  </div>
                  <div className="bottom_layout">
                    <div className="order_colm">
                      <div className="row">
                        <div className="col-12">
                          <div className="order_session">
                            <h2>Transactions History</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {sortingLoader && (
                            <div className="spinner_div">
                              <Spinner/>
                            </div>
                          )}
                          <div className="table_session">
                            <table className="table table-bordered">
                              <thead>
                              <tr>
                                <th>S. No.</th>
                                <th>Transaction Id</th>
                                <th>Order Id</th>
                                <th>Amount</th>
                                <th>Credits</th>
                                <th>Pay Method</th>
                                <th>Status</th>
                                <th>Created At</th>
                              </tr>
                              </thead>
                              <tbody>
                              {orderLoader ? (
                                <div className="spinner_div">
                                  <Spinner/>
                                </div>
                              ) : (
                                <>
                                  {!!allTransaction &&
                                  allTransaction != null &&
                                  allTransaction.length > 0 ? (
                                    allTransaction.map((item, i) => {
                                      return (
                                        
                                        <tr>
                                          <td>
                                         
                                            {(allData?.current_page - 1) *
                                              allData?.per_page +
                                              i +
                                              1}
                                          </td>
                                          <td>{
                                            item.type.includes("stripe")?  item?.pmId: "#" + item?.transaction_id
                                          // item?.type.toLowerCase() ==
                                          // "stripe"
                                          //   ? item?.pmId
                                          //   : "#" + item?.transaction_id
                                          }
                                          </td>
                                          <td>
                                            <ul>
                                              {item?.order_ids &&
                                                item?.order_ids.length > 0 &&
                                                item?.order_ids.map((val) => (
                                                  <li>{val}</li>
                                                ))}
                                            </ul>
                                          </td>
                                          <td>
                                            {
                                              item?.hasOwnProperty("discounted_Amt") && item?.discounted_Amt != item?.amount && item?.discounted_Amt != null &&
                                              <span className="text-decoration-line-through pe-2">${Number(item?.amount).toFixed(2)}</span>
                                            }
                                           
                                            <span> { item?.hasOwnProperty("discounted_Amt") && item?.discounted_Amt != null ? `$${Number(item?.discounted_Amt).toFixed(2)}` : `$${Number(item?.amount).toFixed(2)}`} </span>
                                          </td>
                                          <td>
                                           
                                           
                                            <span> { item?.hasOwnProperty("credits") ? `$${Number(item?.credits).toFixed(2)}` : 0}</span>
                                          </td>
                                          <td className="cancel_btn">
                                          { Array.isArray(item?.type)?
                                                (
                                                item?.type.map((elem)=> {
                                                  return(
                                                    <span
                                                    // if( if(isset($item->type[0]))){ //   $item->type[0] // }elseif(isset($item->type[1])) // { //   $item->type[1] // }else{ //   $item->type // }
                                                    className={`${
                                                      Array.isArray(item.type)?(item.type.includes("paypal")&& !item.type.includes("credits") ?"success":"success"):item?.type.toLowerCase() ==
                                                      "paypal" || "paypal-card"
                                                        ? "success"
                                                        : "warning"||
                                                      Array.isArray(item.type)?(item.type.includes("paypal-card")&& !item.type.includes("credits")?"success":"warning"):item?.type.toLowerCase() ==
                                                      "paypal" || "paypal-card"
                                                        ? "success"
                                                        : "warning"||
                                                      Array.isArray(item.type)?(item.type.includes("credits")?"success":"warning"):item?.type.toLowerCase() ==
                                                      "paypal" || "paypal-card"||"credits"
                                                        ? "success"
                                                        : "warning"
                                                                
                                                              }`}
                                                            >
                                                         {elem}
                                                            </span>
                                                  )
                                                })
                                                ): (
                                                      <span
                                                      // if( if(isset($item->type[0]))){ //   $item->type[0] // }elseif(isset($item->type[1])) // { //   $item->type[1] // }else{ //   $item->type // }
                                                      className={`${
                                                        Array.isArray(item.type)?(item.type.includes("paypal")&& !item.type.includes("credits") ?"success":"warning"):item?.type.toLowerCase() ==
                                                        "paypal" || "paypal-card"
                                                          ? "success"
                                                          : "warning"||
                                                        Array.isArray(item.type)?(item.type.includes("paypal-card")&& !item.type.includes("credits")?"success":"warning"):item?.type.toLowerCase() ==
                                                        "paypal" || "paypal-card"
                                                          ? "success"
                                                          : "warning"||
                                                        Array.isArray(item.type)?(item.type.includes("credits")?"success":"warning"):item?.type.toLowerCase() ==
                                                        "paypal" || "paypal-card"
                                                          ? "success"
                                                          : "warning"        
                                                            }`}
                                                              >
                                                           {item?.type}
                                                              </span> 
                                                  )}
                                          
                                          </td>
                                          <td>
                                            {item?.status.toLowerCase()}
                                          </td>

                                          <td>
                                            {" "}
                                            <Moment format="Do MMM YYYY h:mm:ss a z">
                                              {item?.created_at}
                                            </Moment>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={10}>
                                        No transaction found
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}
                              </tbody>
                            </table>
                            {/* Pagination */}
                            {!!allTransaction &&
                            allTransaction != null &&
                            allTransaction.length > 0 ? (
                              <div
                                className="pagination_colm"
                                aria-label="Page navigation"
                              >
                                <ul className="pagination">
                                  {allData &&
                                    allData?.links?.map((link, i) => {
                                      return (
                                        <li className="page-item" key={i}>
                                          <a
                                            className={`page-link ${
                                              link.active ? "active" : "disable"
                                            }`}
                                            href="#"
                                            onClick={(e) => {
                                              handlePagination(e, link.url);
                                            }}
                                          >
                                            {link?.label == "&laquo; Previous"
                                              ? "Previous"
                                              : link?.label == "Next &raquo;"
                                                ? "Next"
                                                : link?.label}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Pagination */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Transaction;