import React, { useEffect, useRef, useState } from 'react'
import "./styles.css"

const CustomDropdown = ({merchantLocations,setSelectedValue,selectedValue}) => {
    const [isActive,setIsActive] = useState(false)
    const btnRef = useRef()
    const itemRef = useRef()
    const item2Ref = useRef()

    const getFullAddress = (item) => {
        return `${item.address1 ? item.address1+', ':''} ${item.address2 ? item.address2+', ':' '} ${item.city?item.city+' ':' '} ${item.zip?item.zip+', ':' '} ${item.country_name?item.country_name:''}`
      }

    useEffect(() => {
      const closeDropdown = (e) => {
      if(e.srcElement !== btnRef.current && e.srcElement !== itemRef.current && e.srcElement !== item2Ref.current){
        setIsActive(false)
      }
    }
    document.body.addEventListener('click',closeDropdown)
    
      return () => {
        document.body.removeEventListener('click',closeDropdown)
      }
    }, [])

  return (
    <div className='dropdown'>
    <div className='dropdown-btn' ref={btnRef} onClick={(e)=>setIsActive(!isActive)}>
    {selectedValue?.name ? 
    (
    <div style={{display:'flex',flexDirection:'column'}}>
    <h3 ref={item2Ref} style={{fontWeight:'bold'}}>{selectedValue.name}</h3>
    <span ref={itemRef} style={{fontSize:'12px',fontWeight:'500'}}>{getFullAddress(selectedValue)}</span>
    </div>
    )  
    : "Select a location"}
    </div>
    {isActive && (
    <div className='dropdown-content'>
    {merchantLocations && merchantLocations.map((item)=>(
    <div
    onClick={(e)=>{
        setSelectedValue(item)
        setIsActive(false)
    }}
    className={`dropdown-item ${selectedValue == item ? 'selected-option':'unselected-option'}`}>
    <h3>{item.name}</h3>
    <span>{getFullAddress(item)}</span>
    </div>
    ))}
    </div>
    )}
    </div>
  )
}

export default CustomDropdown