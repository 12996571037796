import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { getUserData } from "../../utils";

export default function AddCardForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: false,
  };
  const handleChange = async (event) => {
    //console.log(event.value);
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
  };

  var getCustomerData = getUserData(); //getting customer id from local storage

  var customerName =
    !!getCustomerData && getCustomerData
      ? getCustomerData.customerInfo.customer_data.firstName +
        " " +
        getCustomerData.customerInfo.customer_data.lastName
      : "";
  var customerEmail =
    !!getCustomerData && getCustomerData
      ? getCustomerData.customerInfo.customer_data.email
      : "";

  const handleSubmit = async (ev) => {
    //console.log (props.secret());
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: customerName,
        email: customerEmail,
      },
    });

    if (payload.error) {
      setError();
      toast.error(`${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setTimeout(() => {
        setProcessing(false);
      }, 3000);
      setDisabled(true);
      props.onClick(payload);
      // toast.success("Your card added successfully");
    }
  };
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
        className="btn-primary mt-2"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Add card"
          )}
        </span>
      </button>
      <div className="text-center mt-4"></div>

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a href={`https://dashboard.stripe.com/test/payments`}>
          {" "}
          Stripe dashboard.
        </a>{" "}
        Refresh the page to pay again.
      </p>
    </form>
  );
}
