/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData, getItem, subscriptionPlanTransformData } from "../utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardForm from "../payment/stripe/AddCardForm";
import {
  SaveCustomerCard,
  GetCustomerCard,
  GetCustomerAutopay,
  SetCustomerAutopay,
  ActivateCard,
  DeleteCardById,
  GetCustomerPaypalCard,
  ActivatePaypalCard,
  SetCustomerPaypalAutopay,
  GetCustomerPaypalAutopay,
  DeletePaypalCardById,
  CheckSubscription,
  CancelSubscription,
  GetPlans,
  getInstallAPP,
} from "../auth";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";
// import "../Account-Detail/"
import { INSTALL_APP, STRIPE_PUBLIC_KEY } from "../Api";
import AddPayPalCardForm from "../payment/paypal/AddPayPalCardForm";
import AccountReturnLabel from "./AccountReturnLabel ";

const promise = loadStripe(STRIPE_PUBLIC_KEY);

const Billing = ({ hideSync }) => {
  var userData = getUserData();
  let navigate = useNavigate();
  var customerEmail =
    !!userData && userData ? userData?.customerInfo?.customer_data?.email : "";

  var customerId =
    !!userData && userData ? userData?.customerInfo?.customer_data?.id : "";

  const [subscription, setSubscription] = useState(null);
  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    province: "",
    zip: "",
    session_id: "",
  });

  const [cardForm, setCardForm] = useState({
    firstName: "",
    cardName: "",
    month: "",
    year: "",
    cvv: "",
    bilingZip: "",
  });

  // const [hideSync, setHideSync] = useState(false)

  // useEffect(() => {
  //   let hide = getItem("hideSync");
  //   setHideSync((hide && hide === "1") ? true : false);
  // }, [])

  // userInfo handle Change
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // card handle Chnage

  const cardChange = (e) => {
    setCardForm({
      ...cardForm,
      [e.target.name]: e.target.value,
    });
  };

  const setData = (userData) => {
    if (userData) {
      setForm({
        id: userData.customerInfo.customer_data?.id,
        firstName: userData.customerInfo.customer_data?.firstName,
        lastName: userData.customerInfo.customer_data?.lastName,
        email: userData.customerInfo.customer_data?.email,
        phone: userData.customerInfo.customer_data?.phone,
        company: userData.customerInfo.customer_data?.defaultAddress?.company,
        address1: userData.customerInfo.customer_data?.defaultAddress?.address1,
        address2: userData.customerInfo.customer_data?.defaultAddress?.address2,
        city: userData.customerInfo.customer_data?.defaultAddress?.city,
        country: userData.customerInfo.customer_data?.defaultAddress?.country,
        province: userData.customerInfo.customer_data?.defaultAddress?.province,
        zip: userData.customerInfo.customer_data?.defaultAddress?.zip,
        session_id: userData.session_id,
      });
    }
  };

  const [isOpencard, setOpencard] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [autopay, setAutopay] = useState(false);
  const [paypalAutopay, setPaypalAutopay] = useState(false);
  const [autopayLoader, setAutopayLoader] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [paypalCardData, setPaypalCardData] = useState([]);

  const [isStripeCardCheckboxDisable, setIsStripeCardCheckboxDisable] =
    useState(false);

  const getCurrentSubscription = () => {
    // setLoader(true);
    CheckSubscription()
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // setLoader(false);
          // toast.success(res.message);
          let resData = res.data;
          const dataUpdate = [{ ...res?.data?.plan_info }];
          GetPlans().then((res) => {
            if (res.status == 200) {
              const getTransFormData = subscriptionPlanTransformData(res.data);
              let findPlanInfo;
              if (
                !dataUpdate?.[dataUpdate.length - 1]?.plan_name ||
                dataUpdate?.[dataUpdate.length - 1]?.plan_name == ""
              ) {
                if (dataUpdate?.[dataUpdate.length - 1].is_free == 1) {
                  findPlanInfo = {
                    ...getTransFormData?.[getTransFormData.length - 1],
                    is_free: 1,
                  };
                } else {
                  findPlanInfo = getTransFormData.find(
                    (info) =>
                      info?.price == dataUpdate?.[dataUpdate.length - 1]?.price
                  );
                }
              } else {
                findPlanInfo = getTransFormData.find(
                  (info) =>
                    info?.name == dataUpdate?.[dataUpdate.length - 1]?.plan_name
                );
              }
              setSubscription({ ...resData, plan_info_updated: findPlanInfo });
            }
          });
        } else {
          // setLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        // setLoader(false);
        // toast.error(err.message);
      });
  };

  const cancelSubscription = () => {
    // setLoader(true);
    CancelSubscription({})
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // setLoader(false);
          toast.success(res.message);
          // setSubscription(null);
          getCurrentSubscription();
        } else {
          // setLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        // setLoader(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const handleToken = async (token) => {
    // console.log(token,"bbbbbbbbbbbbb");
    SaveCustomerCard(token)
      .then((res) => {
        if (res.status == 200) {
          // Get customer cards
          getCards(userData.customerInfo.customer_data.id);
          setOpencard(false);
          toast.success(res.message);
          console.log(res, "resoponse");
        } else {
          setOpencard(false);
          toast.error(res.message);
          console.log("error");
        }
      })
      .catch((err) => {
        setOpencard(false);
        console.log(err);
      });
  };

  const handleSaveDetail = () => {};
  const handleUpdateCard = () => {};

  // Get customer cards
  const getCards = (customerId) => {
    if (customerId != null && customerId != "") {
      var customerInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      setCardLoader(true);
      GetCustomerCard(customerInfo)
        .then((res) => {
          if (res.status == 200) {
            setCardLoader(false);
            setCardData(res.data);

            let setDefaultCard = res.data.find((item) => item?.primary);
            setChooseCard(setDefaultCard?.pmId);
            // cards Object
          } else {
            setCardLoader(false);
            console.log(res.message);
          }
        })
        .catch((err) => {
          setCardLoader(false);
          toast.error(err.message);
          console.log(err);
        });
    }
  };

  // Get customer cards
  const getPaypalCards = (customerId) => {
    if (customerId != null && customerId != "") {
      var customerInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
      };
      setCardLoader(true);
      GetCustomerPaypalCard(customerInfo)
        .then((res) => {
          if (res.status == 200) {
            setCardLoader(false);
            setPaypalCardData(res.data);

            let setDefaultCard = res.data.find((item) => item?.primary);
            setPaypalChooseCard(setDefaultCard?._id);
            // cards Object
          } else {
            setCardLoader(false);
            console.log(res.message);
          }
        })
        .catch((err) => {
          setCardLoader(false);
          toast.error(err.message);
          console.log(err);
        });
    }
  };

  // Get customer autopay
  const getAutopay = (customerId) => {
    try {
      if (customerId != null && customerId != "") {
        var customerInfo = {
          customer_id: customerId && customerId.replace(/^\D+/g, ""),
        };
        setCardLoader(true);
        GetCustomerAutopay(customerInfo)
          .then((res) => {
            if (res.status == 200) {
              setAutopay(res.data);
            } else {
              setAutopay(res.data);
            }
          })
          .catch((err) => {
            setCardLoader(false);
            toast.error(err.message);
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get customer autopay
  const getPaypalAutopay = (customerId) => {
    try {
      if (customerId != null && customerId != "") {
        var customerInfo = {
          customer_id: customerId && customerId.replace(/^\D+/g, ""),
        };
        setCardLoader(true);
        GetCustomerPaypalAutopay(customerInfo)
          .then((res) => {
            if (res.status == 200) {
              setPaypalAutopay(res.data);
            } else {
              setPaypalAutopay(res.data);
            }
          })
          .catch((err) => {
            setCardLoader(false);
            toast.error(err.message);
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleIsActive = () => setAutopay((autopay) => !autopay);
  const togglePaypalIsActive = () =>
    setPaypalAutopay((paypalAutopay) => !paypalAutopay);
  // Enable customer autopay
  const handleAutopay = (customerId) => {
    try {
      if (!!cardData && cardData != null && cardData.length > 0) {
        toggleIsActive();
        setAutopayLoader(true);
        if (customerId != null && customerId != "") {
          var customerInfo = {
            customer_id: customerId && customerId.replace(/^\D+/g, ""),
            autopay: !autopay,
          };
          SetCustomerAutopay(customerInfo)
            .then((res) => {
              if (res.status == 200) {
                setAutopay(res.data);
                setAutopayLoader(false);
                toast.success(res.message);
              } else {
                setAutopay(res.data);
                setAutopayLoader(false);
                toast.error(res.message);
              }
            })
            .catch((err) => {
              setAutopayLoader(false);
              toast.error(err.message);
              console.log(err);
            });
        }
      } else {
        toast.error("Please add card first.");
        setIsStripeCardCheckboxDisable(true);
        setTimeout(() => {
          setIsStripeCardCheckboxDisable(false);
        }, 4000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaypalAutopay = (customerId) => {
    try {
      if (
        !!paypalCardData &&
        paypalCardData != null &&
        paypalCardData.length > 0
      ) {
        togglePaypalIsActive();
        setAutopayLoader(true);
        if (customerId != null && customerId != "") {
          var customerInfo = {
            customer_id: customerId && customerId.replace(/^\D+/g, ""),
            paypalAutopay: !paypalAutopay,
          };
          SetCustomerPaypalAutopay(customerInfo)
            .then((res) => {
              console.log(res.status);
              if (res.status == 200) {
                setPaypalAutopay(res.data);
                setAutopayLoader(false);
                toast.success(res.message);
              } else {
                setPaypalAutopay(res.data);
                setAutopayLoader(false);
                toast.error(res.message);
              }
            })
            .catch((err) => {
              setAutopayLoader(false);
              toast.error(err.message);
              console.log(err);
            });
        }
      } else {
        toast.error("Please add card first.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [chooseCard, setChooseCard] = useState(""); //default card
  const [paypalChooseCard, setPaypalChooseCard] = useState(""); //default card
  const [primaryCard, setPrimary] = useState(false); //default card
  const [primaryPaypalCard, setPrimaryPaypal] = useState(false); //default

  const primaryIsActive = () => setPrimary((primaryCard) => !primaryCard);
  const primaryPaypalIsActive = () =>
    setPrimaryPaypal((primaryPaypalCard) => !primaryPaypalCard);

  const handleCardChange = (e) => {
    setChooseCard(e.target.value);
    setPrimary(true);

    try {
      primaryIsActive();
      var data = {
        email: customerEmail,
        pmId: e.target.value,
        primary: !primaryCard,
      };
      ActivateCard(data)
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            toast.success(res.message);
          } else {
            // console.log(res.message);
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err, "apiiiiiii");
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayPalCardChange = (e) => {
    setPaypalChooseCard(e.target.value);
    setPrimaryPaypal(true);

    try {
      primaryPaypalIsActive();
      var data = {
        email: customerEmail,
        _id: e.target.value,
        primary: !primaryPaypalCard,
        customerId: customerId,
      };
      ActivatePaypalCard(data)
        .then((res) => {
          if (res.status == 200) {
            // console.log("200-----",res.data);
            toast.success(res.message);
          } else {
            // console.log("other----",res.message);
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err, "apiiiiiii");
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Card api here
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [deleteCardLoader, setDeleteCardLoader] = useState(false);
  const [DeleteId, setDeleteId] = useState("");

  const [isPaypalOpenDelete, setPaypalOpenDelete] = useState(false);
  const [deletePaypalCardLoader, setDeletePaypalCardLoader] = useState(false);
  const [DeletePaypalId, setDeletePaypalId] = useState("");

  const deleteCardAlert = (cardId) => {
    setOpenDelete(!isOpenDelete);
    setDeleteId(cardId);
  };

  const deletePaypalCardAlert = (cardId) => {
    setPaypalOpenDelete(!isPaypalOpenDelete);
    setDeletePaypalId(cardId);
  };

  //  Delete card
  const handleDelete = (cardId) => {
    var cardObj = {
      card_id: cardId,
      customer_id: customerId,
    };

    setDeleteCardLoader(true);
    DeleteCardById(cardObj)
      .then((res) => {
        if (res.status === 200) {
          // user Object
          setTimeout(() => {
            setDeleteCardLoader(false);
            getCards(userData.customerInfo.customer_data.id);
            setOpenDelete(false);
            toast.success(res.message);
          }, 3000);
        } else {
          setDeleteCardLoader(false);
          setOpenDelete(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setDeleteCardLoader(false);
        setOpenDelete(false);
        toast.error("Something went wrong");
      });
  };
  //  Delete card
  const handleDeletePaypalCard = (cardId) => {
    var cardObj = {
      card_id: cardId,
      customer_id: customerId,
    };

    setDeletePaypalCardLoader(true);
    DeletePaypalCardById(cardObj)
      .then((res) => {
        if (res.status === 200) {
          // user Object
          setTimeout(() => {
            setDeletePaypalCardLoader(false);
            getPaypalCards(userData.customerInfo.customer_data.id);
            setPaypalOpenDelete(false);
            toast.success(res.message);
          }, 3000);
        } else {
          setDeletePaypalCardLoader(false);
          setPaypalOpenDelete(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setDeleteCardLoader(false);
        setPaypalOpenDelete(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    setData(userData);
    getCards(userData.customerInfo.customer_data.id);
    getPaypalCards(userData.customerInfo.customer_data.id);
    getAutopay(userData.customerInfo.customer_data.id);
    getPaypalAutopay(userData.customerInfo.customer_data.id);
  }, []);

  const [isOpenPaypalCard, setIsOpenPaypalCard] = useState(false);

  const handleCloseModel = () => {
    setIsOpenPaypalCard(!isOpenPaypalCard);
    getPaypalCards(userData.customerInfo.customer_data.id);
  };

  const onCancelSubscription = () => {
    cancelSubscription();
  };

  const onChangeSubscription = () => {
    navigate("/service");
  };

  useEffect(() => {
    if (window.location.hash === "#subscription-details") {
      const section = document.querySelector("#subscription-details");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  });

  const handleClickInstallAPP = (e) => {
    try {
      getInstallAPP()
        .then((res) => {
          if (res.status == 200) {
            // toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* Stripe modal */}
      {isOpenDelete && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setOpenDelete(!isOpenDelete);
                }}
              >
                ×
              </div>

              <p style={{ textAlign: "center" }}>
                {" "}
                Are you sure you want to delete this card?
              </p>
              {deleteCardLoader && (
                <div className="spinner_div">
                  <Spinner />
                  Card Informat
                </div>
              )}
              <div className="justify-content-center row">
                <div className="btn_wrap">
                  <button
                    className="btn no-btn-dt no_delete_btn"
                    onClick={(e) => {
                      setOpenDelete(!isOpenDelete);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn yes-btn-dt yes_delete_btn"
                    onClick={(e) => {
                      handleDelete(DeleteId);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {/* Stripe modal */}
      {isPaypalOpenDelete && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setPaypalOpenDelete(!isPaypalOpenDelete);
                }}
              >
                ×
              </div>

              <p style={{ textAlign: "center" }}>
                {" "}
                Are you sure you want to delete this card?
              </p>
              {deletePaypalCardLoader && (
                <div className="spinner_div">
                  <Spinner />
                </div>
              )}
              <div className="justify-content-center row">
                <div className="btn_wrap">
                  <button
                    className="btn no-btn-dt no_delete_btn"
                    onClick={(e) => {
                      setPaypalOpenDelete(!isPaypalOpenDelete);
                    }}
                  >
                    No
                  </button>
                  <button
                    className="btn yes-btn-dt yes_delete_btn"
                    onClick={(e) => {
                      handleDeletePaypalCard(DeletePaypalId);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Stripe modal */}
      {isOpenPaypalCard && (
        <AddPayPalCardForm
          showModel={isOpenPaypalCard}
          setShowModel={handleCloseModel}
          hideModel={false}
          customer={userData?.customerInfo?.customer_data}
          isUseTempUser={false}
        />
      )}
      {/*Subscription detail component*/}
      <div className="order_colm billing_sec" id="subscription-details">
        <div className="top_section">
          <h1 style={{ color: "#596f5a", fontWeight: "700" }}>
            Subscription Details
          </h1>
        </div>
        <div className="bottom_layout white">
          {!hideSync &&
            (subscription?.user_info?.is_app_installed ? (
              <>
                {subscription ? (
                  <>
                    <div>
                      <h5 style={{ color: "#596f5a" }}>
                        {" "}
                        Subscription Information
                      </h5>
                      <div className="row">
                        <div className="col-3" style={{ color: "#596f5a" }}>
                          {subscription.plan_info.is_free
                            ? "Free Plan"
                            : "Platinum Plan"}{" "}
                          : {subscription.plan_info_updated.name}
                        </div>
                        <div
                          className="col-1"
                          style={{ color: "#596f5a" }}
                        >{`$${subscription.plan_info.price}`}</div>
                        <div className="col-8 plan ">
                          <div className="d-flex flex-wrap justify-content-end gap-1">
                            {subscription &&
                              subscription.plan_info.is_free === 0 && (
                                <input
                                  type="button"
                                  value="Cancel"
                                  onClick={(event) => {
                                    onCancelSubscription();
                                  }}
                                />
                              )}
                            <input
                              type="button"
                              value="Manage subscription"
                              onClick={(event) => {
                                onChangeSubscription();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <div>
                      <h5 style={{ color: "#596f5a" }}>
                        {" "}
                        Subscription Information
                      </h5>
                      <div className="row">
                        <div
                          className="col-8"
                          style={{ color: "#596f5a" }}
                        >{`You are not subscribed to any plan.`}</div>
                        <div className="col-4 plan ">
                          <div className="d-flex flex-wrap justify-content-end">
                            <input
                              type="button"
                              value="Subscribe"
                              onClick={(event) => {
                                onChangeSubscription();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <h5 style={{ color: "#596f5a" }}>
                  In order to subscribe you must install our app
                </h5>
                <div className="button_session mt-5">
                  <a href={``} onClick={handleClickInstallAPP}>
                    Install App
                  </a>
                </div>
              </>
            ))}
        </div>
      </div>
      {/*  Subscription Detail component end */}
      {/*Billing detail component*/}
      <div className="order_colm billing_sec">
        <div className="top_section">
          <h1 style={{ color: "#596f5a", fontWeight: "700" }}>
            Billing Details
          </h1>
        </div>
        <div className="bottom_layout white">
          {autopayLoader && (
            <div className="spinner_div">
              <Spinner />
            </div>
          )}
          <h5 style={{ color: "#596f5a" }}> Card Information</h5>
          <div className="autopay_container_wrap">
            <div className="autop_pay_cont">
              <label className="autopay_ct" style={{ color: "#596f5a" }}>
                <input
                  value={""}
                  type="checkbox"
                  name="autopay_lb"
                  checked={paypalAutopay}
                  className="autopay_checkbox"
                  onChange={() => handlePaypalAutopay(form.id)}
                />{" "}
                Enable Autopay
              </label>
            </div>
          </div>
          <div className="account_detail billing_form">
            <div className="row">
              <div className="col-12">
                <div className="table_session">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name on Card</th>
                        <th>Expire Date</th>
                        <th>Card Info</th>
                        <th>Default card</th>
                        <th>Action</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {cardLoader ? (
                        <tr>
                          <td>
                            <div className="spinner_div">
                              <Spinner />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {!!paypalCardData &&
                          paypalCardData != null &&
                          paypalCardData.length > 0 ? (
                            paypalCardData.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.card_name}</td>
                                  <td>
                                    {item.card_expiry_month} /{" "}
                                    {item.card_expiry_year}
                                  </td>
                                  <td>
                                    {"**** **** **** "}
                                    {item.card_number}
                                  </td>
                                  <td>
                                    <div className="img_bx">
                                      <input
                                        type="radio"
                                        name="ccard"
                                        className="form-check-input"
                                        value={item?._id}
                                        onClick={handlePayPalCardChange}
                                        checked={paypalChooseCard == item?._id}
                                      />
                                    </div>
                                  </td>
                                  <td className="icon_view">
                                    <p>
                                      <a
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          deletePaypalCardAlert(item?._id);
                                        }}
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    </p>{" "}
                                  </td>
                                  <td></td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <p>No cards found. Please add a new card</p>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="button_session">
                  <a
                    className="go_to_save_card"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpenPaypalCard(!isOpenPaypalCard);
                    }}
                  >
                    Add Card
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Billing Detail component end */}
      <AccountReturnLabel />
      {/* Stripe modal */}
      {isOpencard && (
        <div className="delete_popup" style={{}}>
          <div className="popup_wrap">
            <div className="content">
              <div
                className="popupCloseButton"
                onClick={(e) => {
                  setOpencard(!isOpencard);
                }}
              >
                ×
              </div>

              <h2 style={{ textAlign: "left" }}>Add card</h2>
              <hr />
              <div className="justify-content-center row">
                <div className="col-md-12">
                  <Elements stripe={promise}>
                    <AddCardForm
                      onClick={handleToken}
                      // secret={returnClientSecrete}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End Stripe modal */}
    </>
  );
};

export default Billing;
