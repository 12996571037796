import axios from "axios";

export async function getHeaders() {
  let userData = await localStorage.getItem("userData");
  console.log(userData);
  if (userData) {
    userData = JSON.parse(userData);
    return {
      authorization: `Bearer ${userData.token}`,
    };
  }
  return {};
}

export function setUserData(data) {
  data = JSON.stringify(data);
  return localStorage.setItem("userData", data);
}

export function setTempUserData(data) {
  data = JSON.stringify(data);
  return localStorage.setItem("tempUserData", data);
}

export async function setItem(key, data) {
  data = JSON.stringify(data);
  return localStorage.setItem(key, data);
}

export function getItem(key) {
  var item = localStorage.getItem(key);
  return JSON.parse(item);
}

export function removeItem(key) {
  return localStorage.removeItem(key);
}

export function clearAsyncStorate(key) {
  return localStorage.clear();
}

export function getUserData() {
  var userData = localStorage.getItem("userData");
  return JSON.parse(userData);
}

export function getTempUserData() {
  var userData = localStorage.getItem("tempUserData");
  return JSON.parse(userData);
}

export async function clearUserData() {
  return localStorage.removeItem("userData");
}
export async function clearFcmToken() {
  return localStorage.removeItem("fcmToken");
}

export async function apiReq(
  endPoint,
  data,
  method,
  headers = {},
  requestOptions = {}
) {
  return new Promise(async (res, rej) => {
    try {
      if (data instanceof FormData) {
        headers = {
          ...headers,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        console.log("Form Data");
      } else {
        headers = {
          ...headers,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }

      if (["get", "delete"].includes(method.toLowerCase())) {
        requestOptions = { ...requestOptions, headers, params: data };
      } else {
        requestOptions = { ...requestOptions, headers };
      }

      const response = await axios[method](endPoint, method.toLowerCase() === 'get' || method.toLowerCase() === 'delete' ? requestOptions : data, requestOptions);
      const responseData = response.data;

      if (responseData.status === false) {
        return rej(responseData);
      }

      return res(responseData);
    } catch (error) {
      console.error("API Request Error:", error);

      const { response } = error;
      if (response) {
        switch (response.status) {
          case 500:
            return rej({ message: "Internal Server Error", msg: "Server Error" });
          case 400:
            return rej({ message: response.data.message, msg: response.data.message });
          case 403:
            console.log(response.data.message);
            clearUserData();
            return rej({ message: response.data.message, msg: response.data.message });
          default:
            if (response.data && response.data.msg) {
              return rej(response.data);
            }
            break;
        }
      }

      if (error.message.startsWith("Converting circular structure to JSON")) {
        return;
      }

      return rej({ message: "Something went wrong", msg: "Something went wrong" });
    }
  });
}

export function apiPost(endPoint, data, headers = {}) {
  return apiReq(endPoint, data, "post", headers);
}

export function apiDelete(endPoint, data, headers = {}) {
  return apiReq(endPoint, data, "delete", headers);
}

export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "get", headers, requestOptions);
}

export function apiPut(endPoint, data, headers = {}) {
  return apiReq(endPoint, data, "put", headers);
}

export function randomString(len = 5) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
// export const getCurrentDateTime = () => {
//     return moment().format('L') + " " + moment().format('LT')
// }

export const removeDublicateFrds = (friendList) => {
  friendList.forEach((data_outer, i) => {
    let count = 0;
    friendList.forEach((data_inner, j) => {
      if (data_inner.user_id == data_outer.user_id) {
        count += 1;
        if (count > 1) {
          friendList.splice(j, 1);
        }
      }
    });
  });
  return friendList;
};

function getWeekDays(locale) {
  const baseDate = new Date(Date.UTC(2019, 8, 30)); // just a Monday

  const weekDays = [];
  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: "long" }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
}

export function subscriptionPlanTransformData(apiData) {
  const transformedData = [];

  const nameMapping = {
      "1 Platinum Launch": "Shoes + Accessories",
      "2 Platinum Launches": "Denim + shoes",
      "3 Platinum Launches": [
          "All Recent Launches + High Quantity",
          "All Products"
      ]
  };

  const priceMapping = {
      0: "Free Plan",
      49: "Shoes + Accessories",
      149: "Denim + shoes",
      299: [
          "All Recent Launches + High Quantity",
          "All Products"
      ]
  };

  apiData.forEach((item, index) => {
      const newItem = { ...item, id: index, plan_id: item.id, img: `assets/images/plan-${index}.png` };

      if (nameMapping[item.name]) {
          const mappedNames = nameMapping[item.name];
          if (Array.isArray(mappedNames)) {
              mappedNames.forEach((name, i) => {
                  transformedData.push({ ...newItem, id: index+i,name: name, img: `assets/images/plan-${index+i}.png` });
              });
          } else {
              transformedData.push({ ...newItem, name: mappedNames });
          }
      } else if (priceMapping[item.price]) {
          const mappedNames = priceMapping[item.price];
          if (Array.isArray(mappedNames)) {
              mappedNames.forEach((name,i) => {
                  transformedData.push({ ...newItem, id: index+i, name: name, img: `assets/images/plan-${index+i}.png` });
              });
          } else {
              transformedData.push({ ...newItem, name: mappedNames });
          }
      }
  });

  return transformedData;
}

export const PhoneNumberInputRegex = /^\+1 \d{3}-\d{3}-\d{4}$/;

export const formatPhoneNumberInput = (value = "") => {
  const digits = value.replace(/\D/g, '');
  let cleanedDigits = digits.startsWith('1') ? digits.slice(1) : digits;
  let formattedNumber = '';
  if (cleanedDigits.length === 0) {
    formattedNumber = ``;
  } else if (cleanedDigits.length <= 3) {
    formattedNumber = cleanedDigits;
  } else if (cleanedDigits.length <= 6) {
    formattedNumber = `${cleanedDigits.slice(0, 3)}-${cleanedDigits.slice(3)}`;
  } else {
    formattedNumber = `${cleanedDigits.slice(0, 3)}-${cleanedDigits.slice(3, 6)}-${cleanedDigits.slice(6, 10)}`;
  }
  const formattedValue = `+1 ${formattedNumber}`;
  return formattedValue;
};

export const formatPhoneNumberSubmit = (value = "") => {
  const digits = value.replace(/\D/g, '');
  let cleanedDigits = digits.startsWith('1') ? digits.slice(1) : digits;
  const formattedNumber = `${cleanedDigits.slice(0, 3)} ${cleanedDigits.slice(3, 6)} ${cleanedDigits.slice(6, 10)}`;
  return formattedNumber;
};