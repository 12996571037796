import React, { useEffect, useState, useRef } from "react";
import Logout from "../components/Logout";
import NavLinks from "../components/Nav";
import { getUserData } from "../components/utils";
import { AnalyticsInfo, GraphInfo } from "../components/auth";
import Spinner from "../components/spinner/Spinner";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRangePicker } from "react-date-range";
import {
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges,
} from "react-date-range";
import moment from "moment";
import { addDays } from "date-fns";
import Moment from "react-moment";
import {
  dateFormatReturn,
  useOutsideAlerter,
} from "../components/CommonFunction";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const Analytics = () => {
  const [orderLoader, setOrderLoader] = useState(true);
  const [analyticsInfo, setAnalyticsInfo] = useState("");

  var getCustomerId = getUserData(); //getting customer id from local storage

  var customerEmail =
    !!getCustomerId && getCustomerId
      ? getCustomerId?.customerInfo?.customer_data?.email
      : "";

  // Fetch all Order here
  const getAnalyticsInfo = () => {
    var AllAnalyticsInfo = {
      email: customerEmail,
    };

    AnalyticsInfo(AllAnalyticsInfo)
      .then((res) => {
        setOrderLoader(false);
        if (res.status == 200) {
          // user Object
          setAnalyticsInfo(res.data);
        } else {
          setOrderLoader(false);
        }
      })
      .catch((err) => {
        setOrderLoader(false);
      });
  };

  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth();
  var day = d.getDate();
  var lastYear = new Date(year - 1, month, day);
  var currentYearFirstDate = new Date(new Date().getFullYear(), 0, 1);

  const [dateState, setDateState] = useState([
    {
      startDate: currentYearFirstDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Opening date range picker
  const [openDatePicker, setOpendatePicker] = useState(false);
  const [graphLoader, setGraphLoader] = useState(false);
  const [graphInfo, setGraphInfo] = useState([]);
  const [orderGraph, setOrderInfo] = useState([]);
  const [shippingGraph, setShippingInfo] = useState([]);
  const handleDateRangeOpen = () => {
    setOpendatePicker(true);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpendatePicker); //Outside click detect function

  // Get graph info here
  const handleGraphInfo = () => {
    var AllGraphInfo = {
      email: customerEmail,
      startDate: dateFormatReturn(dateState[0]?.startDate),
      endDate: dateFormatReturn(dateState[0]?.endDate),
    };
    setGraphLoader(true);
    GraphInfo(AllGraphInfo)
      .then((res) => {
        setGraphLoader(false);
        if (res.status == 200) {
          // user Object
          var salesGraph = res.data.salesGraph;
          var orderGraph = res.data.ordersGraph;
          var shippingGraph = res.data.shippingGraph;

           if (salesGraph.length > 0) {
            // Fill month with 0 , which doesn't have data
            var finalGraph = Array.from(Array(12)).fill(0);
            salesGraph.map((item, i) => {
              finalGraph[parseInt(item._id.month) - 1] = parseInt(
                item.totalSales
              );
            });

            setGraphInfo(finalGraph);
          } else {
            setGraphInfo([]);
          }
          if (orderGraph.length > 0) {
            var orderfinalGraph = Array.from(Array(12)).fill(0);
            orderGraph.map((item, i) => {
              orderfinalGraph[parseInt(item._id.month) - 1] = parseInt(
                item.totalOrders
              );
            });
            setOrderInfo(orderfinalGraph);
          } else {
            setOrderInfo([]);
          }

          // Shipping Graph
          if (shippingGraph.length > 0) {
            var shippingfinalGraph = Array.from(Array(12)).fill(0);
            shippingGraph.map((item, i) => {
              shippingfinalGraph[parseInt(item._id.month) - 1] = parseInt(
                item.totalShipping
              );
            });
            setShippingInfo(shippingfinalGraph);
          } else {
            setShippingInfo([]);
          }
         
         
        } else {
          setGraphLoader(false);
        }
      })
      .catch((err) => {
        setGraphLoader(false);
      });
  };

  useEffect(() => {
    handleGraphInfo();
  }, [dateState]);

  useEffect(() => {
    getAnalyticsInfo();
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Sales",
        data: graphInfo,
        backgroundColor: "rgba(101, 121, 103, 0.5)",
      },
    ],
  };

  const orderData = {
    labels,
    datasets: [
      {
        label: "Orders",
        data: orderGraph,
        backgroundColor: "rgba(101, 121, 103, 0.5)",
      },
    ],
  };
  
   // Shipping graph
   const shippingData = {
    labels,
    datasets: [
      {
        label: "Shipping",
        data: shippingGraph,
        backgroundColor: "rgba(101, 121, 103, 0.5)",
      },
    ],
  };

  // Static ranges
  const staticRanges = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: "This Year",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
      }),
    },
  ]);
  

  return (
    <>
      <section className="bt_batch_overview py-0">
        <div className="container-fluid px-0">
          <div className="row g-0">
            <div className="col-12 d-flex">
              <NavLinks />
              <div className={`right_sidebar ${orderLoader ? "bg_blur" : ""}`}>
                <div className="top_section">
                  <h1>Analytics Overview</h1>
                  <Logout />
                </div>

                <div className="bottom_layout">
                                    {/* Orders garph analitics */}
                                    <div className="row">
                    <div className="col-lg-4 col-md-6" ref={wrapperRef}>
                      <div className="date-colm">
                        <p onClick={handleDateRangeOpen}>
                          <Moment format="YYYY/MM/DD">
                            {dateState[0].startDate}
                          </Moment>{" "}
                          -{" "}
                          <Moment format="YYYY/MM/DD">
                            {dateState[0].endDate}
                          </Moment>
                        </p>
                      </div>

                      {openDatePicker && (
                        <DateRangePicker
                          onChange={(item) => setDateState([item.selection])}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          maxDate={new Date()}
                          ranges={dateState}
                          staticRanges={staticRanges}
                          direction="horizontal"
                        />
                      )}
                    </div>
                  </div>
                  {graphLoader ? (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <div className="analytics_overview_rate">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="open_rate colm_1">
                              <h4>Sales info</h4>
                              <Bar options={options} data={data} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="open_rate colm_2">
                              <h4>Orders info</h4>
                              <Bar options={options} data={orderData} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="open_rate colm_2">
                              <h4>Shipping info</h4>
                              <Bar options={options} data={shippingData} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Orders garph analitics */}

                  <div className="top_section mt-3">
                    <h1>All time stats</h1>
                  </div>
                  {orderLoader ? (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="analytics_overview">
                   
                      <div className="row gx-3 py-3">
                        <div className="col-md-3">
                          <div className="box_colm colm_1">
                            <div className="weekly_sale">
                              <h4>Total Sales (Day)</h4>
                              <p>(Total sales per day)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.salesData?.perDay?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_2">
                            <div className="weekly_sale">
                              <h4>Total Sales (Week)</h4>
                              <p>(Total sales per week)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.salesData?.perWeek?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Total Sales (Month)</h4>
                              <p>(Total sales per month)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.salesData?.perMonth?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Total Sales (Year)</h4>
                              <p>(Total sales per year)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.salesData?.perYear?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Total orders */}
                      <div className="row gx-3">
                        <div className="col-md-3">
                          <div className="box_colm colm_1">
                            <div className="weekly_sale">
                              <h4>Total Orders (Day)</h4>
                              <p>(Total Orders per day)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersData?.perDay}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_2">
                            <div className="weekly_sale">
                              <h4>Total Orders (Week)</h4>
                              <p>(Total Orders per week)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersData?.perWeek}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Total Orders (Month)</h4>
                              <p>(Total Orders per month)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersData?.perMonth}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Total Orders (Year)</h4>
                              <p>(Total Orders per year)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersData?.perYear}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Total orders */}


                      {/* Shipping Orders */}
                      <div className="row gx-3 py-3">
                        <div className="col-md-3">
                          <div className="box_colm colm_1">
                            <div className="weekly_sale">
                              <h4>Total Shipping (Day)</h4>
                              <p>(Total Shipping per day)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.shippingPrice?.perDay?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_2">
                            <div className="weekly_sale">
                              <h4>Total Shipping (Week)</h4>
                              <p>(Total Shipping Per Week)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.shippingPrice?.perWeek?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Total Shipping (Month)</h4>
                              <p>(Total Shipping per month)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.shippingPrice?.perMonth?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Total Shipping (Year)</h4>
                              <p>(Total Shipping per year)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.shippingPrice?.perYear?.toFixed(
                                    2
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Shipping orders */}
                    {/* Total Average */}
                    <div className="row gx-3 py-3">
                        <div className="col-md-3">
                          <div className="box_colm colm_1">
                            <div className="weekly_sale">
                              <h4>Avg. Orders</h4>
                              <p>(Avg. Order value)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersPrice?.avgOrder?.toFixed(
                                    2
                                  ) != null
                                  ? analyticsInfo?.ordersPrice?.avgOrder?.toFixed(
                                      2
                                    )
                                  : "0.00"}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-3">
                          <div className="box_colm colm_2">
                            <div className="weekly_sale">
                              <h4>Max Orders</h4>
                              <p>(Max Order value)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersPrice?.maxOrder}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="box_colm colm_3">
                            <div className="weekly_sale">
                              <h4>Min Orders</h4>
                              <p>(Min Order value)</p>
                              <div className="img_bx">
                                <img
                                  src="assets/images/package.png"
                                  alt="package"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="price_tag">
                              <p>
                                $
                                {!!analyticsInfo &&
                                  analyticsInfo?.ordersPrice?.minOrder}
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>

                      {/* Total Average */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Analytics;
