import React, { useEffect, useMemo, useState, useRef } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/spinner/Spinner";
import { useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";

import "../components/payment/stripe/style.css";
import {
  getCustomerAccountDetail,
  getCustomerAddressDetail,
} from "../components/auth";
import {
  DeleteOrderById,
  GetAllOrders,
  GetDiscountCoupon,
  orderSort,
  removeLineItem,
  searchOrder,
  UpdateOrderItem,
  updateShippingAddress,
  GetMessage,
  CombineOrders,
  addShippingAddress,
  GetFullOrders,
  GetRemainigAmount,
  updateTracking,
} from "../components/auth";
import NavLinks from "../components/Nav";
import {
  getUserData,
  clearUserData,
  setItem,
  getItem,
  removeItem,
} from "../components/utils";
import { allOrders, cartOrders } from "../features/userSlice";
import { API_BASE_URL, COMBINE_ALL_ORDERS_API } from "../components/Api";
import axios from "axios";
import Logout from "../components/Logout";
import { loadStripe } from "@stripe/stripe-js";
import {
  finalremoveDublicateFrds,
  randomString,
  removeDublicateFrds,
} from "../components/CommonFunction";
import Moment from "react-moment";
import { Link } from "react-router-dom";

var pageChange = false;

const Order = () => {
  const dispatch = useDispatch(); //redux
  let navigate = useNavigate();
  const openRegisterFile = useRef();
  const closeCombinePopup = useRef();
  const fetchOrdersForce = useRef();
  const fetchAddressForce = useRef();
  const goBackModal = useRef();
  const goBackAddModal = useRef();

  const [allOrder, setAllOrder] = useState("");
  const [allData, setAllData] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [itemQty, setQty] = useState(1);
  const [ShippingAddress, setShipping] = useState("");
  const [ShippingItem, setShippingItem] = useState("");
  const [message, setMessage] = useState("");
  const [AllOrdersData, setAllOldersData] = useState();

  const [Arr6Value, setArr6Value] = useState();
  const [Arr7Value, setArr7Value] = useState();
  const [couponCode, setCouponCode] = useState("");

  // Loaders
  const [orderLoader, setOrderLoader] = useState(true);
  const [addressLoader, setAddressLoader] = useState(false);
  const [qtyLoader, setQtyLoader] = useState(false);
  const [sortingLoader, setSortingLoader] = useState(false);
  const [paginateLoader, setPaginateLoader] = useState(false);
  const [couponLoader, setCouponLoader] = useState(false);
  const [combineLoader, setCombineLoader] = useState(false);
  const [combineStatus, setCombineStatus] = useState(true);
  const [payStatus, setPayStatus] = useState(true);
  const [removeItemLoader, setRemoveItemLoader] = useState(false);
  const [deleteOrderLoader, setDeleteOrderLoader] = useState(false);
  const [isOpencard, setOpencard] = useState(false);
  const [refrashLoader, setRefrashLoader] = useState(false);
  const [SelectAllCombine, setSelectAllCombine] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [isLiceance, setIsLiceance] = useState(null);
  const [userRemainingAmt, setUserRemainingAmt] = useState(0);
  const [creditStatus, setCreditStatus] = useState(null);
  var getCustomerId = getUserData(); //getting customer id from local storage
  const [populateTracking, setPopulateTracking] = useState(false);
  const [showTrackingCheckBox, setShowTrackingCheckBox] = useState(false);

  var sortData = false;

  var customerId =
    !!getCustomerId && getCustomerId
      ? getCustomerId?.customerInfo?.customer_data?.id
      : "";

  var customerEmail =
    !!getCustomerId && getCustomerId
      ? getCustomerId?.customerInfo?.customer_data?.email
      : "";

  var customerAddress =
    !!getCustomerId && getCustomerId
      ? getCustomerId?.customerInfo?.customerAddresses?.addresses?.container
      : "";

  useEffect(() => {
    getAllOrders();
    GetFullOrder();
    getRemainingAmount();
    getMessage();
    defaultset();
  }, []);

  const inputRef = useRef();
  const inputEditRef = useRef();

  useEffect(() => {
    let autocompleted = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { componentRestrictions: { country: "us" } },
      { types: ["establishment"] }
    );
    // autocomplete.setFields("address_components");

    let autoListners = autocompleted.addListener("place_changed", () => {
      let data = autocompleted.getPlace();
      data.address_components.map((item) => {
        if (
          item.types.includes("postal_code") ||
          item.types.includes("colloquial_area")
        ) {
          return setShippingAdd((prev) => {
            return { ...prev, zip: item.long_name, address1: data?.name };
          });
        }
        if (
          item.types.includes("country") &&
          item.types.includes("political")
        ) {
          // setValue({ value : item.long_name, label : item.long_name })
          setShippingAdd((prev) => {
            return { ...prev, country: item.long_name };
          });
        }
        if (
          item.types.includes("political") &&
          item.types.includes("administrative_area_level_1")
        ) {
          setShippingAdd((prev) => {
            return { ...prev, province: item.long_name };
          });
        }
        if (
          item.types.includes("political") &&
          (item.types.includes("locality") ||
            item.types.includes("sublocality") ||
            item.types.includes("sublocality_level_1"))
        ) {
          setShippingAdd((prev) => {
            return { ...prev, city: item.long_name };
          });
        }
      });
    });
  }, []);
  useEffect(() => {
    let autocompleted = new window.google.maps.places.Autocomplete(
      inputEditRef.current,
      { componentRestrictions: { country: "us" } },
      { types: ["establishment"] }
    );

    let autoListners = autocompleted.addListener("place_changed", () => {
      // console.log("hanle change hanlder", autocompleted.getPlace());
      let data = autocompleted.getPlace();
      data.address_components.map((item) => {
        if (
          item.types.includes("postal_code") ||
          item.types.includes("colloquial_area")
        ) {
          return setUpdateShipping((prev) => {
            return { ...prev, zip: item.long_name, address1: data?.name };
          });
        }
        if (
          item.types.includes("country") &&
          item.types.includes("political")
        ) {
          setUpdateShipping((prev) => {
            return { ...prev, country: item.long_name };
          });
        }
        if (
          item.types.includes("political") &&
          item.types.includes("administrative_area_level_1")
        ) {
          setUpdateShipping((prev) => {
            return { ...prev, province: item.long_name };
          });
        }
        if (
          item.types.includes("political") &&
          (item.types.includes("locality") ||
            item.types.includes("sublocality") ||
            item.types.includes("sublocality_level_1"))
        ) {
          setUpdateShipping((prev) => {
            return { ...prev, city: item.long_name };
          });
        }
      });
    });
  }, []);
  const defaultset = () => {
    getCustomerAccountDetail({
      customerId: customerId,
    }).then((response) => {
      let defaultAddresss = response?.data?.customer?.customerAddress;
      let businessAddress = response?.data?.customer?.businessAddress;
      let liceance = response?.data?.customer?.resale_licence_file;
      console.log("defaultAddresss", defaultAddresss);
      console.log("businessAddress", businessAddress);
      console.log("liceance", liceance);
      setDefaultAddress(defaultAddresss);
      setBusinessAddress(businessAddress);
      setIsLiceance(liceance);
      let obj = {
        ...response?.data.customer.customerAddress,
        firstName: response?.data.customer.firstName,
        lastName: response?.data.customer.lastName,
      };
      delete obj?.first_name;
      delete obj?.last_name;

      setArr6Value(obj);
      // setArr6Value({
      //   ...response?.data.customer.customerAddress,
      //   firstName: response?.data.customer.firstName,
      //   lastName: response?.data.customer.lastName
      // })
    });
  };
  // Fetch all Order here
  const getAllOrders = () => {
    var AllOrderInfo = {
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
      status: "pending",
    };
    // if(AllOrderInfo['customer_id']!== null){
    // setOrderLoader(true);
    setRefrashLoader(true);
    GetAllOrders(AllOrderInfo)
      .then((res) => {
        setOrderLoader(false);
        if (res.status == 200) {
          // user Object
          setAllOrder(res.data.data);
          setAllData(res.data);
          setRefrashLoader(false);
          setSortingLoader(false);

          removeItem("sortingVal");
          removeItem("orderPage");
          removeItem("orderSearch");
          // Store in redux
          dispatch(
            allOrders({
              allOrders: res.data.data,
            })
          );
        } else {
          // console.log("error");

          setSortingLoader(false);
          setOrderLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSortingLoader(false);
        setOrderLoader(false);
      });
  };

  const GetFullOrder = () => {
    // console.log("AllOrderInfo");
    var AllOrderInfo = {
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
      status: "pending",
    };

    GetFullOrders(AllOrderInfo)
      .then((res) => {
        // console.log("res".res);
        if (res.status === 200) {
          setAllOldersData(res.data);
        } else {
          // console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Fetch all Order here
  const autoRefreshAllOrders = async () => {
    // if (sort.status) {
    try {
      var localSorting = getItem("sortingVal");
      var localPagination = getItem("orderPage");
      var localOrderSearch = getItem("orderSearch");
      var localOrderSearch2 = getItem("orderSearch2");

      var AllOrderInfo = {
        customer_id: customerId && customerId.replace(/^\D+/g, ""),
        status: "pending",
      };

      if (localSorting != null && localSorting != "") {
        AllOrderInfo = {
          ...AllOrderInfo,
          sort_by: localSorting.sortBy,
          column: localSorting.columnSortName,
        };
      }
      if (localOrderSearch != null && localOrderSearch != "") {
        AllOrderInfo = {
          ...AllOrderInfo,
          keyword: localOrderSearch,
        };
      }
      if (localOrderSearch2 != null && localOrderSearch2 != "") {
        AllOrderInfo = {
          ...AllOrderInfo,
          keyword2: localOrderSearch2,
        };
      }

      if (localPagination != null && localPagination != "") {
        var page = localPagination;
      } else {
        var page = "page=1";
      }

      const {
        data: { data, message, status },
      } = await axios.post(API_BASE_URL + "orders?" + page, AllOrderInfo, {
        headers: {
          Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`,
        },
      });
      if (status == 200) {
        setOrderLoader(false);
        setSortingLoader(false);
        // user Object
        setAllOrder(data.data);
        setAllData(data);

        // Store in redux
        dispatch(
          allOrders({
            allOrders: data.data,
          })
        );
      } else {
        // console.log("Someting went wrong, please try again.");
      }

      // setOrderLoader(true);
      // GetAllOrders(AllOrderInfo)
      // .then((res) => {
    } catch (error) {
      console.log(error, "error");
    }
  };

  // -------------------Auto Refresh---------------
  useEffect(() => {
    // autoRefreshAllOrders();
    const interval = setInterval(() => {
      GetFullOrder();
      autoRefreshAllOrders();
      getRemainingAmount();
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  // -------------------Auto Refresh---------------

  // ---------------get admin setting message-------------
  const getMessage = () => {
    GetMessage()
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setMessage(res.data.description);
        } else {
          // toast.error(res.message);
          // console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRemainingAmount = () => {
    let obj = {
      CustomerId: customerId,
    };
    GetRemainigAmount(obj)
      .then((res) => {
        // console.log("get credit stasussus---", res)
        setUserRemainingAmt(res?.RemainingAmount);
        setCreditStatus(res?.creditStatus);
      })
      .catch((err) => {
        console.log("");
        // toast.error("Failed to fetch remaining amount")
      });
  };

  // const Echoo = new Echo({
  //   broadcaster: "pusher",
  //   key: "myKey",
  //   cluster: "mt1",
  //   wsHost: "btree-backend.test",
  //   wsPort: 6001,
  //   forceTLS: false,
  //   disableStats: true,
  // });

  // window.Pusher = Pusher;

  // Echoo.join(`chat`)
  //   .here((users) => {
  //     console.log(users);
  //   })
  //   .joining((user) => {
  //     console.log(user.name);
  //   })
  //   .leaving((user) => {
  //     console.log(user.name);
  //   })
  //   .error((error) => {
  //     console.error(error);
  //   });

  //  Delete order
  const handleDelete = (orderId) => {
    var orderObj = {
      order_id: orderId,
    };
    setDeleteOrderLoader(true);
    DeleteOrderById(orderObj)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setTimeout(() => {
            setDeleteOrderLoader(false);
            getAllOrders();
            setOpenDelete(false);
            toast.success(res.message);
          }, 3000);
        } else {
          setDeleteOrderLoader(false);
          setOpenDelete(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setDeleteOrderLoader(false);
        setOpenDelete(false);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  function isNegative(num) {
    if (Math.sign(num) === -1) {
      return true;
    }

    return false;
  }

  const [finalQuantity, setFinalQuantity] = useState("");
  const [Mine, setMine] = useState({
    btnName: "",
    applyBtn: true,
  });
  const [applyItemLoader, setApplyItemLoader] = useState(true);

  const [loopQty, setLoopQty] = useState("");

  // calculating quantity here
  const valueCal = (e, ordeQTY, setQty) => {
    setLoopQty(setQty);
    // case = 4 , 4
    var final_Quantity = "";
    setMine({ btnName: e.target.name });
    if (ordeQTY && setQty != "") {
      setMine((prevState) => {
        return { ...prevState, applyBtn: false };
      });

      if (isNegative(setQty)) {
        //  case = 4, -6
        if (ordeQTY <= setQty.toString().slice(1)) {
          toast.warning("Entered quanaity is greater than current quantity");
          setApplyItemLoader(true);
          setMine((prevState) => {
            return { ...prevState, applyBtn: true };
          });
          return false;
        } else {
          setApplyItemLoader(false);
          final_Quantity = ordeQTY - setQty.toString().slice(1);
          setFinalQuantity(final_Quantity);
        }
      } else {
        // case add = 4 , 4
        setApplyItemLoader(false);
        final_Quantity = parseInt(ordeQTY) + parseInt(setQty);
        setFinalQuantity(final_Quantity);
      }
    } else {
      setMine((prevState) => {
        return { ...prevState, applyBtn: true };
      });
    }
  };

  const setQuantity = (cart, ordeItem) => {
    // getting cart item here and increating/ decreasing the QTY in object
    if (
      finalQuantity == "" ||
      finalQuantity == null ||
      finalQuantity == undefined ||
      Number.isNaN(finalQuantity)
    ) {
      toast.warning("Please check your input value");
      return false;
    }
    const newCart = [...cart?.lineItems?.container].map((item) =>
      item.id === ordeItem.id
        ? {
            ...item,
            currentQuantity: finalQuantity,
          }
        : item
    );

    // made the accepratable new array of object
    var final = { container: newCart };

    // Pushed the new array of object in allOrder as per order id
    const final_array = allOrder.map((v) =>
      v.orderId == cart.orderId
        ? {
            ...v,
            lineItems: final,
          }
        : v
    );

    // Set the value again on new order here
    setAllOrder(final_array);

    // Order update api HIT
    var orderItemObj = {
      order_id: cart?.orderId?.replace(/^\D+/g, ""),
      line_item_id: ordeItem?.id?.replace(/^\D+/g, ""),
      quantity: finalQuantity,
    };

    setQtyLoader(true);
    UpdateOrderItem(orderItemObj)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setMine({ applyBtn: false, btnName: "" });
          setFinalQuantity("");
          setTimeout(() => {
            // fetchOrdersForce.current.click();
            setQtyLoader(false);
            toast.success(res.message);
            setChekcedState([]);
            setSelectAllCombine(false);
          }, 6000);
          // console.log(res,"Order Updated successfully");
        } else {
          setQtyLoader(false);
          toast.error(res.message);
          // console.log("error");
        }
      })
      .catch((err) => {
        setQtyLoader(false);
        toast.error(err.message);
        console.log(err);
      });
  };

  const lineItemHTML = (item, i) => {
    return (
      <>
        <div
          className="modal fade"
          id={`orderDetails-${i}`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Order Details
                  <br />
                  <span>
                    Create At:{" "}
                    <Moment format="Do MMM YYYY h:mm:ss a z">
                      {item.createdAt}
                    </Moment>
                  </span>
                </h5>
                <div className="right_side_btn">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setLoopQty("");
                      setMine({ btnName: "" });
                    }}
                  />
                </div>
              </div>
              <div className="modal-body">
                <div className="product-order_title">
                  <h5>Order {item.name}</h5>
                  {/* status */}
                  <span className="cancel_btn">
                    <span
                      className={`${
                        item.financialStatus == "pending" && !item.combineStatus
                          ? "success"
                          : "combine_cls tooltip-custom"
                      }`}
                    >
                      {item.combineStatus ? "Combined" : item.financialStatus}
                      {item?.combineOrderIds?.length > 0 &&
                        item.combineStatus && (
                          <span className="tooltip-text">
                            <ul>
                              {item?.combineOrderIds.map((num, i) => (
                                <li key={i}>{num}</li>
                              ))}
                            </ul>
                          </span>
                        )}
                    </span>
                  </span>
                  {/* status */}
                </div>
                <ul>
                  <li key="2222">
                    <p>
                      {/* {item.customer.container.firstName}{" "}
                      {item.customer.container.lastName} */}
                      {item?.shippingAddr?.container?.firstName}{" "}
                      {item?.shippingAddr?.container?.lastName}
                      <br />
                      <span>
                        {item.shippingAddr?.container?.address1}
                        {", "}
                        {item.shippingAddr?.container?.city}
                        <br />
                        {item.shippingAddr?.container?.zip}
                        {", "}
                        {item.shippingAddr?.container?.phone}
                        {", "}
                        {item.shippingAddr?.container?.country}
                      </span>
                    </p>
                  </li>
                  <li key="3333">
                    <a
                      data-bs-target={`#editShipping`}
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={(e) => onEditShippingClick(item, i)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
                {qtyLoader ? (
                  <div className="spinner_div">
                    <Spinner />
                  </div>
                ) : (
                  ""
                )}
                <div className="table popup_table">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Quantity</th>
                        <th>Update Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* line item print here */}
                      {item?.lineItems?.container.map(
                        (ordeItem, i) =>
                          ordeItem.currentQuantity > 0 && (
                            <tr className="white_layout" key={i}>
                              <td className="line_item_title">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    checkLineItem.findIndex(
                                      (item) =>
                                        item.line_item_id ===
                                        ordeItem.id.replace(/^\D+/g, "")
                                    ) !== -1
                                  }
                                  name={`flexRadio${i}`}
                                  id="flexRadioDefault"
                                  onChange={(e) =>
                                    handleLineItemChecked(e, ordeItem)
                                  }
                                />{" "}
                                <b>{ordeItem.name}</b>
                              </td>
                              <td>{ordeItem.currentQuantity}</td>
                              <td>
                                {loopQty == "" ? (
                                  <input
                                    type="text"
                                    name={`quantity${i}`}
                                    className="qty"
                                    onChange={(e) =>
                                      valueCal(
                                        e,
                                        ordeItem.currentQuantity,
                                        e.target.value
                                      )
                                    }
                                    value={""}
                                    onKeyPress={(event) => {
                                      if (!/[0-9-+]/g.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                ) : Mine.btnName != `quantity${i}` ? (
                                  <input
                                    type="text"
                                    name={`quantity${i}`}
                                    className="qty"
                                    onChange={(e) =>
                                      valueCal(
                                        e,
                                        ordeItem.currentQuantity,
                                        e.target.value
                                      )
                                    }
                                    value={""}
                                    onKeyPress={(event) => {
                                      if (!/[0-9-+]/g.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    name={`quantity${i}`}
                                    className="qty"
                                    onChange={(e) =>
                                      valueCal(
                                        e,
                                        ordeItem.currentQuantity,
                                        e.target.value
                                      )
                                    }
                                    value={loopQty}
                                    onKeyPress={(event) => {
                                      if (!/[0-9-+]/g.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                )}

                                {Mine?.btnName == "" ? (
                                  <button
                                    className="btn btn-warning btn-sm"
                                    onClick={(e) => setQuantity(item, ordeItem)}
                                    disabled={true}
                                  >
                                    Apply
                                  </button>
                                ) : Mine.btnName != `quantity${i}` ? (
                                  <button
                                    className="btn btn-warning btn-sm"
                                    onClick={(e) => setQuantity(item, ordeItem)}
                                    disabled={true}
                                  >
                                    Apply
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-warning btn-sm"
                                    onClick={(e) => setQuantity(item, ordeItem)}
                                    disabled={
                                      Mine.applyBtn &&
                                      Mine.btnName == `quantity${i}`
                                    }
                                  >
                                    Apply
                                  </button>
                                )}
                              </td>
                              {/* <td className="variant_quantity">
                                <input
                                  type="button"
                                  className="qtyminus"
                                  value="-"
                                  onClick={(e) =>
                                    setQuantity(
                                      item,
                                      ordeItem,
                                      parseInt(
                                        valueCal(ordeItem.currentQuantity, -1)
                                      ),
                                      ordeItem.currentQuantity
                                    )
                                  }
                                />
                                <input
                                  type="text"
                                  disabled
                                  name="quantity"
                                  className="qty"
                                  value={ordeItem.currentQuantity}
                                />
                                <input
                                  type="button"
                                  value="+"
                                  className="qtyplus"
                                  onClick={(e) =>
                                    setQuantity(
                                      item,
                                      ordeItem,
                                      parseInt(
                                        valueCal(ordeItem.currentQuantity, +1)
                                      ),
                                      ordeItem.currentQuantity
                                    )
                                  }
                                />
                              </td> */}
                              <td>
                                $
                                {(
                                  ordeItem.originalUnitPrice *
                                    ordeItem.currentQuantity -
                                  ordeItem.discountedTotal
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )
                      )}
                      <tr className="white_layout">
                        <td>
                          {!!checkLineItem && checkLineItem.length > 0 ? (
                            <button
                              className="remove_btn"
                              onClick={(e) => handleRemoveLineItem(e)}
                              disabled={removeItemLoader}
                            >
                              Remove Item
                            </button>
                          ) : (
                            <button
                              disabled="disable"
                              className="remove_btn remove_variant_item_alert disabled_btn"
                            >
                              Remove Item
                            </button>
                          )}
                          {removeItemLoader && (
                            <div className="spinner_div">
                              <Spinner />
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>

                    {/* Refund line item  here*/}
                    {item?.refundLineItems?.container &&
                      item?.refundLineItems?.container.length > 0 && (
                        <>
                          <span className="orderefunds badge-warning text-center ">
                            Cancelled Items
                          </span>
                          <thead>
                            <tr>
                              <th colSpan={2}>Title</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              {/* <th>Status</th> */}
                            </tr>
                          </thead>
                          {/* <tbody> */}
                          {refundItemLoop(item?.refundLineItems?.container)}
                          {/* </tbody> */}
                        </>
                      )}
                    {/* End refund line item */}
                    <tfoot>
                      <tr>
                        <td colSpan={2} style={{ textAlign: "left" }}>
                          Sub Total
                          <br />
                          Shipping Price
                          <br />
                          Total
                        </td>
                        <td>
                          <button
                            className="remove_btn remove_variant_item_alert disabled_btn hide"
                            // ref={fetchOrdersForce}
                            onClick={() => getAllOrders()}
                          >
                            Fetch Orders
                          </button>
                          ${Number(item.subTotalPrice).toFixed(2)}
                          <br />${Number(item.totalShippingPrice).toFixed(2)}
                          <br />${Number(item.totalPrice).toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const combineOrderHTML = () => {
    return (
      <>
        <div
          className="modal fade"
          id={`combineOrderDetails`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="btn hide"
                  ref={fetchAddressForce}
                  onClick={(e) => handleGetCombineAddresses(e)}
                >
                  Fetch address
                </button>

                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Combine Orders
                  </h5>
                  <div>
                    {!!combineAddress && combineAddress != null ? (
                      <button
                        className="combine-btn mb-0"
                        onClick={(e) => handleCombine(e)}
                        disabled={combineLoader}
                      >
                        Combine Order
                      </button>
                    ) : (
                      <button
                        disabled="disable"
                        className="combine-btn mb-0 remove_variant_item_alert disabled_btn"
                      >
                        Combine Order
                      </button>
                    )}
                    {combineLoader && (
                      <div className="spinner_div">
                        <Spinner />
                      </div>
                    )}
                  </div>
                </div>
                <div className="right_side_btn">
                  <button
                    type="button"
                    ref={closeCombinePopup}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
              <div className="modal-body">
                <div className="product-order_title"></div>

                <div className="table popup_table">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>
                          <div className="d-flex justify-align-content-between align-items-center">
                            <div className="w-100">
                              <span>Select Address</span>
                            </div>
                            <div>
                              <a
                                data-bs-target={`#addShipping`}
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                className="add_address_btn"
                              >
                                Add address
                              </a>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {combineAddressLoader ? (
                        <tr>
                          <td>
                            <div className="spinner_div">
                              <Spinner />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {/* selected orders addresses */}
                          {customerAddressData &&
                            customerAddressData.map((item, i) => (
                              <>
                                {item.type == undefined && (
                                  <>
                                    <div className="devider"></div>
                                    <tr className="white_layout" key={i}>
                                      <td>
                                        <div className="label-wrapper">
                                          <span>Selected Address</span>
                                        </div>
                                        <input
                                          className="form-check-input mt-2"
                                          defaultChecked={i == 0}
                                          type="radio"
                                          name={`addressRadio`}
                                          id={`flexRadioDefault${i}`}
                                          onChange={(e) =>
                                            handleCombineAddress(e, item)
                                          }
                                        />{" "}
                                        <b>
                                          {item?.firstName} {item?.lastName},{" "}
                                        </b>
                                        <b>{item?.address1}, </b>
                                        <b>{item.city}, </b>
                                        <b>{item.company}, </b>
                                        <b>{item.province}, </b>
                                        <b>{item.country}, </b>
                                        <b>{item.zip}</b>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </>
                            ))}
                          {/* Default orders addresses */}
                          {customerAddressData &&
                            customerAddressData.map((item, i) => (
                              <>
                                {item.type == "default_address" && (
                                  <>
                                    <div className="devider"></div>
                                    <tr className="white_layout" key={i}>
                                      <td>
                                        <div className="label-wrapper">
                                          <span>Default Address</span>
                                        </div>
                                        <input
                                          className="form-check-input mt-2"
                                          // defaultChecked={i == 0}
                                          type="radio"
                                          name={`addressRadio`}
                                          id={`flexRadioDefault${i}`}
                                          onChange={(e) =>
                                            handleCombineAddress(e, item)
                                          }
                                        />{" "}
                                        <b>
                                          {item?.firstName} {item?.lastName},{" "}
                                        </b>
                                        <b>{item?.address1}, </b>
                                        <b>{item?.city}, </b>
                                        <b>{item?.company}, </b>
                                        <b>{item?.province}, </b>
                                        <b>{item?.country}, </b>
                                        <b>{item?.zip}</b>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </>
                            ))}
                          {/* Custom orders addresses */}
                          {customerAddressData &&
                            customerAddressData.map((item, i) => (
                              <>
                                {item.type == "custom_added" && (
                                  <>
                                    <div className="devider"></div>
                                    <tr className="white_layout" key={i}>
                                      <td>
                                        <div className="label-wrapper">
                                          <span>Custom Added Address</span>
                                        </div>
                                        <input
                                          className="form-check-input mt-2"
                                          defaultChecked={i == 0}
                                          type="radio"
                                          name={`addressRadio`}
                                          id={`flexRadioDefault${i}`}
                                          onChange={(e) =>
                                            handleCombineAddress(e, item)
                                          }
                                        />{" "}
                                        <b>
                                          {item?.firstName} {item?.lastName},{" "}
                                        </b>
                                        <b>{item?.address1}, </b>
                                        <b>{item.city}, </b>
                                        <b>{item.company}, </b>
                                        <b>{item.province}, </b>
                                        <b>{item.country}, </b>
                                        <b>{item.zip}</b>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </>
                            ))}
                        </>
                      )}

                      <tr className="white_layout">
                        <td>
                          {!!combineAddress && combineAddress != null ? (
                            <button
                              className="remove_btn"
                              onClick={(e) => handleCombine(e)}
                              disabled={combineLoader}
                            >
                              Combine Order
                            </button>
                          ) : (
                            <button
                              disabled="disable"
                              className="remove_btn remove_variant_item_alert disabled_btn"
                            >
                              Combine Order
                            </button>
                          )}
                          {combineLoader && (
                            <div className="spinner_div">
                              <Spinner />
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Seeting update shipping state here
  const [updateShipping, setUpdateShipping] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    company: "",
    phone: "",
    country: "",
    zip: "",
    city: "",
    province: "",
  });

  // Add edit address changes
  const [addShippingAdd, setShippingAdd] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    company: "",
    phone: "",
    country: "",
    zip: "",
    city: "",
    province: "",
  });

  const onEditShippingClick = (item, i) => {
    setShipping(item);
    setShippingItem(i);

    var base = item?.shippingAddr?.container;

    var orderId = item?.orderId?.replace(/^\D+/g, "");

    setUpdateShipping({
      firstName: base?.firstName,
      lastName: base?.lastName,
      address1: base?.address1,
      company: base?.company,
      phone: base?.phone,
      country: base?.country,
      zip: base?.zip,
      orderId: orderId,
      city: base?.city,
      province: base?.province,
    });
  };

  // For country selection
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  // ON change country set
  const changeHandler = (value) => {
    setValue(value);
  };

  // Setting edit change form data
  const handleEditChange = (e) => {
    setUpdateShipping({
      ...updateShipping,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "address1") {
      if (e.target.value === "") {
        setUpdateShipping({
          ...updateShipping,
          address1: "",
          address2: "",
          city: "",
          country: "united states",
          province: "",
          zip: "",
        });
      }
    }
  };

  //  updateing/saving shipping address
  const handleShippingSave = (e) => {
    e.preventDefault();
    if (
      updateShipping.firstName == "" ||
      updateShipping.lastName == "" ||
      updateShipping.address1 == "" ||
      updateShipping.city == ""
    ) {
      toast.error("Please fill the all fields");
      return false;
    }
    var updatedShippingInfo = {
      orderId: updateShipping.orderId,
      shippingAddress: {
        first_name: updateShipping.firstName,
        last_name: updateShipping.lastName,
        address1: updateShipping.address1,
        address2: null,
        company: updateShipping.company,
        phone: updateShipping.phone,
        country: updateShipping.country,
        zip: updateShipping.zip,
        city: updateShipping.city,
        province: updateShipping.province,
      },
    };

    setAddressLoader(true);
    updateShippingAddress(updatedShippingInfo)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setTimeout(() => {
            // fetchOrdersForce.current.click();
            setAddressLoader(false);
            toast.success(res.message);
            goBackModal.current.click();
          }, 5000);
        } else {
          toast.error(res?.message?.shipping_address[0]);
          setAddressLoader(false);
          // toast.error(res.message);
        }
      })
      .catch((err) => {
        setAddressLoader(false);
        toast.error(err.message);
      });
  };

  const handleEditAddressChange = (e) => {
    setShippingAdd({
      ...addShippingAdd,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "address1") {
      if (e.target.value === "") {
        setShippingAdd({
          ...addShippingAdd,
          address1: "",
          address2: "",
          city: "",
          country: "united states",
          province: "",
          zip: "",
        });
      }
    }
  };

  //  updateing/saving shipping address for combine orders
  const handleAddShipping = (e) => {
    e.preventDefault();

    if (
      addShippingAdd.firstName == "" ||
      addShippingAdd.lastName == "" ||
      addShippingAdd.address1 == "" ||
      addShippingAdd.city == ""
    ) {
      toast.error("Please fill the all fields");
      return false;
    }
    var updatedShippingInfo = {
      customerId: customerId && customerId.replace(/^\D+/g, ""),
      shippingAddress: {
        firstName: addShippingAdd.firstName,
        lastName: addShippingAdd.lastName,
        address1: addShippingAdd.address1,
        address2: null,
        company: addShippingAdd.company,
        phone: addShippingAdd.phone,
        country: addShippingAdd.country,
        zip: addShippingAdd.zip,
        city: addShippingAdd.city,
        province: addShippingAdd.province,
      },
    };

    setAddressLoader(true);
    addShippingAddress(updatedShippingInfo)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setTimeout(() => {
            // fetchOrdersForce.current.click();
            setAddressLoader(false);
            toast.success(res.message);
            goBackAddModal.current.click();
            fetchAddressForce.current.click();
            setShippingAdd({
              firstName: "",
              lastName: "",
              address1: "",
              company: "",
              phone: "",
              country: "",
              zip: "",
              city: "",
              province: "",
            });
          }, 5000);
        } else {
          toast.error(res?.message);
          setAddressLoader(false);
          // toast.error(res.message);
        }
      })
      .catch((err) => {
        setAddressLoader(false);
        toast.error(err.message);
      });
  };

  //Order delete prompt

  const [isOpenDelete, setOpenDelete] = useState(false);
  const [DeleteId, setDeleteId] = useState("");

  const deleteOrderAlert = (orderId) => {
    setOpenDelete(!isOpenDelete);
    setDeleteId(orderId);
  };
  //Order delete prompt

  //  Shipping modal html
  const editShippingHtml = (item, i) => {
    return (
      <div
        className="modal fade"
        id={`editShipping`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={`-1`}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Order Details
                <br />
                <span>Status</span>
              </h5>
              <div className="right_side_btn">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="shipping_form">
                <div className="product-order_title">
                  <h5>Shipping Address</h5>
                </div>
                <form action="#" method="post">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name*</label>
                        <input
                          type="text"
                          name="firstName"
                          value={updateShipping.firstName}
                          onChange={handleEditChange}
                        />
                        <input type="hidden" name="orderId" value={orderId} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name*</label>
                        <input
                          type="text"
                          name="lastName"
                          value={updateShipping.lastName}
                          onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Address</label>
                        {/* <input
                          type="text"
                          name="address1"
                          value={updateShipping.address1}
                          onChange={handleEditChange}
                        /> */}
                        <input
                          ref={inputEditRef}
                          type="text"
                          name="address1"
                          value={updateShipping.address1}
                          onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Company</label>
                        <input
                          type="text"
                          name="company"
                          value={updateShipping.company}
                          onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="tel"
                          name="phone"
                          value={updateShipping.phone}
                          onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          type="text"
                          name="country"
                          value={updateShipping.country}
                          disabled
                          className="disabled"
                          // onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          name="zip"
                          value={updateShipping.zip}
                          disabled
                          className="disabled"
                          // onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          name="city"
                          value={updateShipping.city}
                          disabled
                          className="disabled"
                          // onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>
                        <input
                          type="text"
                          name="province"
                          value={updateShipping.province}
                          disabled
                          className="disabled"
                          // onChange={handleEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      {addressLoader && (
                        <div className="spinner_div">
                          <Spinner />
                        </div>
                      )}
                      <div className="form-group text-right">
                        <input
                          type="submit"
                          defaultValue="Save"
                          onClick={handleShippingSave}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-bs-target={`#orderDetails-${i}`}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                ref={goBackModal}
              >
                Go back
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //  Shipping modal html
  const addShippingHtml = (item, i) => {
    return (
      <div
        className="modal fade"
        id={`addShipping`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={`-1`}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Order Details
                <br />
                <span>Status</span>
              </h5>
              <div className="right_side_btn">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="shipping_form">
                <div className="product-order_title">
                  <h5>Add Shipping Address</h5>
                </div>
                <form action="#" method="post">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name*</label>
                        <input
                          type="text"
                          name="firstName"
                          value={addShippingAdd.firstName}
                          onChange={handleEditAddressChange}
                        />
                        <input type="hidden" name="orderId" value={orderId} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name*</label>
                        <input
                          type="text"
                          name="lastName"
                          value={addShippingAdd.lastName}
                          onChange={handleEditAddressChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          name="address1"
                          ref={inputRef}
                          value={addShippingAdd.address1}
                          onChange={handleEditAddressChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Company</label>
                        <input
                          type="text"
                          name="company"
                          value={addShippingAdd.company}
                          onChange={handleEditAddressChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="tel"
                          name="phone"
                          value={addShippingAdd.phone}
                          onChange={handleEditAddressChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Country</label>
                        <input
                          type="text"
                          name="country"
                          value={addShippingAdd.country}
                          // onChange={handleEditAddressChange}
                          disabled
                          className="disabled"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          name="zip"
                          value={addShippingAdd.zip}
                          // onChange={handleEditAddressChange}
                          disabled
                          className="disabled"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          name="city"
                          value={addShippingAdd.city}
                          disabled
                          className="disabled"
                          // onChange={handleEditAddressChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>
                        <input
                          type="text"
                          name="province"
                          value={addShippingAdd.province}
                          disabled
                          className="disabled"
                          // onChange={handleEditAddressChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      {addressLoader && (
                        <div className="spinner_div">
                          <Spinner />
                        </div>
                      )}
                      <div className="form-group text-right">
                        <input
                          type="submit"
                          defaultValue="Save"
                          onClick={handleAddShipping}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-bs-target={`#combineOrderDetails`}
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                ref={goBackAddModal}
              >
                Go back
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // setting default address on react select
    var base = ShippingAddress?.shippingAddr?.container;
    var default_country = {
      value: base?.countryCode,
      label: base?.country,
    };

    setValue(default_country);
  }, [ShippingAddress]);

  // Selecting checked order item and pusing in array
  const [checkedState, setChekcedState] = useState([]);
  const [checkedProductIds, setProductIds] = useState([]);
  const [checkedPrice, setCheckedPrice] = useState({
    totalPrice: 0.0,
    totalPriceWithOutShipping: 0.0,
    totalShippingPrice: 0,
  });
  const [discountPrice, setDiscount] = useState("");
  const [payableAmount, setPayableamount] = useState("");
  const [isCheckAll, setIsCheckAll] = useState("");
  const [checkedAddress, setCheckedAddress] = useState([]);

  useEffect(() => {
    // console.log("checkedState ==", checkedState)
    const combinedOrders = checkedState.filter(
      (x) => x.combineStatus && x.combineStatus === true
    );
    if (
      combinedOrders &&
      combinedOrders.length === 1 &&
      checkedState.length === 1
    ) {
      setShowTrackingCheckBox(true);
      setPopulateTracking(
        combinedOrders[0].order_link
          ? combinedOrders[0].order_link.do_not_populate_tracking
          : false
      );
    } else setShowTrackingCheckBox(false);
  }, [checkedState]);

  // Select order checkbox
  const handleChecked = (e, checkedItem) => {
    let newObj = {};
    let newShippingAdd = {};

    if (SelectAllCombine == true) {
      // SelectAllCombine=false ;
    } else {
      if (e.target.checked === true) {
        newObj = Object.assign({ isCheck: true }, checkedItem);
        setChekcedState((checkedState) => [...checkedState, newObj]);
      } else {
        newObj = Object.assign({ isCheck: false }, checkedItem);
        const nextSelectedRows =
          checkedState &&
          checkedState.filter(
            (checkedState) => checkedState.orderId != newObj.orderId
          );
        setChekcedState(nextSelectedRows);
      }

      if (
        checkedState &&
        checkedState.length > 0 &&
        checkedState.filter((item) => item?.isCheck == true).length ==
          allOrder.length - 1
      ) {
        setIsCheckAll(true);
      }
    }
  };

  useEffect(() => {
    setPayableamount(checkedPrice?.totalPrice);
  }, [checkedPrice]);

  const updateTrackingStatus = (status) => {
    const data = {
      order_id:
        checkedState[0].orderId.indexOf("/") > 0
          ? checkedState[0].orderId.substring(
              checkedState[0].orderId.lastIndexOf("/") + 1
            )
          : checkedState[0].orderId,
      do_not_populate_tracking: status,
    };
    updateTracking(data).then((response) => {
      // console.log("response ==", response)
      if (response.status == 200) {
      } else {
      }
    });
  };

  //   useEffect(() => {
  //   SelectAllOrder();
  // }, [])

  // Call coupon function on checkedState change

  // Select all orde at once

  const handleSelectAll = (e) => {
    if (SelectAllCombine === true) {
      // SelectAllCombine=false ;
      // console.log("-----------------------test--------------------");
    } else {
      // console.log(SelectAllCombine);
      setIsCheckAll(!isCheckAll);
      const { checked } = e.target;
      const nextSelectedRows = allOrder.map((item) => {
        return { ...item, isCheck: checked };
      });
      // console.log("all order", AllOrdersData);
      // console.log("next selectedrow", nextSelectedRows);
      setChekcedState(nextSelectedRows);
      // console.log(isCheckAll + "asdas");
      if (isCheckAll) {
        // console.log(isCheckAll, 'sssssssss');
        setChekcedState([]);
        setCheckedPrice({
          totalPrice: 0.0,
          totalPriceWithOutShipping: 0.0,
          totalShippingPrice: 0.0,
        });
      }
    }
  };

  useEffect(() => {
    if (SelectAllCombine) {
      setChekcedState(AllOrdersData);
    } else {
      setChekcedState([]);
    }
  }, [SelectAllCombine]);

  //=================== remove line item all function ===========================================//

  const [checkLineItem, setCheckLineItem] = useState([]);
  const [currentOrderId, setCurrentOrderId] = useState("");
  const handleLineItemChecked = (e, checkedItem) => {
    let newObj = {};

    if (e.target.checked === true) {
      newObj = {
        line_item_id: checkedItem.id.replace(/^\D+/g, ""),
        quantity: checkedItem.currentQuantity,
      };

      setCheckLineItem([...checkLineItem, newObj]);
    } else {
      const removeLineItemRows = checkLineItem.filter(
        (checkedState) =>
          checkedState.line_item_id != checkedItem.id.replace(/^\D+/g, "")
      );
      setCheckLineItem(removeLineItemRows);
    }
  };

  const handleRemoveLineItem = (e) => {
    e.preventDefault();

    var removedLineItem = JSON.stringify({
      order_id: currentOrderId,
      line_items: checkLineItem,
    });
    setRemoveItemLoader(true);
    removeLineItem(removedLineItem)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setCheckLineItem([]);
          // console.log("remoe line items--------------")
          setTimeout(() => {
            // fetchOrdersForce.current.click();
            setRemoveItemLoader(false);

            toast.success(res.message);
            setChekcedState([]);
            setSelectAllCombine(false);
          }, 5000);
        } else {
          setRemoveItemLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setRemoveItemLoader(false);
        toast.error(err.message);
      });
  };

  // ================End line item funciton ==================================//

  const [searchText, setSearchText] = useState("");
  const [searchText2, setSearchText2] = useState("");

  // ================ Search Order ==================================//
  const handleSearch = (e) => {
    // e.preventDefault();
    setSortingLoader(true);
    // console.log("hanlde---earh")

    if (searchText == "" && searchText2 == "") {
      // console.log("hanlde---searh inner")
      removeItem("orderSearch");
      removeItem("orderSearch2");
      setSortingLoader(false);
      // getAllOrders();
      return false;
    }

    // Setting local storage value here
    setItem("orderSearch", searchText);
    setItem("orderSearch2", searchText2);

    var searchInfo = {
      status: "pending",
      keyword: searchText,
      keyword2: searchText2,
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
    };

    searchOrder(searchInfo)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setAllOrder(res.data.data);
          setAllData(res.data);
          setSortingLoader(false);
          // Store in redux
          dispatch(
            allOrders({
              allOrders: res.data.data,
            })
          );
        } else {
          setSortingLoader(false);
          // console.log("error");
        }
      })
      .catch((err) => {
        setSortingLoader(false);
        console.log(err);
      });
  };

  // ================ Sorting Order ==================================//

  const [sort, setSort] = useState({
    sortBy: "desc",
    columnSortName: "name",
    status: false,
  }); // asc or desc

  const handleSorting = (sortType, columnName) => {
    setSort({
      sortBy: sortType,
      columnSortName: columnName,
      status: !sort.status,
    });

    var sortValLocal = {
      sortBy: sortType,
      columnSortName: columnName,
      status: !sort.status,
    };

    // console.log(sortValLocal, "qqqqqqqqqqqqqq");

    // Setting local storage value here
    setItem("sortingVal", sortValLocal);
    var sortInfo = {
      status: "pending",
      sort_by: sortType,
      email: customerEmail,
      column: columnName,
    };
    sortData = true;
    setSortingLoader(true);
    orderSort(sortInfo)
      .then((res) => {
        if (res.status == 200) {
          // user Object
          setAllOrder(res.data.data);
          setAllData(res.data);
          setSortingLoader(false);
          // Store in redux
          dispatch(
            allOrders({
              allOrders: res.data.data,
            })
          );
        } else {
          // console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // -------------Applying coupen here--------------------
  const applyCoupon = () => {
    var couponInfo = {
      customer_id: customerId && customerId.replace(/^\D+/g, ""),
      coupon_code: couponCode,
    };

    if (!(checkedState.length > 0)) {
      toast.error("Please select an order...");
      return false;
    }

    setCouponLoader(true);
    GetDiscountCoupon(couponInfo)
      .then((res) => {
        if (res.status == 200) {
          setCouponLoader(false);
          // user Object
          // console.log(res.message);
          // toast.success('Discount coupon applied successfully');
          discount_Coupon_logic(res.data);
        } else {
          setCouponLoader(false);
          toast.error(res.message);

          setCheckedPrice((prevState) => {
            return {
              ...prevState,
              totalShippingPrice: 0,
            };
          });

          setDiscount("");
          // console.log("payableAmount---14----");
          setPayableamount("");
        }
      })
      .catch((err) => {
        setCouponLoader(false);
        toast.error(err.message);
      });
  };

  // --------------discount_Coupon_logic----------------
  const discount_Coupon_logic = (obj) => {
    // Total price incluing shipping price - checkedPrice
    var dicounttype = obj.value_type;
    var dicountpricett = obj.value;
    dicountpricett = dicountpricett.substring(1);
    var discountprice = 0;

    var total = 0.0;
    var totalLineItemQnt = 0;
    var price = checkedPrice.totalPrice;
    // console.log("dicountpricett---", dicountpricett, obj);
    if (dicountpricett) {
      if (price != 0.0) {
        // Global varibale for minimum quantiy
        var quantityRange =
          obj.prerequisite_quantity_range?.greater_than_or_equal_to;
        // Global variable for minimum product purchase price
        var minimumPurchase =
          obj.prerequisite_subtotal_range?.greater_than_or_equal_to;
        var totalShippingPrice = checkedPrice.totalShippingPrice;
        var totalOrderPrice = checkedPrice.totalPriceWithOutShipping;
        var testPro = 0;
        // If specific product validation
        var entitledProductIds = obj?.entitled_product_ids;
        var productsId = [];

        // Check if this coupon valid for specific customer
        var isCustomer =
          obj.prerequisite_customer_ids.length &&
          obj.prerequisite_customer_ids.filter((obj) => {
            return obj == customerId;
          });
        var expery = new Date(obj.ends_at);
        var now = new Date(Date.now());
        if (
          obj.prerequisite_customer_ids.length &&
          isCustomer.length &&
          isCustomer != customerId
        ) {
          toast.error("This coupon is not valid.");
          total = checkedPrice.totalPrice;
        } else if (obj.ends_at != null && expery < now) {
          toast.error("This coupon is expired.");
          total = checkedPrice.totalPrice;
        } else {
          // *********************Get selected product id*****************************************//
          if (entitledProductIds.length > 0) {
            // Product ids  getting here
            checkedState.map((item, index) => {
              item?.lineItems?.container.map((line, i) => {
                if (line.currentQuantity > 0) {
                  var line_Item_Object = {
                    id: line.product.id.replace(/^\D+/g, ""),
                    price:
                      line.originalUnitPrice * parseInt(line.currentQuantity),
                    quantity: line.currentQuantity,
                  };
                  productsId.push(line_Item_Object);
                }
              });
            });
            // End product ids here
          }

          //  Set total line item quantity
          var finalQTYArray = [];

          checkedState.map((item, index) => {
            item?.lineItems?.container.map((line, i) => {
              var line_Item_Object = {
                orderId: item.orderId,
                price: line.originalUnitPrice * parseInt(line.currentQuantity),
                quantity: line.currentQuantity,
              };

              finalQTYArray.push(line_Item_Object);
            });
          });

          // making new object of qty with price
          let result = Object.values(
            finalQTYArray.reduce((c, { orderId, price, quantity }) => {
              c[orderId] = c[orderId] || { orderId, price: 0, quantity: 0 };
              c[orderId].price += price;
              c[orderId].quantity += quantity;
              return c;
            }, {})
          );

          // Sum all the quantity here
          totalLineItemQnt = [
            result.reduce((obj, n) => {
              for (var prop in n) {
                if (obj.hasOwnProperty(prop)) obj[prop] += n[prop];
                else obj[prop] = n[prop];
              }
              return obj;
            }, {}),
          ];

          // console.log(totalLineItemQnt[0].quantity,'aaaaaaaaa..........');
          // Ending with total line item quantity

          //***************(Amount off order)**************//
          if (
            obj?.target_type == "line_item" &&
            obj?.target_selection == "all"
          ) {
            // console.log("Amount off order");

            if (
              typeof minimumPurchase != "undefined" &&
              minimumPurchase != "" &&
              minimumPurchase != null &&
              !(parseFloat(price) >= parseFloat(minimumPurchase))
            ) {
              toast.warning(
                "Products price must be greater than $" +
                  parseFloat(minimumPurchase).toFixed(2)
              );
              total = price;
              // Print common HTML
              // commonCouponHtml(total);
              setPayableamount(total);
            } else if (
              quantityRange != "" &&
              quantityRange != null &&
              !(totalLineItemQnt[0].quantity >= quantityRange)
            ) {
              toast.warning(
                "Products quantity must be greater than " + quantityRange
              );
              total = price;
              // Print common HTML
              // commonCouponHtml(total);
              setPayableamount(total);
            } else {
              if (parseFloat(price) > parseFloat(dicountpricett)) {
                if (dicounttype == "percentage") {
                  discountprice =
                    (parseFloat(totalOrderPrice) * parseInt(dicountpricett)) /
                    100;
                } else {
                  discountprice = dicountpricett;
                }
                total = parseFloat(totalOrderPrice) - parseFloat(discountprice);
                total = parseFloat(total) + parseFloat(totalShippingPrice);
                total = parseFloat(total).toFixed(2);
                discountprice = parseFloat(discountprice).toFixed(2);
                setPayableamount(total);
              } else {
                toast.warning("Order price is less then discount price.");
                total = price;
                // Print common HTML
                // commonCouponHtml(total);
                setPayableamount(total);
              }
            }
          }
          //***************(Amount off product)***************//
          else if (
            obj?.target_type == "line_item" &&
            obj?.target_selection == "entitled"
          ) {
            // console.log("Amount off product");
            if (
              typeof minimumPurchase != "undefined" &&
              minimumPurchase != "" &&
              minimumPurchase != null &&
              !(parseFloat(totalOrderPrice) >= parseFloat(minimumPurchase))
            ) {
              toast.warning(
                "Products price must be greater than $" +
                  parseFloat(minimumPurchase).toFixed(2)
              );
              total = price;
              // Print common HTML
              // commonCouponHtml(total);
              setPayableamount(total);
            } else if (
              quantityRange != "" &&
              quantityRange != null &&
              !(totalLineItemQnt[0].quantity >= quantityRange)
            ) {
              toast.warning(
                "Products quantity must be greater than " + quantityRange
              );
              total = price;
              // Print common HTML
              // commonCouponHtml(total);
              setPayableamount(total);
            } else {
              if (parseFloat(price) > parseFloat(dicountpricett)) {
                // if (dicounttype == "percentage") {
                //   discountprice =
                //     (parseFloat(totalOrderPrice) * parseInt(dicountpricett)) /
                //     100;
                // } else {
                //   discountprice = dicountpricett;
                // }

                if (entitledProductIds.length > 0) {
                  var filtered = [];
                  var filterProPrice = 0.0;
                  var filterProQuantity = 0;
                  var notFilterProPrice = 0.0;
                  var finalProPrice = 0.0;
                  for (var i = productsId.length - 1; i >= 0; --i) {
                    if (
                      entitledProductIds.indexOf(parseInt(productsId[i].id)) !=
                      -1
                    ) {
                      filterProPrice += parseFloat(productsId[i].price);
                      filterProQuantity += parseFloat(productsId[i].quantity);
                    } else {
                      notFilterProPrice += parseFloat(productsId[i].price);
                    }
                  }

                  if (dicounttype == "percentage") {
                    discountprice =
                      (parseFloat(filterProPrice) * parseInt(dicountpricett)) /
                      100;
                  } else {
                    discountprice = dicountpricett;
                  }

                  // console.log(filterProPrice, "filterProPrice");
                  // console.log(discountprice, "discountprice");
                  // console.log(totalOrderPrice, "totalOrderPrice");

                  // console.log(filterProPrice, "filterProPrice");

                  if (filterProPrice != 0) {
                    if (
                      typeof minimumPurchase != "undefined" &&
                      minimumPurchase != "" &&
                      minimumPurchase != null &&
                      !(
                        parseFloat(filterProPrice) >=
                        parseFloat(minimumPurchase)
                      )
                    ) {
                      toast.warning(
                        "Products price must be greater than $" +
                          parseFloat(minimumPurchase).toFixed(2)
                      );
                      total = price;
                      // Print common HTML
                      // commonCouponHtml(total);
                      setPayableamount(total);
                      return false;
                    } else if (
                      quantityRange != "" &&
                      quantityRange != null &&
                      !(filterProQuantity >= quantityRange)
                    ) {
                      toast.warning(
                        "Products quantity must be greater than " +
                          quantityRange
                      );
                      total = price;
                      // Print common HTML
                      // commonCouponHtml(total);
                      setPayableamount(total);
                      return false;
                    } else if (
                      !(parseFloat(filterProPrice) > parseFloat(discountprice))
                    ) {
                      toast.warning(
                        "Product price is less then discount price."
                      );
                      total = parseFloat(totalOrderPrice);
                      total = (
                        parseFloat(total) + parseFloat(totalShippingPrice)
                      ).toFixed(2);
                      discountprice = parseFloat(0).toFixed(2);
                      return false;
                    } else {
                      var filterPayablePrice =
                        parseFloat(filterProPrice) - parseFloat(discountprice);
                      finalProPrice = (
                        parseFloat(filterPayablePrice) +
                        parseFloat(notFilterProPrice) +
                        parseFloat(totalShippingPrice)
                      ).toFixed(2);
                      total = finalProPrice;
                      discountprice = parseFloat(discountprice).toFixed(2);
                    }
                    // ---------------------
                    // if (
                    //   parseFloat(filterProPrice) > parseFloat(discountprice)
                    // ) {
                    //   var filterPayablePrice =
                    //     parseFloat(filterProPrice) - parseFloat(discountprice);
                    //   finalProPrice = (
                    //     parseFloat(filterPayablePrice) +
                    //     parseFloat(notFilterProPrice) +
                    //     parseFloat(totalShippingPrice)
                    //   ).toFixed(2);
                    //   total = finalProPrice;
                    //   discountprice = parseFloat(discountprice).toFixed(2);
                    // } else {
                    //   toast.warning(
                    //     "Product price is less then discount price."
                    //   );
                    //   total = parseFloat(totalOrderPrice);
                    //   total = (
                    //     parseFloat(total) + parseFloat(totalShippingPrice)
                    //   ).toFixed(2);
                    //   discountprice = parseFloat(0).toFixed(2);
                    // }
                  } else {
                    total = parseFloat(totalOrderPrice) - parseFloat(0);
                    total = (
                      parseFloat(total) + parseFloat(totalShippingPrice)
                    ).toFixed(2);
                    discountprice = parseFloat(0).toFixed(2);
                  }
                  // console.log(discountprice, "discountprice");
                } else {
                  if (dicounttype == "percentage") {
                    discountprice =
                      (parseFloat(totalOrderPrice) * parseInt(dicountpricett)) /
                      100;
                  } else {
                    discountprice = dicountpricett;
                  }

                  total =
                    parseFloat(totalOrderPrice) - parseFloat(discountprice);
                  total = (
                    parseFloat(total) + parseFloat(totalShippingPrice)
                  ).toFixed(2);
                  discountprice = parseFloat(discountprice).toFixed(2);
                }
              } else {
                toast.error("Order price is less then discount price.");
                total = price;
              }
              setPayableamount(total);
            }
          }
          //*****************(apply coupon on shipping)***********************//
          else if (obj?.target_type == "shipping_line") {
            // console.log("Amount off shipping");

            if (
              typeof minimumPurchase != "undefined" &&
              minimumPurchase != "" &&
              minimumPurchase != null &&
              !(parseFloat(totalOrderPrice) >= parseFloat(minimumPurchase))
            ) {
              toast.warning(
                "Products price must be greater than $" +
                  parseFloat(minimumPurchase).toFixed(2)
              );
              total = price;
              // Print common HTML
              // commonCouponHtml(total);
              setPayableamount(total);
            } else if (
              quantityRange != "" &&
              quantityRange != null &&
              !(totalLineItemQnt[0].quantity >= quantityRange)
            ) {
              toast.warning(
                "Products quantity must be greater than " + quantityRange
              );
              total = price;
              // Print common HTML
              // commonCouponHtml(total);
              setPayableamount(total);
            } else {
              if (dicounttype == "percentage") {
                discountprice =
                  (parseFloat(totalShippingPrice) * parseInt(dicountpricett)) /
                  100;
              } else {
                discountprice = dicountpricett;
              }
              total =
                parseFloat(totalShippingPrice) - parseFloat(discountprice);
              total = (parseFloat(total) + parseFloat(totalOrderPrice)).toFixed(
                2
              );
              discountprice = parseFloat(discountprice).toFixed(2);
              setPayableamount(total);
            }
          } else {
            if (dicounttype == "percentage") {
              discountprice =
                (parseFloat(total) * parseInt(dicountpricett)) / 100;
            } else {
              discountprice = dicountpricett;
            }
            total = parseFloat(total) - parseFloat(discountprice);
            total = parseFloat(total).toFixed(2);
            discountprice = parseFloat(discountprice).toFixed(2);
            setPayableamount(total);
          }
          // setting Discount code here
          // console.log(discountprice,'discountprice');
          setDiscount(discountprice);
        }
      }
    }
  };

  // Calculating the price and returning the html
  const calcPrice = () => {
    // console.log("CheckedState222------", checkedState);
    const priceTotal =
      checkedState &&
      checkedState
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.totalPrice)),
          0
        )
        .toFixed(2);

    const totalPriceNotShipping =
      checkedState &&
      checkedState
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.subTotalPrice)),
          0
        )
        .toFixed(2);

    const totalShipping =
      checkedState &&
      checkedState
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.totalShippingPrice)),
          0
        )
        .toFixed(2);
    // console.log("totalPrice------", priceTotal);

    setCheckedPrice({
      totalPrice: priceTotal,
      totalPriceWithOutShipping: totalPriceNotShipping,
      totalShippingPrice: totalShipping,
    });
  };

  // --------------Pagination------------------
  // Handel pagination customer
  const handlePagination = async (e, url) => {
    // console.log("asd");
    e.preventDefault();
    try {
      // Set page to localstorage
      setItem("orderPage", url.split("?")[1]);
      if (sort.status) {
        var AllOrderInfo = {
          customer_id: customerId && customerId.replace(/^\D+/g, ""),
          status: "pending",
          sort_by: sort.sortBy,
          column: sort.columnSortName,
        };
      } else {
        var AllOrderInfo = {
          customer_id: customerId && customerId.replace(/^\D+/g, ""),
          status: "pending",
        };
      }
      if (url && url != null) {
        setPaginateLoader(true);
        const {
          data: { data, message, status },
        } = await axios.post(
          API_BASE_URL + "orders?" + url.split("?")[1],
          AllOrderInfo,
          {
            headers: {
              Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`,
            },
          }
        );
        if (status == 200) {
          setAllOrder(data.data);
          setAllData(data);
          setPaginateLoader(false);
          // Store in redux
          dispatch(
            allOrders({
              allOrders: data.data,
            })
          );
          window.scrollTo(0, 0);
        }
        pageChange = true;
        // SelectAllOrder();
        calcPrice();
        // setCustomer(data);
      }
    } catch (error) {
      setPaginateLoader(false);
      console.log(error);
    }
  };

  // -----------------handle Combine functionality------------
  const [combineAddress, setCombineAddres] = useState();
  const handleCombineAddress = (e, address) => {
    setCombineAddres(address);
    // console.log("setCombineAddres", address);
  };

  const handleCombine = (e) => {
    e.preventDefault();

    if (combineAddress.id === null || combineAddress.id == undefined) {
      toast.error("please select another address or create a new one");
      return;
    }
    var orderIds = [];
    if (SelectAllCombine === true) {
      AllOrdersData.map((item, index) => {
        orderIds.push(item?.orderId);
      });
      // console.log(orderIds);
    } else {
      checkedState.map((item, index) => {
        if (item?.isCheck == true) orderIds.push(item?.orderId);
      });
    }
    // Getting orders ids
    // checkedState.map((item, index) => {

    delete combineAddress.createdAt;
    delete combineAddress.isPosition;
    delete combineAddress.type;

    let address = {
      ...combineAddress,
      id: combineAddress.id + "?model_name=Address",
    };
    var CombineInfo = {
      email: customerEmail && customerEmail,
      order_ids: orderIds,
      shipping_address: address,
    };

    // console.log(CombineInfo,'CombineInfo......');
    setCombineLoader(true);
    // console.log(CombineInfo);

    CombineOrders(CombineInfo)
      .then((res) => {
        setChekcedState([]);
        if (res.status == 200) {
          setCheckedPrice({
            totalPrice: 0.0,
            totalPriceWithOutShipping: 0.0,
            totalShippingPrice: 0,
          });
          setPayableamount("");
          // user Object
          setTimeout(() => {
            getAllOrders();
            closeCombinePopup.current.click();
            toast.success(res.message);
            setCombineLoader(false);
            setSelectAllCombine(false);
          }, 5000);
        } else {
          // console.log("error");
          setCombineLoader(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setCombineLoader(false);
        console.log(err.message);
        toast.error(err.message);
      });

    // console.log(checkedState, "checkedStatecheckedState");
  };

  // handleGetCombineAddresses
  const [combineAddressLoader, setCombineAddressLoader] = useState(false);
  const [customerAddressData, setCustomerAddressData] = useState([]);

  const handleAutoCombineAddresses = async (e) => {
    if (allOrder.length > 0) {
      var AllOrderInfo = {
        customer_id: customerId && customerId,
      };
      e.preventDefault();
      try {
        setCombineAddressLoader(true);
        const {
          data: { data, message, status },
        } = await axios.post(
          COMBINE_ALL_ORDERS_API,
          {},
          {
            headers: {
              Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`,
            },
          }
        );
        if (status == 200) {
          toast.success(message);
        } else {
          toast.error(message);
        }
        setCombineAddressLoader(false);
      } catch (error) {
        setCombineAddressLoader(false);
        if (error) {
          toast.error("Some error has occured");
        }
        console.log(error);
      }
    }
  };

  const handleGetCombineAddresses = async (e) => {
    if (SelectAllCombine === true) {
      e.preventDefault();
      var AllOrderInfo = {
        customer_id: customerId && customerId,
      };
      // console.log("asdas");
      // console.log(AllOrdersData);

      try {
        // console.log(allOrdersData);
        var selectedShippingAdd = [];
        var newAddObj = {};
        // console.log(allOrdersData);
        AllOrdersData.forEach((value, i) => {
          selectedShippingAdd.push(value.shippingAddr.container);
        });

        setCheckedAddress(removeDublicateFrds(selectedShippingAdd));
        var AllOrderInfo = {
          customer_id: customerId && customerId,
        };
        setCombineAddressLoader(true);
        const {
          data: { data, message, status },
        } = await axios.post(
          API_BASE_URL + "order/combine_address",
          AllOrderInfo,
          {
            headers: {
              Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`,
            },
          }
        );
        if (status == 200) {
          setCombineAddressLoader(false);
          var customAdd =
            data?.customAddresses != undefined ? data?.customAddresses : [];
          var defaultAdd =
            data?.defaultAddr != undefined ? [data?.defaultAddr.container] : [];
          // console.log("default", defaultAdd);
          // console.log("custom", customAdd);
          const newArr2 = selectedShippingAdd.map((v) => ({
            ...v,
            isPosition: "first",
          }));
          const newArr3 = data.addresses.container.map((v) => ({
            ...v,
            isPosition: "second",
          }));
          const newArr4 = customAdd
            .reverse()
            .map((v) => ({ ...v, isPosition: "third" }));
          const newArr5 = defaultAdd.map((v) => ({
            ...v,
            isPosition: "fourth",
          }));

          const newArr7 = [Arr6Value];
          newArr4.forEach(myFunction);

          function myFunction(item, index) {
            newArr4[index]["type"] = "custom_added";
          }

          // newArr3["type"]="ball"
          newArr5[0]["type"] = "default_address";
          // console.log(newArr5);
          var combineAdd = [newArr2, newArr5, newArr4];
          // console.log(combineAdd);
          // console.log("combineAdd");
          var afterCombineAdd = combineAdd.flat();
          var finalAddArr = [];
          finalAddArr = finalremoveDublicateFrds(afterCombineAdd);

          // newArr3["type"]="ball"
          newArr7[0]["type"] = "default_address";
          var combineAdd = [newArr2, newArr7, newArr4];
          console.log(combineAdd);
          var afterCombineAdd = combineAdd.flat();
          var finalAddArr = [];
          finalAddArr = finalremoveDublicateFrds(afterCombineAdd);

          setCombineAddres(finalAddArr[0]);
          setCustomerAddressData(finalAddArr);
          // console.log("finalAddArr", finalAddArr);
          // var mergedAddress = [...checkedAddress, ...data.addresses.container];
          // removeDublicateFrds(afterCombineAdd);
          // console.log(mergedAddress,"mergedAddress");
          // setCustomerAddressData(removeDublicateFrds(afterCombineAdd));
        }
      } catch (error) {
        setCombineAddressLoader(false);
        console.log(error);
      }
    } else {
      // new implementation
      // console.log(checkedState);
      e.preventDefault();

      try {
        var selectedShippingAdd = [];
        var newAddObj = {};
        checkedState.forEach((value, i) => {
          // console.log("selectedavalue", value.shippingAddr.container);

          selectedShippingAdd.push(value.shippingAddr.container);
        });
        setCheckedAddress(removeDublicateFrds(selectedShippingAdd));
        var AllOrderInfo = {
          customer_id: customerId && customerId,
        };
        setCombineAddressLoader(true);
        const {
          data: { data, message, status },
        } = await axios.post(
          API_BASE_URL + "order/combine_address",
          AllOrderInfo,
          {
            headers: {
              Authorization: `Bearer ${getCustomerId.customerAccessToken.accessToken}`,
            },
          }
        );
        if (status == 200) {
          setCombineAddressLoader(false);
          var customAdd =
            data?.customAddresses != undefined ? data?.customAddresses : [];
          var defaultAdd =
            data?.defaultAddr != undefined ? [data?.defaultAddr.container] : [];
          // console.log("default", defaultAdd);
          // console.log("custom", customAdd);
          const newArr2 = selectedShippingAdd.map((v) => ({
            ...v,
            isPosition: "first",
          }));
          const newArr3 = data.addresses.container.map((v) => ({
            ...v,
            isPosition: "second",
          }));
          const newArr4 = customAdd
            .reverse()
            .map((v) => ({ ...v, isPosition: "third" }));
          const newArr5 = defaultAdd.map((v) => ({
            ...v,
            isPosition: "fourth",
          }));

          const newArr7 = [Arr6Value];
          newArr4.forEach(myFunction);

          function myFunction(item, index) {
            newArr4[index]["type"] = "custom_added";
          }

          // newArr3["type"]="ball"
          newArr5[0]["type"] = "default_address";
          var combineAdd = [newArr2, newArr5, newArr4];
          // console.log(combineAdd);
          var afterCombineAdd = combineAdd.flat();
          var finalAddArr = [];
          finalAddArr = finalremoveDublicateFrds(afterCombineAdd);

          // newArr3["type"]="ball"
          newArr7[0]["type"] = "default_address";
          var combineAdd = [newArr2, newArr7, newArr4];
          // console.log(combineAdd);
          var afterCombineAdd = combineAdd.flat();
          var finalAddArr = [];
          finalAddArr = finalremoveDublicateFrds(afterCombineAdd);

          setCombineAddres(finalAddArr[0]);
          setCustomerAddressData(finalAddArr);
          console.log("finalAddArrr", finalAddArr);
          // console.log("finalAddArr", finalAddArr);
          // var mergedAddress = [...checkedAddress, ...data.addresses.container];
          // removeDublicateFrds(afterCombineAdd);
          // console.log(mergedAddress,"mergedAddress");
          // setCustomerAddressData(removeDublicateFrds(afterCombineAdd));
        }
      } catch (error) {
        setCombineAddressLoader(false);
        console.log(error);
      }
    }
  };
  // console.log(checkedAddress, "checkedAddress");
  // Plus quantity with same ID
  const refundItemLoop = (refundItem) => {
    var hash = Object.create(null);
    var result = [];

    refundItem.forEach(function (o) {
      if (!hash[o.id]) {
        hash[o.id] = {
          id: o.id,
          quantity: 0,
          name: o.name,
          originalTotal: o.originalTotal,
          originalUnitPrice: o.originalUnitPrice,
          currentQuantity: o.currentQuantity,
          discountedTotal: o.discountedTotal,
          discountedUnitPrice: o.discountedUnitPrice,
          fulfillableQuantity: o.fulfillableQuantity,
          title: o.title,
          variant: {
            id: o?.variant?.id,
          },
        };
        result.push(hash[o.id]);
      }
      hash[o.id].quantity += +o.quantity;
    });

    return (
      <tbody>
        {!!result &&
          result?.length > 0 &&
          result.map((refundOrdeItem, i) => (
            <tr className="white_layout">
              <td colSpan={2}>{refundOrdeItem?.name}</td>
              <td>{refundOrdeItem?.quantity}</td>
              <td>
                ${" "}
                {(
                  refundOrdeItem.originalTotal * refundOrdeItem.quantity
                ).toFixed(2)}
              </td>
            </tr>
          ))}
      </tbody>
    );
  };

  // ------------stripe----------------
  // Pay button
  const handlePay = (e) => {
    e.preventDefault();
    var orderIds = [];
    // console.log("........................", checkedState);

    if (SelectAllCombine === true) {
      e.preventDefault();
      // console.log(SelectAllCombine);
      // console.log(checkedState);
      checkedState.map((item, index) => {
        var orderObj = {
          id: item?.orderId.replace(/^\D+/g, ""),
          amount: item?.totalPrice,
          item_name: item?.name,
          subTotalPrice: item?.subTotalPrice,
        };
        orderIds.push(orderObj);
      });
    }
    // console.log("---------------", checkedState);

    if (checkedState.length > 0) {
      // Getting orders ids
      if (SelectAllCombine === false) {
        checkedState.map((item, index) => {
          if (item?.isCheck == true)
            var orderObj = {
              id: item?.orderId.replace(/^\D+/g, ""),
              amount: item?.totalPrice,
              item_name: item?.name,
              subTotalPrice: item?.subTotalPrice,
            };

          orderIds.push(orderObj);
        });
      }

      const cartId = randomString(20);
      // Setting cart data
      dispatch(
        cartOrders({
          cartOrders: {
            id: cartId,
            total:
              !!payableAmount && payableAmount != null
                ? payableAmount
                : checkedPrice.totalPrice,
            cart_order: checkedState,
            items: orderIds,
            discount: checkedState.length > 0 ? discountPrice : "0",
            shippingAmount: checkedPrice?.totalShippingPrice
              ? checkedPrice?.totalShippingPrice
              : 0,
            subTotal: checkedPrice?.totalPriceWithOutShipping
              ? checkedPrice?.totalPriceWithOutShipping
              : 0,
            remainingAmount: userRemainingAmt,
            creditStatus: creditStatus,
          },
        })
      );

      navigate(`/checkout/${cartId}`);
    } else {
      toast.error("Kindly select an order");
    }
  };

  useEffect(() => {
    calcPrice();
    !!couponCode &&
      couponCode != null &&
      setTimeout(() => {
        openRegisterFile.current.click();
      }, 1000);
    checkedState && checkedState.length > 1
      ? setCombineStatus(false)
      : setCombineStatus(true);
    checkedState && checkedState.length > 0
      ? setPayStatus(false)
      : setPayStatus(true);
  }, [checkedState]);

  console.log("islience===>", isLiceance);

  return (
    <>
      <div>
        {editShippingHtml(ShippingAddress, ShippingItem)}
        {addShippingHtml(ShippingAddress, ShippingItem)}
        {combineOrderHTML()}
        {/* Stripe modal */}
        {isOpenDelete && (
          <div className="delete_popup" style={{}}>
            <div className="popup_wrap">
              <div className="content">
                <div
                  className="popupCloseButton"
                  onClick={(e) => {
                    setOpenDelete(!isOpenDelete);
                  }}
                >
                  ×
                </div>

                <p style={{ textAlign: "center" }}>
                  {" "}
                  Are you sure you want to delete this order?
                </p>
                {deleteOrderLoader && (
                  <div className="spinner_div">
                    <Spinner />
                  </div>
                )}
                <div className="justify-content-center row">
                  <div className="btn_wrap">
                    <button
                      className="btn no-btn-dt no_delete_btn"
                      onClick={(e) => {
                        setOpenDelete(!isOpenDelete);
                      }}
                    >
                      No
                    </button>
                    <button
                      className="btn yes-btn-dt yes_delete_btn"
                      onClick={(e) => {
                        handleDelete(DeleteId);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Stripe modal */}

        <section className="bt_batch_overview py-0">
          <div className="container-fluid px-0">
            <div className="row g-0">
              <div className="col-12 d-flex">
                <NavLinks />
                <div
                  className={`right_sidebar tab-content ${
                    orderLoader ||
                    sortingLoader ||
                    paginateLoader ||
                    addressLoader ||
                    qtyLoader ||
                    combineLoader ||
                    removeItemLoader ||
                    deleteOrderLoader ||
                    combineAddressLoader
                      ? "bg_blur"
                      : ""
                  }`}
                >
                  {defaultAddress &&
                  (defaultAddress?.address1 === null ||
                    defaultAddress?.address1 === undefined) ? (
                    <div className="notification d-flex justify-content-center">
                      <div>
                        <span>
                          Please add shipping address{" "}
                          <Link to="/account-detail">here</Link>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {businessAddress && !businessAddress.address1 ? (
                    <div className="notification d-flex justify-content-center">
                      <div>
                        <span>
                          Your business info must be added before we will ship
                          any of your customers products{" "}
                          <Link to="/account-detail">
                            Add Buisness Info Now
                          </Link>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!isLiceance ? (
                    <div className="notification d-flex justify-content-center">
                      <div>
                        <span>
                          Business license must be uploaded before we can ship
                          the products.{" "}
                          <Link to="/account-detail">Upload Now</Link>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="top_section">
                    <h1>Orders Overview</h1>
                    <div className="dashboard-text">
                      {message && (
                        <h3 style={{ border: "1px dashed", padding: "10px" }}>
                          <p data-placeholder="This is Dashboard Text">
                            {message}
                          </p>{" "}
                        </h3>
                      )}
                    </div>

                    <Logout />
                  </div>

                  <div className="bottom_layout">
                    <div className="create_order_overview">
                      <div className="row" style={{ flexWrap: "nowrap" }}>
                        <div className="col-10 col-lg-10 col-md-8 col-sm-8">
                          <div className="row">
                            {/* search box -1 */}
                            <div className="col-3 col-lg-2 col-md-4 col-sm-4">
                              <div className="search-container">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    placeholder="Ship To"
                                    name="search"
                                    value={searchText}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                  />
                                  <div className="search-container-button">
                                    {searchText !== "" ? (
                                      <button
                                        onClick={(e) => {
                                          setSearchText("");
                                          setSortingLoader(true);
                                          removeItem("orderSearch");
                                        }}
                                      >
                                        <i className="fa fa-close" />
                                      </button>
                                    ) : (
                                      <i className="fa fa-search" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* search box -2 */}
                            <div className="col-3 col-lg-2 col-md-4 col-sm-4">
                              <div className="search-container">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    placeholder="Order No."
                                    name="search"
                                    value={searchText2}
                                    onChange={(e) =>
                                      setSearchText2(e.target.value)
                                    }
                                  />
                                  <div className="search-container-button">
                                    {searchText2 !== "" ? (
                                      <button
                                        onClick={(e) => {
                                          setSearchText2("");
                                          setSortingLoader(true);
                                          removeItem("orderSearch2");
                                        }}
                                      >
                                        <i className="fa fa-close" />
                                      </button>
                                    ) : (
                                      <i className="fa fa-search" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-3 button_session"
                              style={{
                                marginTop: "0px",
                              }}
                            >
                              <button
                                className={`combine_button ${
                                  searchText === "" &&
                                  searchText2 === "" &&
                                  "disabled_btn"
                                }`}
                                name="search"
                                onClick={(e) => handleSearch(e)}
                                style={{
                                  backgroundColor: "#5d725f",
                                  padding: "1.2em 25px",
                                  boxSizing: "content-box",
                                }}
                                disabled={
                                  searchText === "" && searchText2 === ""
                                    ? true
                                    : false
                                }
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* {console.log(creditStatus)}
{console.log("creditStatus")} */}
                        {creditStatus === "enabled" ? (
                          <div className="col-2 col-lg-2 col-md-4 col-sm-4">
                            <div className="balance-box">
                              <h4 className="balance-title mb-2">
                                Available Amount
                              </h4>
                              <h2 className="balance-amt">{`$${userRemainingAmt.toFixed(
                                2
                              )}`}</h2>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className="col-2 col-lg-2 col-md-4 col-sm-4">
                          <div className="balance-box">
                            <h4 className="balance-title mb-2">Available Amount</h4>
                            <h2 className="balance-amt">{ `$${userRemainingAmt.toFixed(2)}` }</h2>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="order_colm">
                      <div className="row">
                        <div className="col-12">
                          <div className="order_session">
                            <div className="heading_order">
                              <h2>Orders</h2>
                              {/* <button

                                    id="flexRadioDefault"
                                    name="all"
                                    onClick={(e) => SelectAllOrder(e)}
                                  > Select All
                                  </button> */}
                              <input
                                type="checkbox"
                                className="form-check-input select_full_order"
                                id="flexRadioDefault"
                                name="all"
                                style={{ marginLeft: "0.8rem" }}
                                checked={SelectAllCombine}
                                onChange={(e) =>
                                  setSelectAllCombine(!SelectAllCombine)
                                }
                              />{" "}
                              <label
                                style={{
                                  marginLeft: "0.4rem",
                                  color: "#9ba39b",
                                }}
                              >
                                Select All Orders
                              </label>
                            </div>
                            <div className="setting_icon_colm">
                              <ul>
                                <li style={{ marginRight: "10px" }}>
                                  <button
                                    className={`combine_button ${
                                      allOrder.length > 0
                                        ? "active_btn"
                                        : "disabled_btn"
                                    }`}
                                    // name="combine"
                                    // data-bs-toggle="modal"
                                    // data-bs-target={`#combineOrderDetails`}
                                    onClick={(e) =>
                                      handleAutoCombineAddresses(e)
                                    }
                                    disabled={allOrder.length < 1}
                                    style={{
                                      color: "#fff",
                                      padding: "10px 20px",
                                      boxSizing: "content-box",
                                      border: "none",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    Auto-Combine
                                  </button>
                                </li>
                                <li>
                                  <a
                                    // href="javascript:void(0);"
                                    className={
                                      refrashLoader
                                        ? "refresh_button setting_icon_colm_color"
                                        : "refresh_button"
                                    }
                                    onClick={() => getAllOrders()}
                                  >
                                    {" "}
                                    <i
                                      className="fa fa-refresh"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {refrashLoader ? "Refresh....." : "Refresh"}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {sortingLoader && (
                            <div className="spinner_div">
                              <Spinner />
                            </div>
                          )}
                          <div className="table_session">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>
                                    <input
                                      type="checkbox"
                                      className="form-check-input select_all_order"
                                      id="flexRadioDefault"
                                      name="all"
                                      checked={
                                        checkedState &&
                                        checkedState.length > 0 &&
                                        checkedState.filter(
                                          (item) => item?.isCheck == true
                                        ).length == allOrder.length
                                      }
                                      disabled={SelectAllCombine}
                                      onChange={(e) => handleSelectAll(e)}
                                    />{" "}
                                  </th>
                                  <th>
                                    <div className="d-flex">
                                      Order Number{" "}
                                      {!!sort &&
                                      sort?.status == true &&
                                      sort.columnSortName == "name" ? (
                                        <p
                                          className="decending-data sortdata"
                                          data-order="Order_num"
                                          data-val="desc"
                                          onClick={(e) =>
                                            handleSorting("desc", "name")
                                          }
                                        >
                                          <span className="order_sorting_icon order_up">
                                            <i
                                              className="fa fa-sort-amount-asc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      ) : (
                                        <p
                                          className="assending-data sortdata"
                                          data-order="Order_num"
                                          data-val="asc"
                                          onClick={(e) =>
                                            handleSorting("asc", "name")
                                          }
                                        >
                                          <span className="order_sorting_icon order_down">
                                            <i
                                              className="fa fa-sort-amount-desc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </th>

                                  <th>
                                    <div className="d-flex">
                                      Ship To{" "}
                                      {!!sort &&
                                      sort?.status == true &&
                                      sort.columnSortName ==
                                        "shippingAddr.container.firstName" ? (
                                        <p
                                          className="decending-data sortdata"
                                          data-order="Order_num"
                                          data-val="desc"
                                          onClick={(e) =>
                                            handleSorting(
                                              "desc",
                                              "shippingAddr.container.firstName"
                                            )
                                          }
                                        >
                                          <span className="order_sorting_icon order_up">
                                            <i
                                              className="fa fa-sort-amount-asc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      ) : (
                                        <p
                                          className="assending-data sortdata"
                                          data-order="Order_num"
                                          data-val="asc"
                                          onClick={(e) =>
                                            handleSorting(
                                              "asc",
                                              "shippingAddr.container.firstName"
                                            )
                                          }
                                        >
                                          <span className="order_sorting_icon order_down">
                                            <i
                                              className="fa fa-sort-amount-desc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </th>
                                  <th>Status</th>
                                  <th>Shipping Address</th>
                                  <th>Subtotal</th>
                                  <th>Shipping</th>
                                  <th>
                                    <div className="d-flex">
                                      Total{" "}
                                      {!!sort &&
                                      sort?.status == true &&
                                      sort.columnSortName == "totalPrice" ? (
                                        <p
                                          className="decending-data sortdata"
                                          data-order="Order_num"
                                          data-val="desc"
                                          onClick={(e) =>
                                            handleSorting("desc", "totalPrice")
                                          }
                                        >
                                          <span className="order_sorting_icon order_up">
                                            <i
                                              className="fa fa-sort-amount-asc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      ) : (
                                        <p
                                          className="assending-data sortdata"
                                          data-order="Order_num"
                                          data-val="asc"
                                          onClick={(e) =>
                                            handleSorting("asc", "totalPrice")
                                          }
                                        >
                                          <span className="order_sorting_icon order_down">
                                            <i
                                              className="fa fa-sort-amount-desc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </th>
                                  <th>
                                    <div className="d-flex">
                                      Created At{" "}
                                      {!!sort &&
                                      sort?.status == true &&
                                      sort.columnSortName == "createdAt" ? (
                                        <p
                                          className="decending-data sortdata"
                                          data-order="Order_num"
                                          data-val="desc"
                                          onClick={(e) =>
                                            handleSorting("desc", "createdAt")
                                          }
                                        >
                                          <span className="order_sorting_icon order_up">
                                            <i
                                              className="fa fa-sort-amount-asc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      ) : (
                                        <p
                                          className="assending-data sortdata"
                                          data-order="Order_num"
                                          data-val="asc"
                                          onClick={(e) =>
                                            handleSorting("asc", "createdAt")
                                          }
                                        >
                                          <span className="order_sorting_icon order_down">
                                            <i
                                              className="fa fa-sort-amount-desc"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {combineAddressLoader && (
                                  <div className="spinner_div">
                                    <Spinner />
                                  </div>
                                )}
                                {orderLoader ? (
                                  <tr>
                                    <td>
                                      <div className="spinner_div">
                                        <Spinner />
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {!!allOrder &&
                                    allOrder != null &&
                                    allOrder.length > 0 ? (
                                      allOrder.map((item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>
                                              <input
                                                key={i}
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`flexRadioDefault${i}`}
                                                name={`flexRadio${i}`}
                                                value={
                                                  checkedState &&
                                                  checkedState.includes(
                                                    checkedState.find(
                                                      (el) =>
                                                        el.orderId ===
                                                        item.orderId
                                                    )
                                                  )
                                                }
                                                checked={
                                                  checkedState &&
                                                  checkedState.includes(
                                                    checkedState.find(
                                                      (el) =>
                                                        el.orderId ===
                                                        item.orderId
                                                    )
                                                  )
                                                }
                                                onChange={(e) =>
                                                  handleChecked(e, item)
                                                }
                                              />
                                            </td>
                                            <td>
                                              {item.name.replace(/[#]/g, "")}
                                            </td>
                                            <td>
                                              {
                                                item?.shippingAddr?.container
                                                  .firstName
                                              }{" "}
                                              {
                                                item?.shippingAddr?.container
                                                  .lastName
                                              }
                                            </td>
                                            <td className="cancel_btn">
                                              <span
                                                className={`${
                                                  item.financialStatus ==
                                                    "pending" &&
                                                  !item.combineStatus
                                                    ? "success"
                                                    : "combine_cls tooltip-custom"
                                                }`}
                                              >
                                                {item.combineStatus
                                                  ? "Combined"
                                                  : item.financialStatus}
                                                {item?.combineOrderIds?.length >
                                                  0 &&
                                                  item.combineStatus && (
                                                    <span className="tooltip-text">
                                                      <ul>
                                                        {item?.combineOrderIds.map(
                                                          (num, i) => (
                                                            <li key={i}>
                                                              {num}
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    </span>
                                                  )}
                                              </span>
                                            </td>
                                            <td>
                                              {
                                                item.shippingAddr?.container
                                                  ?.address1
                                              }
                                              {", "}
                                              {
                                                item.shippingAddr?.container
                                                  ?.city
                                              }
                                              <br />
                                              {
                                                item.shippingAddr?.container
                                                  ?.zip
                                              }
                                              {", "}
                                              {
                                                item.shippingAddr?.container
                                                  ?.country
                                              }
                                            </td>
                                            <td>
                                              $
                                              {Number(
                                                item?.subTotalPrice
                                              ).toFixed(2)}
                                            </td>
                                            <td>
                                              $
                                              {Number(
                                                item?.totalShippingPrice
                                              ).toFixed(2)}
                                            </td>
                                            <td>
                                              $
                                              {Number(item?.totalPrice).toFixed(
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {" "}
                                              <Moment format="Do MMM YYYY h:mm:ss a z">
                                                {item?.createdAt}
                                              </Moment>
                                            </td>
                                            <td className="icon_view">
                                              <p>
                                                <a
                                                  data-bs-toggle="modal"
                                                  data-bs-target={`#orderDetails-${i}`}
                                                  onClick={() =>
                                                    setCurrentOrderId(
                                                      item.orderId.replace(
                                                        /^\D+/g,
                                                        ""
                                                      )
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  />
                                                </a>
                                              </p>
                                              <p>
                                                <a
                                                  href="#"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteOrderAlert(
                                                      item.orderId.replace(
                                                        /^\D+/g,
                                                        ""
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                  />
                                                </a>
                                              </p>{" "}
                                              {item.isProcessing && (
                                                <span className="badge badge-warning">
                                                  Processing...
                                                </span>
                                              )}
                                            </td>
                                            {lineItemHTML(item, i)}
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={10}>No orders found</td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                            {paginateLoader && (
                              <div className="spinner_div">
                                <Spinner />
                              </div>
                            )}
                            {/* Pagination */}
                            {!!allOrder &&
                            allOrder != null &&
                            allOrder.length > 0 ? (
                              <div
                                className="pagination_colm"
                                aria-label="Page navigation"
                              >
                                <ul className="pagination">
                                  {allData &&
                                    allData?.links?.map((link, i) => {
                                      return (
                                        <li className="page-item" key={i}>
                                          <a
                                            className={`page-link ${
                                              link.active ? "active" : "disable"
                                            }`}
                                            href="#"
                                            onClick={(e) => {
                                              handlePagination(e, link.url);
                                            }}
                                          >
                                            {link.label == "&laquo; Previous"
                                              ? "Previous"
                                              : link.label == "Next &raquo;"
                                              ? "Next"
                                              : link.label}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* Pagination */}
                          </div>
                          <div className="button_session">
                            {/*<div class="discount_box">
											<form>
												<input type="text" name="discount" class="input-text" id="discount" value="" placeholder="Discount">
											</form>
										</div>*/}
                            <div className="left_side_coupun">
                              <div className="coupon_form">
                                <form id="cpform" method="post" action="#">
                                  <input
                                    type="text"
                                    name="coupon_code"
                                    className="input-text"
                                    id="coupon_code"
                                    value={couponCode}
                                    placeholder="Coupon code"
                                    onChange={(e) =>
                                      setCouponCode(e.target.value)
                                    }
                                  />
                                  {!!couponCode && couponCode != null ? (
                                    <button
                                      className="button"
                                      ref={openRegisterFile}
                                      name="apply_coupon"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        applyCoupon();
                                      }}
                                      disabled={couponLoader}
                                    >
                                      Apply
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </form>
                                {couponLoader && (
                                  <div className="spinner_div">
                                    <Spinner />
                                  </div>
                                )}
                                <p>
                                  <span>
                                    Product total: $
                                    {!!checkedPrice &&
                                    checkedPrice.totalPriceWithOutShipping !=
                                      null
                                      ? checkedPrice.totalPriceWithOutShipping
                                      : "0.00"}
                                  </span>
                                  <br />
                                  <span>
                                    Shipping total: $
                                    {!!checkedPrice &&
                                    checkedPrice.totalShippingPrice != null
                                      ? checkedPrice.totalShippingPrice
                                      : "0.00"}
                                  </span>
                                  <br />
                                  <span>
                                    Total: $
                                    {!!checkedPrice &&
                                    checkedPrice.totalPrice != null
                                      ? checkedPrice.totalPrice
                                      : "0.00"}
                                  </span>
                                  <br />
                                  <span>
                                    Discount: $
                                    {!!discountPrice &&
                                    discountPrice != null &&
                                    checkedState &&
                                    checkedState.length > 0
                                      ? discountPrice
                                      : "0.00"}
                                  </span>
                                  <br />
                                  <span>
                                    Payable amount: $
                                    {!!payableAmount &&
                                    payableAmount != null &&
                                    checkedState &&
                                    checkedState.length > 0
                                      ? payableAmount
                                      : "0.00"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="combine_btn_colm">
                              {showTrackingCheckBox && (
                                <div>
                                  <input
                                    type="checkbox"
                                    className="form-check-input select_full_order"
                                    id="populateTracking"
                                    name="populateTracking"
                                    style={{ marginLeft: "0.8rem" }}
                                    checked={populateTracking}
                                    onChange={(e) => {
                                      updateTrackingStatus(!populateTracking);
                                      setPopulateTracking(!populateTracking);
                                    }}
                                  />{" "}
                                  <label
                                    style={{
                                      marginLeft: "0.4rem",
                                      color: "#9ba39b",
                                    }}
                                  >
                                    Do not populate tracking info
                                  </label>
                                </div>
                              )}

                              <button
                                className={`combine_button ${
                                  combineStatus && "disabled_btn"
                                }`}
                                name="combine"
                                data-bs-toggle="modal"
                                data-bs-target={`#combineOrderDetails`}
                                onClick={(e) => handleGetCombineAddresses(e)}
                                disabled={combineStatus}
                                style={{
                                  padding: "1.2em 25px",
                                  boxSizing: "content-box",
                                }}
                              >
                                Combine
                              </button>
                              {/* <a href="#">Combine</a> */}

                              <button
                                className={`combine_button ${
                                  payStatus && "disabled_btn"
                                }`}
                                name="combine"
                                onClick={(e) => handlePay(e)}
                                disabled={payStatus}
                                style={{
                                  padding: "1.2em 25px",
                                  boxSizing: "content-box",
                                }}
                              >
                                Pay
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Order;
