import React, { useEffect, useState } from "react";
import { getUserData } from "../utils";
import Form from "react-bootstrap/Form";
import {
  UpdateSyncSettings,
  GetSyncSetting,
  UpdateInventoryLocation,
} from "../auth";
import { toast } from "react-toastify";
import CustomDropdown from "../customDropdown/CustomDropdown";

const SyncSettings = ({ merchantLocations }) => {
  const [status, setStatus] = useState("active");
  const [inventoryLocation, setInventoryLocation] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (merchantLocations) {
      const currentLocation = merchantLocations.filter(
        (e) => e.is_current_location == true
      );
      setSelectedValue(currentLocation[0]);
    }
  }, [merchantLocations]);

  const onUpdate = () => {
    const payload = {
      sync_settings: {
        status: status,
      },
    };
    UpdateSyncSettings(payload)
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUpdateLocation = (e) => {
    e.preventDefault();
    if (selectedValue && selectedValue.id) {
      const payload = {
        location_id: selectedValue.id,
      };
      UpdateInventoryLocation(payload)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSetting = () => {
    GetSyncSetting()
      .then((res) => {
        if (res.status == 200) {
          setStatus(
            res.data && res.data.sync_settings && res.data.sync_settings.status
              ? res.data.sync_settings.status
              : status
          );
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <>
      <div className="order_colm billing_sec">
        <div className="top_section">
          <h1 class="account_heading">Sync Settings</h1>
        </div>
        <div className="bottom_layout white sync-settings">
          <div>
            <p style={{ color: "#596f5a" }}>
              {" "}
              What would you like to keep default status for products when
              synced first time ?
            </p>
            <div className=" d-flex">
              <label>Status</label>
              <select
                id="status"
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                value={status}
              >
                <option value="active">Active</option>
                <option value="draft">Draft</option>
              </select>
            </div>
            <input
              type="button"
              defaultValue="Update"
              onClick={(event) => {
                onUpdate();
              }}
            />
          </div>
          <div className="mt-5">
            <h5 style={{ color: "#596f5a" }}> Inventory Location</h5>
            <div className="autopay_container_wrap">
              <div className="autop_pay_cont mt-4" style={{ color: "#596f5a" }}>
                <CustomDropdown
                  merchantLocations={merchantLocations}
                  setSelectedValue={setSelectedValue}
                  selectedValue={selectedValue}
                />
              </div>
              <input
                className="mt-4"
                type="button"
                defaultValue="Update"
                onClick={(e) => {
                  onUpdateLocation(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SyncSettings;
