import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./reduxStore/store";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        newestOnTo={true}
        autoClose={3000}
        closeButton={false}
        newestOnTop={true}
        className="toast-container"
        hideProgressBar={true}
      />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
