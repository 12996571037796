import { useEffect } from 'react';

const useInputMask = () => {
  useEffect(() => {
    // initialize inputmask lib for all the forms
    const initIPM = async () => {
      const Inputmask = (await import('inputmask')).default;
      Inputmask().mask(document.querySelectorAll('input'));
    };
    initIPM();
  }, []);
};

export default useInputMask;
