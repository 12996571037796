import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";

const Header = () => {
  let pathname = window.location.pathname;

  function Opens() {
    "https://boutiquetree.com/";
  }
  return (
    <div
      className="top_bar"
      // style={{ backgroundImage: '#5C715F' }}
    >
      <div>
        <a href="https://boutiquetree.com" target="blank">
          <img
            className="top_img"
            src="assets/images/logo.png"
            onClick={() => setTimeout(Opens())}
            alt="logo"
          />
        </a>
      </div>
    </div>
  );
};
export default Header;
