import React, {useEffect, useRef, useState} from "react";

import "../payment/stripe/style.css";


const SubscribePrivateConnection = (props) => {

  const [Loader, setLoader] = useState(false);
  const [connectionCode, setConnectionCode] = useState('')

  return (
    // <div className="bottom_layout white">
      <div //className="sync-shop"
      className={`sync-shop ${
        Loader ? "bg_blur" : ""
      }`}
      >
        <div className="row">
          <div className="col">
            <h1>Subscribe to a Connection</h1>
          </div>
          <div className="connection-btn">
            <input
                type="button"
                defaultValue="Back"
                onClick={(event) => {props.onBack()}}
              />
          </div>
        </div>
        <div>
          {/* <h2 className="mt-4">Connection Code</h2> */}
          <h4 className="mt-2">Use a connection code from Boutique Tree shop to import their products and keep them synced to your shop</h4>
          <div className="col-12">
          <input className="col-md-3 mt-3 connection-code"
              type="text" 
              placeholder="Connection Code"
              value={connectionCode} 
              onChange={(e) => {
                setConnectionCode(e.target.value);
              }}/>
          </div>
          <div className="connection-btn mt-3">
            <input
                type="button"
                disabled={!connectionCode}
                defaultValue="Subscribe"
                onClick={(event) => {
                  props.onSubscribe(connectionCode)
                }}
              />
          </div>
        </div>
        
      </div>
    // </div>
  );
};
export default SubscribePrivateConnection;
