import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import Spinner from "../../spinner/Spinner";
import { useForm } from "react-hook-form";
import { captureOrderPayment, SaveCustomerPaypalCard } from "../../auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useInputMask from "../../../hooks/useInputMask";

export default function AddPayPalCardForm({
  showModel,
  setShowModel,
  hideModel,
  customer,
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, submitCount },
  } = useForm();
  let navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const [cvv, setCVV] = useState("");
  const [cardNumber, setCardNumber] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]{0,4}$/;

    if (regex.test(inputValue)) {
      setCVV(inputValue);
    } else {
      return false;
    }
  };

  const handleCardNumberChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]{0,16}$/;

    if (regex.test(inputValue)) {
      setCardNumber(inputValue);
    } else {
      return false;
    }
  };

  useInputMask();
  const onSubmit = (data) => {
    data.cvv = cvv;
    data.cardNumber = cardNumber;
    if (data.cardNumber.length <= 14) {
      touchedFields.cardNumber = false;
    } else {
      const payload = {
        card: data,
        customerId: customer?.id,
        customerEmail: customer?.email,
      };
      setProcessing(true);
      SaveCustomerPaypalCard(payload)
        .then((response) => {
          if (response.status === 200) {
            setTimeout(() => {
              setProcessing(false);
              toast.success(response.message);
              setShowModel();
            }, 5000);
          } else {
            setTimeout(() => {
              setProcessing(false);
              toast.error(response.message);
              setShowModel();
            }, 5000);
          }
        })
        .catch((errors) => {
          setTimeout(() => {
            setProcessing(false);
            toast.success(errors);
          }, 5000);
          console.log(errors);
        });
    }
  };
  console.log(errors);
  return (
    <Modal show={showModel} onHide={setShowModel}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Credit Card Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-container">
          <form>
            <div className="field-container">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                type="text"
                placeholder="Card Holder's Name"
                {...register("name", { required: true })}
              />
              {errors.name?.type === "required" && (
                <p role="alert" className="invalid-feedback">
                  Name is required
                </p>
              )}
            </div>
            <div className="field-container">
              <label htmlFor="cardNumber">Card Number</label>
              <input
                className="card-field"
                id="cardNumber"
                value={cardNumber}
                type="text"
                placeholder="Card Number"
                onChange={handleCardNumberChange}
                // {...register("cardNumber", {
                //   required: true
                // })}
              />
              {(touchedFields.cardNumber || submitCount > 0) &&
                !/^[0-9]{14,16}$/.test(cardNumber) && (
                  <p role="alert" className="invalid-feedback">
                    Card Number is required
                  </p>
                )}
            </div>
            <div className="field-container">
              <label htmlFor="expiryMonth">Expiration Month</label>
              <input
                id="expiryMonth"
                type="text"
                data-inputmask-regex="[0-1][0-9]"
                placeholder="MM"
                {...register("expiryMonth", { required: true, max: 12 })}
              />
              {errors.expiryMonth?.type === "required" && (
                <p role="alert" className="invalid-feedback">
                  Expiration Month is required
                </p>
              )}
            </div>
            <div className="field-container">
              <label htmlFor="expiryYear">Expiration Year</label>
              <input
                id="expiryYear"
                type="text"
                data-inputmask-regex="[0-9][0-9][0-9][0-9]"
                placeholder="YYYY"
                {...register("expiryYear", {
                  required: true,
                  valueAsNumber: true,
                })}
              />
              {errors.expiryYear?.type === "required" && (
                <p role="alert" className="invalid-feedback">
                  Expiration Year is required
                </p>
              )}
            </div>
            <div className="field-container">
              <label htmlFor="cvv">Security Code</label>
              <input
                id="cvv"
                type="text"
                value={cvv}
                placeholder="CVV"
                onChange={handleChange}
              />
              {(touchedFields.cvv || submitCount > 0) &&
                !/^[0-9]{3,4}$/.test(cvv) && (
                  <p role="alert" className="invalid-feedback">
                    Please enter valid Security Code
                  </p>
                )}
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start flex-row">
        <div className="flex-row">
          <div className="custom_cx_button ">
            {processing ? (
              <div className="spinner_div">
                <Spinner />
              </div>
            ) : (
              <div className="combine_btn_colm float-start">
                <Button
                  className="combine_button mt-0"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   payNowUsingCreditCard();
                  // }}
                  name="combine"
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  {" "}
                  Save Card
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
