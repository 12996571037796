import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getItem } from "../components/utils";
import { BRAND_LOGO, BRAND_NAME, BRAND_HELP_URL } from "./Api";
const NavLinks = () => {
  let pathname = window.location.pathname;
  const [hideSync, setHideSync] = useState(false);

  useEffect(() => {
    let hide = getItem("hideSync");
    setHideSync(hide && hide === "1" ? true : false);
    $(".toggleBtn").click(function () {
      var sidebar_width = parseInt($(".tab_colm").css("width"));
      if (sidebar_width > 100) {
        $(".tab_colm").css("width", "100px");
        $(".sidebar-control").fadeOut();
        $(".nav-left-icon").fadeOut();
        $(".tab_colm").addClass("short_menu");
      } else {
        $(".tab_colm").css("width", "280px");
        $(".sidebar-control").fadeIn();
        $(".nav-left-icon").fadeIn();
        $(".tab_colm").removeClass("short_menu");
      }
    });
  }, []);
  function Opens() {
    "https://boutiquetree.com/";
  }
  return (
    <div
      className="left_sidebar tab_colm short_menu"
      style={{ backgroundImage: 'url("assets/images/layout@2x.png")' }}
    >
      <div className="logo_img">
        {/* <Link to="https://boutiquetree.com/" className={`${"" ? "active" : ""}`} target="_blank"> */}
        {/* <Link to='https://boutiquetree.com' target="blank"> 
          <img src="assets/images/logo.png" alt="logo"  />
        </Link> */}
        <a href="/" target="blank">
          <img
            src={`${BRAND_LOGO}`}
            onClick={() => setTimeout(Opens())}
            alt={`${BRAND_NAME}`}
          />
        </a>
      </div>

      <div id="tab" className="btn-group project_tab" data-toggle="buttons">
        <h6 className={`${pathname === "/" ? "active" : ""}`}>
          <Link to="/" className={"btn btn-default"} data-toggle="tab">
            <FontAwesomeIcon icon={["fas", "cart-shopping"]} />

            <span className="sidebar-control">Orders</span>
          </Link>
        </h6>
        <h6 className={`${pathname === "/order-history" ? "active" : ""}`}>
          <Link
            to="/order-history"
            className={"btn btn-default"}
            data-toggle="tab"
          >
            <FontAwesomeIcon icon="fa-solid fa-cart-plus" />
            <span className="sidebar-control">Orders History</span>
          </Link>
        </h6>
        <h6 className={`${pathname === "/transactions" ? "active" : ""}`}>
          <Link
            to="/transactions"
            className="btn btn-default"
            data-toggle="tab"
          >
            <FontAwesomeIcon icon="fa-solid fa-arrow-right-arrow-left" />
            <span className="sidebar-control">Transactions</span>
          </Link>
        </h6>
        <h6 className={`${pathname === "/analytics" ? "active" : ""}`}>
          <Link to="/analytics" className="btn btn-default" data-toggle="tab">
            <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
            <span className="sidebar-control">Analytics</span>
          </Link>
        </h6>
        <h6 className={`${pathname === "/account-detail" ? "active" : ""}`}>
          <Link
            to="/account-detail"
            className="btn btn-default"
            data-toggle="tab"
          >
            <FontAwesomeIcon icon="fa-solid fa-gear" />
            <span className="sidebar-control">Settings</span>
          </Link>
        </h6>
        {!hideSync && (
          <h6 className={`${pathname === "/sync-shop" ? "active" : ""}`}>
            <Link to="/sync-shop" className="btn btn-default" data-toggle="tab">
              <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" />
              <span className="sidebar-control">Shop Sync</span>
            </Link>
          </h6>
        )}

        {/* Help icon */}
        <h6>
          <a
            href={`${BRAND_HELP_URL}`}
            className="btn btn-default"
            target="_blank"
          >
            <FontAwesomeIcon icon="fa-solid fa-circle-info" />
            <span className="sidebar-control">Help</span>
          </a>
        </h6>

        <h6>
          <div className="nav-switcher">
            <a
              href="javascript:void(0)"
              className="toggleBtn"
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px 5px",
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-bars-staggered" />
              <span className="collapse-button-label sidebar-control">
                Collapse menu
              </span>
            </a>
          </div>
        </h6>
      </div>
    </div>
  );
};
export default NavLinks;
