import React from "react";

import Account from "../components/Account-Detail/Account";
import Billing from "../components/Account-Detail/Billing";
import Connections from "../components/Shop-Sync/Connections";
import Preferences from "../components/Shop-Sync/Preferences";
import Settings from "../components/Shop-Sync/Settings";
import NavLinks from "../components/Nav";
import Logout from "../components/Logout";

const ShopSync = () => {
  return (
    <section className="bt_batch_overview py-0">
      <div className="container-fluid px-0">
        <div className="row g-0">
          <div className="col-12 d-flex">
            <NavLinks/>
            <div className="right_sidebar">
              <div className="top_section">
                <h1>Sync Shop
                <h6>With Boutique Tree</h6>
                </h1>
                <Logout/>
              </div>
              <Connections />
              {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Shop Sync</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Preferences</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Settings</button>
                </li>
              </ul> */}
              {/* <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <Connections />
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <Preferences/>
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <Settings/>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopSync;