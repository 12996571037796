import React  from "react";
import { Grid,Typography, Link } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className="footer-container row box-shadow">
     <Grid container spacing={2} justifyContent={isSmallScreen ? 'center' : 'flex-start'}>
      <Grid item xs={12} sm={6} md={3} textAlign={isSmallScreen ? 'center' : 'left'}>
        <Link 
          href="https://boutiquetree.com" 
          target="_blank" 
          style={{ display: 'grid', justifyContent: 'center' }}
        >
          <img 
            style={{ width: '100px', justifySelf: 'center' }} 
            src="assets/images/footer-logo-icon.png" 
            alt="logo" 
          />
          <img 
            style={{ width: '200px' }} 
            src="assets/images/footer-logo-name.png" 
            alt="logo" 
          />
        </Link>
      </Grid>
      
      <Grid item xs={12} sm={6} md={3} textAlign={isSmallScreen ? 'center' : 'left'}>
        <Typography variant="h6" gutterBottom>Resources</Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/pages/what-is-dropshipping", "_blank")} 
          className="footer-text"
        >
          What is dropshipping?
        </Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/pages/boutique-tree-suggestion-box", "_blank")} 
          className="footer-text"
        >
          Suggestions
        </Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/pages/boutique-tree-membership-gold-and-platinum", "_blank")} 
          className="footer-text"
        >
          Plan Options
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} textAlign={isSmallScreen ? 'center' : 'left'}>
        <Typography variant="h6" gutterBottom>Support</Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/pages/how-can-we-help", "_blank")} 
          className="footer-text"
        >
          Support
        </Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/pages/returns", "_blank")} 
          className="footer-text"
        >
          Returns
        </Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/policies/shipping-policy", "_blank")} 
          className="footer-text"
        >
          Shipping
        </Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/pages/referrals", "_blank")} 
          className="footer-text"
        >
          Referrals
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} textAlign={isSmallScreen ? 'center' : 'left'}>
        <Typography variant="h6" gutterBottom>Terms of Service</Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/policies/privacy-policy", "_blank")} 
          className="footer-text"
        >
          Privacy Policy
        </Typography>
        <Typography 
          onClick={() => window.open("https://boutiquetree.com/policies/terms-of-service", "_blank")} 
          className="footer-text"
        >
          Terms of Service
        </Typography>
      </Grid>
    </Grid>
          <div className="footer-foot">
            Boutique Tree © 2023 Copyright all rights reserved
          </div>
        </div>
    )
}

export default Footer;