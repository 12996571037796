import React, { useEffect, useRef, useState } from "react";
import NavLinks from "../Nav";
import { getUserData } from "../utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardForm from "../payment/stripe/AddCardForm";
import Select from "react-select";

import {
  getCustomerAccountDetail,
  getCustomerAddressDetail,
  updateCustomerAccountDetail,
  getCollectionProducts,
} from "../auth";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import "../payment/stripe/style.css";
import Products from "./Products";

const SubscribeConnection = (props) => {
  var userData = getUserData();
  // const [collapsed, setCollapsed] = useState()
  const [products, setProducts] = useState();
  const [Loader, setLoader] = useState(false);
  // console.log("propss ==", props)
  var customerEmail =
    !!userData && userData ? userData?.customerInfo?.customer_data?.email : "";

  // const [code, setCode] = useState('')
  const [collapsed, setCollapsed] = useState();
  const inputRef = useRef();

  const onGetCollectionProducts = (item) => {
    // console.log("item == ", item)
    // return
    // if(!code){
    //   toast.error("please enter code");
    //   return;
    // }
    // const collectionIds = item.collections.map(x => x.collectionId);
    // if(collectionIds.length < 1){
    //   setProducts([]);
    //   return;
    // }
    setLoader(true);
    getCollectionProducts({
      connection_id: item.connection_id,
      // collection_id: collectionIds
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log("response ==", response);
          setProducts(response.data);
          // setShowSubscribe(false)
          // toast.success(response.message);
          setLoader(false);
          // fetchConnections();
          // fetchAllConnections();
        } else {
          setLoader(false);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        // setLoader(false);
      });
  };

  return (
    // <div className="bottom_layout white">
    <div //className="sync-shop"
      className={`sync-shop ${Loader ? "bg_blur" : ""}`}
    >
      <div className="row">
        <div className="col">
          <h1>Subscribe to a Connection</h1>
        </div>
        <div className="connection-btn">
          <input
            className=" btn-brown"
            type="button"
            defaultValue="Enter Code"
            onClick={(event) => {
              props.onPrivateSubascribe();
            }}
          />
        </div>
        <div className="connection-btn">
          <input
            type="button"
            defaultValue="Back"
            onClick={(event) => {
              props.onBack();
            }}
          />
        </div>
      </div>
      <div>
        {/* <h2 className="mt-4">Connection Code</h2> */}
        <h4 className="mt-2">
          Subscribe to a connection from Boutique Tree shop to import their
          products and keep them synced to your shop.
        </h4>
        <div className="col-12">
          <div className="table_session">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Connection</th>
                  <th>Attached Collections</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {Loader && (
                    <div className="spinner_div">
                      <Spinner />
                    </div>
                  )}
                  {props.allConnections.length > 0 ? (
                    props.allConnections.map((con, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td className="col-3">
                              <h2 className="subscriber-title">
                                {con.connection_name}
                              </h2>
                              <div className="uid-box">
                                <div className="uid">{con.connection_uid}</div>
                              </div>
                            </td>
                            <td className="col-6">
                              {con.collections &&
                                con.collections.length > 0 && (
                                  <div>
                                    {con.collections.map((collection) => (
                                      <div className="collection-box">
                                        {collection.title}
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </td>
                            <td className=" col-2">
                              <input
                                type="button"
                                // className="btn-text"
                                defaultValue="Subscribe"
                                onClick={(event) => {
                                  props.onSubscribe(con.connection_uid);
                                }}
                              />
                            </td>
                            <td className="icon-container col-1">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (collapsed && collapsed._id === con._id) {
                                    setCollapsed(null);
                                  } else {
                                    setCollapsed(con);
                                    //call api
                                    onGetCollectionProducts(con);
                                  }
                                  // console.log(collapsed)
                                }}
                              >
                                {!collapsed ||
                                (collapsed && collapsed._id !== con._id) ? (
                                  <img
                                    className="img"
                                    src="../assets/images/collapse-right.png"
                                    alt="logo"
                                  />
                                ) : (
                                  <img
                                    className="img"
                                    src="../assets/images/collapse.png"
                                    alt="logo"
                                  />
                                )}
                              </a>
                            </td>
                          </tr>
                          {products &&
                            collapsed &&
                            collapsed._id === con._id && (
                              <tr>
                                <td colspan="6">
                                  <Products products={products} />
                                </td>
                              </tr>
                            )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No connections found</td>
                    </tr>
                  )}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="col-md-2 mt-4">
          <input
              type="button"
              defaultValue="Subscribe"
              onClick={(event) => {props.onSubscribe(code)}}
            />
        </div> */}
    </div>
    // </div>
  );
};
export default SubscribeConnection;
